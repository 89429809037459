import { Component } from 'react';
import VWO from "@vendor/vwo";


class GoalConversion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            goalId: props.goalId
        }
    }

    componentDidMount() {
        VWO.pushGoalConversion(this.state.goalId);
    }

    render() {
        return null;
    }

}

export default GoalConversion;
