export default function Header({header, headerId, subText, children}) {
    return (
        <div className='row'>
            <div className="col-12">
                <div className={'pageHeading'} id={headerId}>
                    <h1>{header}</h1>
                    {subText && <p className="large fontMedium">{subText}</p>}
                    {children}
                </div>
            </div>
        </div>
    )
}
