import MaterialAddress from "@components/general/material/address-lookup";
import MaterialInput from "@components/general/material/input";


const SearchForm = ({address, searchFormError, apartmentFieldError, duplicate, submitted, handleClick, handleSelection, handleApartment}) => {

    const updateApartment = (event) => {
        const value = event.target.value;
        if (/^\S*$/.test(value)) {
            handleApartment(value)
        }
    }


    return (
        <div>

            <div className="relative my-4">
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-9 mb-4 mb-md-0 first">
                        <MaterialAddress
                            id={'AddressSearch'}
                            error={searchFormError}
                            duplicate={duplicate}
                            submitted={submitted}
                            placeholder={'Start typing your address'}
                            text={'Find your address'}
                            handleKeyup={handleSelection}
                        />
                    </div>
                    <div className="col-12 col-md-4 col-lg-3">
                        <MaterialInput
                            id={'Apartment'}
                            cy={'Apartment'}
                            submitted={submitted}
                            placeholder={'Optional'}
                            text={'Apt/Unit'}
                            required={false}
                            value={address.Apartment}
                            handleKeyup={updateApartment}
                            error={apartmentFieldError}
                        />
                    </div>
                </div>
            </div>

            <button
                type="button"
                className="link dark mb-4"
                data-cy="th_EnterAddressManually"
                onClick={handleClick}
            >Enter address manually</button>

        </div>
    )

}

export default SearchForm;