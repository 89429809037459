module.exports = {
    pushGoalConversion: (id) => {
        window._vis_opt_queue = window._vis_opt_queue || [];
        window._vis_opt_queue.push(function() { _vis_opt_goal_conversion(id); });
    },

    APPLICANT_DETAILS_GOAL_ID: 201,
    VEHICLE_DETAILS_GOAL_ID: 202,
    DRIVER_DETAILS_GOAL_ID: 203,
    ADDITIONAL_DRIVER_DETAILS_GOAL_ID: 204,
    CURRENT_COVERAGE_GOAL_ID: 205,
    CONTACT_DETAILS_GOAL_ID: 206,
    QUOTES_DETAILS_GOAL_ID: 207,
    ADJUST_COVERAGE_GOAL_ID: 208,
    PAYMENT_GOAL_ID: 209,
    LIENHOLDER_DETAILS_GOAL_ID: 210,
    DRIVER_PERFORMANCE_GOAL_ID: 211,
    DRIVER_COURSE_GOAL_ID: 212,
    EDELIVERY_GOAL_ID: 213,
    PROGRESSIVE_QUOTE_SUMMARY_GOAL_ID: 214,
    BIND_SUCCESS_GOAL_ID: 215,
    WARM_TRANSFER_GOAL_ID: 216,
    MANUAL_ADDRESS_GOAL_ID: 217,
    ADD_VEHICLE_GOAL_ID: 218,
    ADD_DRIVER_GOAL_ID: 219,
    EDIT_DRIVER_GOAL_ID: 220,
    MARITAL_STATUS_GOAL_ID: 221,
    UM_OPTIONS_GOAL_ID: 222
};
