import SVG from "@components/general/svg";
import {SHARED_ASSETS_PATH} from "@constants";


const PictureContainer = () => {

    return (
        <div id="picture" className="picture">
            <div className="picture-container">

                <div className="stars" aria-hidden="true">
                    <SVG img={SHARED_ASSETS_PATH + "svg/reviews-rating.svg"} alt={"4.6 out of 5 rating"}/>
                </div>

                <div className="text">Tried. Tested. Trusted.</div>

                <div className="review"><SVG img={SHARED_ASSETS_PATH + "svg/reviews-logo.svg"} alt={"Reviews.io logo"}/></div>

                <div className="callout">
                    <div className="statement">
                        <span className="amount">70+</span>
                        <span className="fact">Insurance Providers</span>
                    </div>
                    <div className="statement">
                        <span className="amount">48</span>
                        <span className="fact">Licensed States</span>
                    </div>
                    <div className="statement">
                        <span className="amount">30k+</span>
                        <span className="fact">Policies Sold</span>
                    </div>
                </div>

                <div className="callout-mobile">
                    <p className="large mt-3">Over 3,500 happy customers</p>
                </div>

            </div>
        </div>
    )

}

export default PictureContainer;