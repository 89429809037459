import SubmitButton from "@components/complete-payment/SubmitButton";
import SVG from "@components/general/svg";


const BackToAgent = ({url, message}) => {

    return (
        <section id="interstitial-progress-container" className={"interstitial"}>
            <div className="interstitial-content">
                <div className="interstitial-content__container container">
                    <h1 className="small">We have hit a bump in the road</h1>
                    <div className={"text-center"}>
                        <p className={"text-center"}>{ message }</p>
                        <SubmitButton
                            text={"Go back to quotes"}
                            className={"reverse mb-5"}
                            handleClick={() => document.location.href = url}
                        />
                    </div>
                    <SVG style={{width: "80%"}} src="broken-car" />
                </div>
            </div>
        </section>
    )

};


export default BackToAgent;