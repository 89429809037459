import InsurerLogos from "@layout/insurer-logos";
import PauseAnimationButton from "@components/general/pause-animation-button";


const LogoContainer = () => {

    return (
        <>
            <div id="logos" className="logos">
                <InsurerLogos/>
            </div>
            <div className="controls">
                <PauseAnimationButton/>
            </div>
        </>
    )

}

export default LogoContainer;