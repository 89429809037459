import Validation from "@forms/validation";
import Utils from "@components/general/AVB_utils";
import { createRef, Component } from "react";


class SwitchField extends Component {

    constructor(props) {
        super(props);

        this.userMessages = [];
        this.validator = createRef();
        this.isEditable = true;

        let startingClass = "";
        if (props.hasOwnProperty("incomplete")) {
            startingClass = props.incomplete ? "field_incomplete" : "field_ok";
        }


        this.state = {
            values: (props.values) ? props.values : '',
            value: (props.userData) ? props.userData : props.value ?? "",
            validation: (props.validation) ? props.validation : '',
            input_class: [startingClass],
            hasError: true,
            isDirty: false,
            fullWidth: (props.fullWidth) ? props.fullWidth : false
        };
    }


    componentDidMount() {
        const {userData} = this.props;
        if (typeof userData !== "undefined") {
            this.setState({
                value: userData
            });
            this.validator.current.updateValue(true, Utils.makeBool(userData));
        }
    }



    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.parentValue !== this.props.parentValue && this.props.parentValue !== '') {
            const {userData, parentValue, values} = this.props;
            const {value} = this.state;
            let currentValue = (!value) ? (userData) ? userData : '' : value;
            let selectValues;

            if (parentValue) {
                selectValues = this.props.parentChange(parentValue);
            } else {
                selectValues = values;
            }

            const entries = Object.entries(selectValues);

            if (entries.length === 2 && parentValue !== '' && parentValue !== null) {
                currentValue = entries[entries.length - 1][0];
                this.updateValue(currentValue);
            }
        }

    }


    isValid = (silent) => {
        return this.validator.current.validate(silent);
    };


    getData = (silent) => {
        let errors = this.isValid(silent);
        if (errors) {
            return false;
        } else {
            const {fieldName} = this.props;
            const {value} = this.state;
            return {
                name: fieldName,
                value: value
            };
        }

    };


    updateRelatedValue = (relatedValue) => {
        const {relatedValuesTrue, relatedValuesFalse} = this.props;
        if (relatedValuesTrue.indexOf(relatedValue) > -1 || relatedValuesFalse.indexOf(relatedValue) > -1) {
            let newValue;
            if (relatedValuesFalse.indexOf(relatedValue) > -1) {
                newValue = 0;
            }
            if (relatedValuesTrue.indexOf(relatedValue) > -1) {
                newValue = 1;
                this.isEditable = false;
            }
            this.handleInput(newValue);
        } else {
            this.handleInput(null, true);
            this.isEditable = true;
        }
    };

    handleKeyboardInput = (event, newVal) => {
        if (event.keyCode == 32 || event.keyCode == 13) {
            this.handleInput(newVal);
            event.preventDefault();
        }
    }

    handleInput = (val) => {
        const {values, changeCallBack, changeCallBackData} = this.props;


        let isSubmitted = false;
        if (this.props.form && this.props.form.current) {
            isSubmitted = this.props.form.current.state.submitted;
        }

        if (isSubmitted) {
            this.validator.current.updateValue(false, val);
        }

        if (this.state.input_class.indexOf('field_incomplete') !== -1) {
            const classes = this.state.input_class;
            if (val !== "") {
                classes.push("field_ok");
            } else {
                const i = this.state.input_class.indexOf('field_ok');
                if (i !== -1) {
                    classes.splice(i,1)
                }
            }
            this.setState({
                input_class: classes
            })
        }

        this.setState({
            value: values[val]
        }, () => {
            if (changeCallBack) {
                changeCallBack(values[val], changeCallBackData);
            }
        });
    };


    onFocus = () => {
        this.markDirty();
    };


    storeErrors(errorMessage) {
        this.userMessages = errorMessage;
        this.validator.current.setErrors(this.userMessages);
    }


    clearErrors() {
        this.userMessages = [];
        this.validator.current.setErrors(this.userMessages);
    }


    handleError = (hasError, messages) => {
        let i = this.state.input_class.indexOf('field_error');
        let add = (hasError && i < 0) ? 'field_error' : false;
        let remove = (!hasError && i > -1) ? 1 : 0;
        let start = (i > -1) ? i : 0;
        let newVal = this.state.input_class;

        if (!hasError && this.state.input_class.indexOf('field_ok') < 0) {
            add = 'field_ok';
        }
        if (hasError && !remove) {
            i = this.state.input_class.indexOf('field_ok');
            remove = (hasError && i > -1) ? 1 : 0;
            start = (i > -1) ? i : 0;
        }

        if (add) {
            newVal.splice(start, remove, add);
        } else {
            newVal.splice(start, remove);
        }

        if (hasError) {
            this.storeErrors(messages.splice(0, 1));
        } else {
            this.clearErrors();
        }

        this.setState({input_class: newVal, hasError: hasError});
    };


    buildField() {
        const {label,values,isEditable,fieldName,id,validation,layoutOrder,activeValue,subLabel, index, testHook, labelClass} = this.props;
        const {value,input_class} = this.state;
        let fieldID = (typeof id === "undefined")?fieldName:id;
        let currentisEditable = (typeof isEditable === "undefined" || isEditable === true)?this.isEditable:isEditable;

        let switchJsx = [];
        let noteJsx = [];
        let errorJsx = [];
        let extraElementsJsx = [];
        let startJsx = [];
        let i = 0;
        let x = 0;

        if (typeof inputClasses !== "undefined" && input_class.indexOf(inputClasses) < 0) {
            input_class.push(inputClasses);
        }

        if (label) {
            let html = label;
            html += this.props.required ? `<span class="required"> *</span>` : '';
            html += this.props.optional ? `<span class="regular">(optional)</span>` : '';
            startJsx.push(
                <div key={i}>
                    <legend
                        className={`label ${input_class.join(' ')} ${labelClass}`}
                        dangerouslySetInnerHTML={{
                            __html: html
                        }}/>
                    {subLabel && (
                        <span className={'label-sub ' + input_class.join(' ')}>{subLabel}</span>
                    )}
                </div>);
            i++;
        }

        if (layoutOrder === 'mid') {
            startJsx.push(noteJsx);
        }

        let currentValue = value;
        for (const val in values) {
            let valTest = value;
            let itemValTest = values[val];
            let indexValue = (typeof index === "undefined") ? "" : "_Item-" + index;
            let testHookWithItemIndex = testHook + "_Value-" + values[val] + indexValue;

            if (Utils.isBoolString(value) !== 'string') {
                valTest = Utils.makeBool(value);
                itemValTest = Utils.makeBool(values[val]);
            }

            let result = (valTest === itemValTest);
            let className = (result) ? 'active' : '';

            if (result) {
                currentValue = val;
            }


            switchJsx.push(
                <span id={fieldID + '-' + values[val]} className={"switch-control " + className + ' ' + input_class.join(' ')} key={i}>
                    <label className="switch" htmlFor={"switch-" + fieldID + '-' + values[val] + (typeof index === "undefined" ? "" : index)}>
                        <input
                            type="radio"
                            name={fieldName + (typeof index === "undefined" ? "" : "_" + index)}
                            value={currentValue}
                            checked={val === currentValue}
                            id={"switch-" + fieldID + '-' + values[val] + (typeof index === "undefined" ? "" : index)}
                            onChange={() => this.handleInput(val)}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault()
                            }}
                            data-incomplete={(this.props.incomplete && !currentValue) ? "inc" + (typeof index === "undefined" ? "" : index) : ""}
                            onFocus={this.onFocus}
                            data-cy={testHookWithItemIndex}
                        />
                        <span className="switch-track"/>
                        <span className="switch-value">{values[val]}</span>
                    </label>
                </span>
            );
            i++;
        }

        if (value === activeValue) {
            extraElementsJsx.push(<div key={i}>{this.props.children}</div>);
        }

        errorJsx.push(<Validation
            value={currentValue}
            validation={validation}
            key={x}
            className={input_class.join(' ')}
            onHasError={this.handleError}
            ref={this.validator}
            values={values}
            testHook={testHook}
            {...this.props}
        />);

        let startNote = (layoutOrder === 'start') ? noteJsx : '';
        let endNote = (layoutOrder === 'end') ? noteJsx : '';
        let holderClassName = (!currentisEditable) ? ' disabled' : '';
        let fullWidth = (this.state.fullWidth) ? ' full-width' : '';
        return (
            <div>
                {startNote}
                <div className={"switch-control-holder" + fullWidth + " " + holderClassName}>
                    <div className={'switches'}>
                        <fieldset>
                            {startJsx}
                            <div id={fieldName + (typeof id !== "undefined" ? "-" + index : "")}>
                                {switchJsx}
                            </div>
                        </fieldset>
                    </div>
                </div>
                {endNote}
                <div className="fieldErrors">
                    {errorJsx}
                </div>
                {extraElementsJsx}
            </div>
        );
    }


    markDirty() {
        this.setState({
            isDirty: true
        });
    }


    render() {
        return this.buildField();
    }

}


export default SwitchField;