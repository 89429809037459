import SVG from "@components/general/svg";
import Loading from "@components/general/loading";
import {SHARED_ASSETS_PATH} from "@constants";


const SubmitButton = ({text="Continue", className="", loading=false, disabled=false,handleClick}) => {

    return (
        <div className="d-flex justify-content-center base-submit-holder mt-4">

            {loading ? (
                <div className="my-1">
                    <Loading/>
                </div>
            ) : (
                <button
                    type="button"
                    className={`submit ${className}`}
                    data-cy={"th_SubmitFormButton"}
                    onClick={disabled ? null : handleClick}
                ><span className="text">{ text }</span>
                    <SVG
                        img={SHARED_ASSETS_PATH + "svg/arrow-right-white.svg"}
                        alt="right arrow icon"
                        className="ioa-pr-xl"
                        aria-hidden="true"
                    />
                </button>
            )}
        </div>
    );

}

export default SubmitButton;