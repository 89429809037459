module.exports = [
    {
        id: "Street1",
        rules: [
            {
                name: "required",
                message: "Street Address is required"
            },
            {
                name: "regex",
                conditions: {
                    pattern: /^[a-z0-9\s.,/#()'"@:;*&-]+$/i
                },
                message: "Street Address contains illegal characters"
            },
            {
                name: "regex",
                conditions: {
                    pattern: /^(\d+)[a-zA-Z0-9 \-./]*( +)([a-zA-Z0-9\s.,/#()'"@:;*&-]){3,}$/
                },
                message: "Street Address has to contain street number and name"
            }
        ]
    },
    {
        id: "Apartment",
        rules: [
            {
                name: "regex",
                conditions: {
                    pattern: /^[a-z0-9]+$/i
                },
                message: "Apt/Unit must only contain numbers and letters"
            }
        ]
    },
    {
        id: "City",
        rules: [
            {
                name: "required",
                message: "City/Town is required"
            },
            {
                name: "regex",
                conditions: {
                    pattern: /^[a-z0-9\s.,'\-&]+$/i
                },
                message: "City/Town contains illegal characters"
            }
        ]
    },
    {
        id: "County",
        rules: [
            {
                name: "regex",
                conditions: {
                    pattern: /^[a-z0-9\s.,'\-&]+$/i
                },
                message: "County contains illegal characters"
            }
        ]
    },
    {
        id: "ConstituentState",
        rules: [
            {
                name: "required",
                message: "State is required"
            }
        ]
    },
    {
        id: "PostalCode",
        rules: [
            {
                name: "required",
                message: "ZIP code is required"
            },
            {
                name: "numeric",
                message: "ZIP code must only contain numbers"
            },
            {
                name: "length",
                conditions: {
                    equals: 5
                },
                message: "ZIP Code should be exactly 5 digits"
            }
        ]
    }
];