export const credit_card = (value) => {

    let sum = 0;
    let isSecond = false;
    value = value.replace(/\D/g, "");

    if (value.length < 13) return false;

    for (let i = value.length - 1; i >= 0; i--) {
        const cDigit = value.charAt(i);
        let digit = parseInt(cDigit, 10);
        if (isSecond && (digit *= 2) > 9) {
            digit -= 9;
        }
        sum += digit;
        isSecond = !isSecond;
    }

    return (sum % 10) === 0;

};