import { Component } from 'react';


class NoteElement extends Component {


    constructor(props) {
        super(props);
        this.state = {
            noteActiveClass: ''
        };
    }


    toggleNote(){
        this.setState((prevState) => {
            if (prevState.noteActiveClass === '') {
                return {noteActiveClass: 'active'}
            }else{
                return {noteActiveClass: ''}
            }
        });
    }


    buildElement() {
        const {value, className} = this.props;
        const {noteActiveClass} = this.state;
        return (
            <>
                <div className={className + " input-note-trigger " + noteActiveClass } onClick={this.toggleNote.bind(this)} >?
                    <div className={"input-note mobile " + noteActiveClass }>
                        <div><div className={"input-note-trigger"} >?</div></div>
                        <div className={'content'} dangerouslySetInnerHTML={{__html:value}} />
                    </div>
                </div>
                <div className={className + " input-note desktop " + noteActiveClass }>
                    <div><div className={"input-note-trigger"} >?</div></div>
                    <div className={'content'} dangerouslySetInnerHTML={{__html:value}} />
                </div>
            </>
        );
    }


    render() {
        return '';
    }

}

export default NoteElement;