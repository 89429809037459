import {useEffect, useState} from 'react';
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import TitleHover from "@components/general/title-hover";


const MaterialAddress = ({id, text, duplicate, error, placeholder, handleKeyup}) => {

    const [address, setAddress] = useState('');
    const [clear, setClear] = useState(false);

    useEffect(() => {
        if (address) {
            document.getElementById(id + "-label").classList.add("shrink")
        }
    }, [error]);


    const handleChange = address => {
        setAddress(address);
    };


    const resetAddress = () => {
        setAddress('')
        setClear(false);
        handleKeyup({}, true)
        document.getElementById('AddressSearch').focus();
    }


    const handleSelect = address => {
        const addressObj = {};
        geocodeByAddress(address)
            .then(results => {
                const address_components = results[0].address_components;
                for (let i = 0; i < address_components.length; i++) {
                    let addressType = address_components[i].types[0];
                    addressObj[addressType] = addressType === "route" ? address_components[i]?.long_name : address_components[i]?.short_name;
                }
                setAddress(`${addressObj.street_number ?? ""} ${addressObj.route ?? ""}, ${addressObj.locality ?? addressObj.political ?? ""}, ${addressObj.administrative_area_level_2 ?? ""}, ${addressObj.administrative_area_level_1 ?? ""} ${addressObj.postal_code ?? ""}, USA`)
                addressObj.StreetView = `${addressObj.street_number ?? ""} ${addressObj.route ?? ""}, ${addressObj.locality ?? addressObj.political ?? ""}, ${addressObj.administrative_area_level_2 ?? ""}, ${addressObj.administrative_area_level_1 ?? ""} ${addressObj.postal_code ?? ""}, USA`;
                addressObj.HouseNumber = addressObj.street_number;
                setClear(true);
                return getLatLng(results[0]);
            })
            .then(latLng => {
                addressObj.lat = latLng.lat;
                addressObj.lng = latLng.lng;
                handleKeyup(addressObj, true);
                document.getElementById(id).blur();
            })
            .catch(error => console.error('Error', error));
    };


    const handleFocus = (e) => {
        const label = document.getElementById(e.target.id + "-label");
        if (label) {
            label.classList.add("shrink")
        }
    }


    const handleBlur = (e) => {
        if (address === "") {
            const label = document.getElementById(e.target.id + "-label");
            if (label) {
                if (!label.classList.contains("error")) {
                    label.classList.remove("shrink")
                }
            }
        }
    }


    const labelClass = error ? "typeAhead error shrink" : clear ? 'typeAhead shrink' : "typeAhead"


    return (
        <PlacesAutocomplete
            highlightFirstSuggestion={true}
            shouldFetchSuggestions={address.length > 3}
            searchOptions={{
                componentRestrictions: {
                    country: 'US'
                },
                types: ['address']
            }}
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
        >
            {({getInputProps, suggestions, getSuggestionItemProps}) => (

                <div className={error ? "oops top" : clear ? 'selected top' : address ? 'relative' : 'searchContainer'}>
                    <input
                        {...getInputProps({
                            placeholder: placeholder,
                            id: id,
                            'data-cy': "th_" + id,
                            className: "ellipsis " + ( error ? "error" : "")
                        })}
                        disabled={clear}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                    {address && (
                        <TitleHover title={"Clear Address"}>
                            <button id="clear-address-button" type="button" className="btn-address btn-clear"
                                    onClick={resetAddress}/>
                        </TitleHover>
                    )}

                    <label className={labelClass} id={id + "-label"} htmlFor={id}>{text} <span
                        className="required">*</span></label>

                    {(address && suggestions.length > 0) && (
                        <div className="autocomplete-dropdown-container">
                            {suggestions.map((suggestion, index) => {
                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                const style = suggestion.active ? {
                                    backgroundColor: '#fafafa',
                                    cursor: 'pointer'
                                } : {backgroundColor: '#ffffff', cursor: 'pointer'};
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {className, style})}
                                        key={index}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    {duplicate ? (
                        <div className="fieldErrors">
                            <p
                                data-cy={`th_${id}-error`}
                                className="field_error"
                            >Primary location of the vehicle must be different from your primary address</p>
                        </div>
                    ) : error ? (
                        <div className="fieldErrors">
                            <p
                                data-cy={`th_${id}-error`}
                                className="field_error">Address is required</p>
                        </div>
                    ) : (
                        <div/>
                    )}

                </div>

            )}
        </PlacesAutocomplete>
    );

}

export default MaterialAddress