import { useEffect, useRef } from "react";
import {Typeahead} from "react-bootstrap-typeahead";
import TitleHover from "@components/general/title-hover";


const MaterialTypeAhead = ({handleKeyup, id, cy, submitted, value, error = false, options, placeholder, text, required = true, preselectedState = ""}) => {

    const refContainer = useRef('');

    let className = 'relative';

    if (submitted) {
        className = error ? 'warning' : value ? 'selected top' : 'relative';
    }


    useEffect(() => {
        if (value || preselectedState) {
            document.getElementById(id + "-label").classList.add("shrink")
        }
    }, [preselectedState, value]);


    const clear = () => {
        refContainer.current.clear();
        handleBlur({target: {value: "", name: id}});
        handleKeyup({target: {value: "", name: id}})
    }


    const handleFocus = () => {
        const label = document.getElementById(id + "-label");
        if (label) {
            label.classList.add("shrink")
        }
    }


    const handleBlur = (e) => {
        if (e.target.value === "") {
            const label = document.getElementById(id + "-label");
            if (label) {
                if (!label.classList.contains("error")) {
                    label.classList.remove("shrink")
                }
            }
        }
    }


    const handleChange = (e) => {
        handleKeyup({target: {value: e.length ? e[0].label : "", name: id}})
    }


    return (
        <>
            <span className={'material-select'}>
                <span className={className}>

                    <Typeahead
                        ref={refContainer}
                        id={`${id}-typeahead`}
                        highlightOnlyResult={true}
                        placeholder={placeholder}
                        options={options}
                        emptyLabel='No options available'
                        inputProps={{
                            "autoComplete": "off",
                            id: id,
                            className: error ? "error" : "",
                            "data-cy": "th_" + cy
                        }}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        selected={[value ?? preselectedState]}
                        onKeyDown={(e) => {
                            let a = e.key;
                            if (/[^a-zA-Z\s]/.test(a)) {
                                e.preventDefault();
                            }
                        }}
                        defaultSelected={[value ?? preselectedState]}
                    >

                        {value ? (
                            <TitleHover title={"Clear State"}><button
                                id="clear-address-button"
                                type="button"
                                className="btn-address btn-clear"
                                onClick={clear}
                            /></TitleHover>
                        ) : (
                            <div className="arrow"/>
                        )}

                        <label
                            id={id + "-label"}
                            htmlFor={id}
                            className={error ? "typeAhead error shrink" : "typeAhead"}
                        >{text} {required && <span className="required">*</span>}</label>

                    </Typeahead>

                </span>
            </span>

            {error && (
                <div className="fieldErrors">
                    <p data-cy={`th_${cy}-error`} className="field_error">{text} is required</p>
                </div>
            )}

        </>
    )

}

export default MaterialTypeAhead;