import {Component} from 'react';
import Utils from "@components/general/AVB_utils";
import {renderToStaticMarkup} from "react-dom/server";

/** @module form */
/**
 *
 * @class
 * @description Create a label element for inputs
 *
 */
class LabelElement extends Component {

    constructor(props) {
        super(props);
    }

    buildElement() {
        const {value, required, optional, className, tooltip, spanClass, ...rest} = this.props;

        const htmlContent = `${value} ${required ? renderToStaticMarkup(<span aria-label='required' role="text"
                                                                              className="required">*</span>) : ""} ${optional ? renderToStaticMarkup(
            <span className="regular"> (optional)</span>) : ""}`;

        return (
            <label
                {...rest}
                className={className ?? ""}
                key={Utils.getIndex()}
            ><span className={`d-flex align-items-center ${spanClass}`}><span dangerouslySetInnerHTML={{__html: htmlContent}}/>{tooltip}</span>
            </label>
        );
    }


    render() {
        return (this.buildElement());
    }
}

export default LabelElement;
