import { Component } from 'react';
import TextField from "@forms/inputs/text";
import formInputs from "@config/forms/get-address";
import TypeAheadSelect from '@forms/inputs/type-ahead-select';
import GoogleMapsLookup from '@forms/inputs/google-maps-lookup';
import {stateLookup} from "@utilities";


class IoaAddressLookup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            query: '',
            ...this.parseDefaultAddress(props.defaultAddress),
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.defaultAddress !== prevProps.defaultAddress) {
            this.setState({
                ...this.parseDefaultAddress(this.props.defaultAddress),
            });
        }
    }

    parseDefaultAddress(address) {
        return address ?
            {
                query: (address.street_number + " " + address.route).trim(),
                city: address.locality,
                apartment: address.apartment,
                county: address.administrative_area_level_2,
                state: stateLookup('state', address.administrative_area_level_1 ,'label'),
                zipCode: address.postal_code
            }
            :
            null;
    }

    parseAddress = ({streetAddress, city, county, state, zipCode, streetAddressInvalid}) => {
        const streetWasInvalid = this.props.elements['StreetAddress-0'].current.state.hasError;
        this.setState({
            query: streetAddress,
            city: city,
            county: county,
            state:stateLookup('state', state ,'label'),
            zipCode: zipCode,
            streetAddressInvalid: streetAddressInvalid
        }, () => {
            if (streetWasInvalid) {
                this.props.elements['StreetAddress-0'].current.isValid()
            }
        });
    }

    getData = () => {
        const {elements} = this.props;
        let filteredInputs = [];
        let formName = '';
        let formIndex = 0;
        let firstError = null;

        for (const element in inputs) {
            if (element === "formName") {
                formName = elements[element];
            } else {
                formIndex = parseInt(element.split("-")[1]);
                if (typeof filteredInputs[formIndex] === "undefined") {
                    filteredInputs[formIndex] = []
                }
                if (inputs[element].current !== null) {
                    filteredInputs[formIndex].push(inputs[element]);
                }
            }
        }

        let formValid = true;
        let i = 0;

        while (i <= formIndex) {
            if (typeof filteredInputs[i] !== "undefined") {
                for (const elementIndex in filteredInputs[i]) {
                    let chk = filteredInputs[i][elementIndex].current.getData(silent, filteredInputs[i]);
                    if (chk) {
                        if (typeof this.formData[i] === "undefined") {
                            this.formData[i] = []
                        }
                        this.formData[i][chk.name] = chk.value;
                    } else {
                        if (firstError === null) {
                            firstError = filteredInputs[i][elementIndex].current.props.id ? filteredInputs[i][elementIndex].current.props.id : filteredInputs[i][elementIndex].current.props.fieldName
                        }
                        formValid = false;
                    }
                }
            }
            i++;
        }

        return {
            valid: formValid,
            formName: formName,
            firstError: firstError
        };
    };

    isValid = (silent, filteredInputs) => {
        return this.validator.current.validate(silent, this.state.value, filteredInputs);
    };

    checkResults = (event) => {
        let autocompleteService = new google.maps.places.AutocompleteService();
        const inputValue = event.target.value;
        autocompleteService.getPlacePredictions(
            {
                input: inputValue,
                type: ["address"],
                componentRestrictionstypes: {country: "us"}
            },
            (predictions, status) => {
                if (inputValue.length > 5 && !predictions) {
                    this.setState({
                        streetInvalid: true
                    }, this.props.elements['StreetAddress-0'].current.isValid)
                }
            }
        );
    }

    render() {
        const {appObj} = this.props;

        for (const element in formInputs) {
            formInputs[element].form = this.form;
        }

        const backgroundClass = this.props.greyBackground ? 'grey-background' : '';

        return (
            <div className={backgroundClass}>
                <div className='row'>
                    <div className="col-12 col-md-10 col-lg-7">
                        <div className='street-apartment'>
                            <div className='formField'>
                                <GoogleMapsLookup
                                    formRef={this.props.elements['StreetAddress-0']}
                                    onAddressSelected={this.parseAddress}
                                    onManualChange={value => this.setState({query: value})}
                                    userData={this.state.query}
                                    {...formInputs.StreetAddress}
                                />
                            </div>
                            <div className='formField'>
                                <TextField
                                    {...formInputs.AptUnit}
                                    userData={this.state.apartment}
                                    ref={this.props.elements['AptUnit-0']}
                                    changeCallBack={value => this.setState({apartment: value})}
                                    related={this.props.elements['StreetAddress-0']}
                                    skipValidation={this.state.query.length === 0 || this.state.streetAddressInvalid}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-12 col-sm-6 col-md-5 col-lg-3">
                        <div className='formField'>
                            <TextField
                                userData={this.state.city}
                                {...formInputs.City}
                                ref={this.props.elements['City-0']}
                                related={this.props.elements['StreetAddress-0']}
                                changeCallBack={value => this.setState({city: value})}
                                skipValidation={this.state.query.length === 0 || this.state.streetAddressInvalid}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-5 col-lg-3">
                        <div className='formField'>
                            <TextField
                                userData={this.state.county}
                                {...formInputs.County}
                                ref={this.props.elements['County-0']}
                                related={this.props.elements['StreetAddress-0']}
                                changeCallBack={value => this.setState({county: value})}
                                skipValidation={this.state.query.length === 0 || this.state.streetAddressInvalid}
                            />
                        </div>
                    </div>
                    <div className="d-none d-lg-block col-12 col-lg-1"/>
                </div>
                <div className='row'>
                    <div className="col-12 col-sm-6 col-md-5 col-lg-3">
                        <div className='formField'>
                            <TypeAheadSelect
                                id={'state-select'}
                                {...formInputs.State}
                                ref={this.props.elements['State-0']}
                                userData={this.state.state}
                                related={this.props.elements['StreetAddress-0']}
                                changeCallBack={value => this.setState({state: value})}
                                skipValidation={this.state.query.length === 0 || this.state.streetAddressInvalid}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-5 col-lg-4">
                        <div className='formField'>
                            <TextField
                                userData={this.state.zipCode}
                                {...formInputs.ZipCode}
                                ref={this.props.elements['ZipCode-0']}
                                related={this.props.elements['StreetAddress-0']}
                                changeCallBack={value => this.setState({zipCode: value})}
                                skipValidation={this.state.query.length === 0 || this.state.streetAddressInvalid}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IoaAddressLookup;