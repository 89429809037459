import { useEffect, useState } from "react";


export default function PauseAnimationButton() {

    const [animation, setAnimation] = useState("true");

     
    useEffect(() => {
        let animationPreference = localStorage.getItem('allow-animation');
        animationPreference = animationPreference ?? "true";
        updateAnimation(animationPreference);

    }, [])


    const setAnimationPreference = () => {
        const newPreference = animation === "true" ? "false": "true";
        localStorage.setItem('allow-animation', newPreference);
        updateAnimation(newPreference);
    }


    const updateAnimation = (preference) => {
        setAnimation(preference);
        if (preference === "true") {
            document.body.classList.add('animate');
        } else {
            document.body.classList.remove('animate');
        }
    }


    return (
        <div aria-live="polite" className="container animationControl">
            <button
                type="button"
                className='pause'
                data-cy={"th_AnimationControlButton"}
                onClick={setAnimationPreference} 
            >{animation === "true" ? 'Pause' : 'Play'} Animation</button>
        </div>
    );

}