import { createRef, Component } from 'react';
import SVG from "@components/general/svg";
import {logSubmitEvent} from "@vendor/analytics"
import { spamMessage } from "@config/site";
import {SHARED_ASSETS_PATH} from "@constants";


class Submit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isEditable: (props.isEditable) ? props.isEditable : false,
            value: (props.value) ? props.value : '',
            input_class: [props.input_class],
            hasError: true
        };

        this.input = createRef();
    }


    handleError = (hasError, messages) => {
        let i = this.state.input_class.indexOf('field_error');
        let add = (hasError && i < 0) ? 'field_error' : 'field_ok';
        let remove = (!hasError && i > -1) ? 1 : 0;
        let start = (i > -1) ? i : 0;
        let newVal = this.state.input_class;

        if (add) {
            newVal.splice(start, remove, add);
        } else {
            newVal.splice(start, remove);
        }

        if (hasError) {

            this.storeErrors(messages.splice(0,1));
        } else {
            this.clearErrors();
        }

        this.setState({ input_class: newVal, hasError: hasError });
    };


    buildField() {
        const { isEditable, fieldName, onClick, statement, testHook, back, close } = this.props;
        const { value, input_class } = this.state;

        let jsx = [];
        let i = 0;

        if (close) {
            jsx.push(
                <button type="submit" className="remove-driver-cancel" onClick={close}>
                    Cancel
                </button>
            );
        }

        if (back) {
            jsx.push(back);
        }

        jsx.push(
            <button
                aria-label={value}
                type="submit"
                disabled={(!isEditable) ? isEditable : ''}
                name={fieldName}
                id={fieldName}
                onClick={(event) => {onClick(event); logSubmitEvent(value)}}
                className={input_class.join(' ') + ' ioa-pl-xl'}
                key={fieldName}
                ref={this.input}
                data-cy={testHook}
            ><span className="ioa-pr-sm">{value}</span> <SVG img={SHARED_ASSETS_PATH + "svg/arrow-right-white.svg"} alt="right arrow icon" className="ioa-pr-xl" /></button>
        );

        i++;

        if (this.props.className !== undefined && this.props.className.includes('submit-holder')) {
            return (
                <div className={this.props.className}>
                    {jsx}
                </div>
            );

        } else {
            return (
                <div className={'base-submit-holder container'}>
                    <div className="row separator mb-2">
                        {statement ? (
                            <>
                                <div className={`col-12 col-lg-6 d-md-flex align-items-md-start`}>
                                    <div data-cy={"th_Disclaimer_Statement"} className={'statement'} dangerouslySetInnerHTML={{ __html: statement }} />
                                </div>
                                <div className={`col-12 col-lg-6 d-md-flex flex-column justify-content-end`}>
                                    <div className="d-flex flex-column align-items-md-end">
                                        {!this.props.loading && (
                                            <div className="float-lg-right">{jsx}</div>
                                        )}

                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className={`col-12 d-md-flex flex-column justify-content-end`}>
                                <div className="d-flex flex-column align-items-md-end">
                                    {!this.props.loading && (
                                        <div className="float-lg-right">{jsx}</div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    {spamMessage && (
                        <div className="row">
                            <div className={`col-12 d-md-flex flex-column justify-content-end`}>
                                <div className="privacy-disclosure-container">
                                    <SVG src={'lock'} className="mr-1" alt="Secure padlock icon" />
                                    <span className="privacy-disclosure ioa-pl-sm">{ spamMessage }</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )
        }
    }

    render() {
        return this.buildField();
    }

}

export default Submit;