import {age} from "./age";
import {credit_card} from "./credit_card";
import {date} from "./date";
import {email} from "./email";
import {credit_card_active} from "./credit_card_active";
import {credit_card_expired} from "./credit_card_expired";
import {length} from "./length";
import {maximum_length} from "./maximum_length";
import {minimum_length} from "./minimum_length";
import {numeric} from "./numeric";
import {range} from "./range";
import {regex} from "./regex";
import {required} from "./required";
import {required_if} from "./required_if";
import {telephone} from "./telephone";
import {text_only} from "./text_only";


module.exports = {

    "age": ({value, conditions}) => age(value, conditions),

    "credit_card": ({value}) => credit_card(value),

    "email": ({value}) => email(value),

    "credit_card_active": ({value, conditions}) => credit_card_active(value, conditions),
    
    "credit_card_expired": ({value}) => credit_card_expired(value),

    "date": ({value}) => date(value),

    "length": ({value, conditions}) => length(value, conditions),

    "maximum_length": ({value, conditions}) => maximum_length(value, conditions),

    "minimum_length": ({value, conditions}) => minimum_length(value, conditions),

    "numeric": ({value}) => numeric(value),

    "range": ({value, conditions}) => range(value, conditions),

    "regex": ({value, conditions, data}) => regex(value, conditions, data),

    "required": ({value}) => required(value),

    "required_if": (data) => required_if(data),

    "telephone": ({value}) => telephone(value),


    "text": ({value}) => text_only(value),

};