import * as validationRules from "@hooks/useValidation/rules";


const useValidation = (rules) => {


    const fieldIds = {};

    for (let rule of rules) {
        fieldIds[rule.id] = "";
    }


    const findValidationRulesById = (id) => {
        const fieldRules = rules.find((rule) => rule.id === id);
        if (fieldRules) {
            return fieldRules.rules;
        }
        return false;
    };


    const processValidationRule = (name, conditions, value, data) => {
        return validationRules.hasOwnProperty(name) ? !validationRules[name]({value, conditions, data}) : false;
    };


    const runValidation = (fieldRules, value, data) => {
        return fieldRules.filter((rule) => {
            const {name, conditions} = rule;
            return processValidationRule(name, conditions, value, data);
        }).map((rule) => {
            return rule.message;
        });
    };


    const validate  = (formData) => {

        const errors = {};

        Object.entries(formData).forEach(([id, value]) => {

            const fieldRules = findValidationRulesById(id);

            if (fieldRules) {
                const errorMessages = runValidation(fieldRules, value, formData);
                const hasErrors = !!(errorMessages.length);

                if (hasErrors) {
                    errors[id] = errorMessages[0];
                }

            }

        });

        return [errors, Object.keys(errors).length, !Object.keys(errors).length];
    };

    return [validate, fieldIds];

};


export default useValidation;