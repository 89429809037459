import Tippy from "@tippyjs/react";
import useDetectDevice from "@hooks/useDetectDevice";

const popperOptions = {
    modifiers: [
        {
            name: 'flip',
            options: {
                fallbackPlacements: ['top', 'bottom']
            }
        }, {
            name: 'preventOverflow',
            options: {
                altAxis: true,
                tether: false
            }
        }, {
            name: 'offset',
            options: {
                offset: [0, 8],
            }
        }
    ]
};


const TitleHover = ({children, title}) => {

    return (
        <Tippy
            content={title}
            delay={[750,50]}
            trigger={'mouseenter'}
            touch={false}
            arrow={false}
            placement="top"
            popperOptions={popperOptions}
            className={"title"}
            disabled={useDetectDevice()}
        >{ children }</Tippy>
    )

}


export default TitleHover;