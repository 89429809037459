import { createRef, Component } from 'react';
import { Typeahead } from "react-bootstrap-typeahead";
import NoteElement from "@forms/note";
import LabelElement from "@forms/label";
import Validation from "@forms/validation";
import SVG from "@components/general/svg";
import TitleHover from "@components/general/title-hover";


class TypeAheadSelect extends Component {

    constructor(props) {

        super(props);

        this.userMessages = [];
        this.validator = createRef();
        this.input = createRef();
        this.isEditable = true;

        let startingClass = "";
        if (props.hasOwnProperty("incomplete")) {
            startingClass = props.incomplete ? "field_incomplete" : "field_ok";
        }

        this.state = {
            isEditable: (props.isEditable)?props.isEditable:false,
            value: (!props.userData)?(props.value)?props.value:'':props.userData,
            validation: (props.validation)?props.validation:'',
            input_class:[props.input_class, startingClass],
            label:props.label,
            hasError:true,
            isDirty:false,
            rawInput: ''
        };

    }


    componentDidMount() {
        const {userData} = this.props;
        if(typeof userData !== "undefined"){
            this.setState({
                value : userData
            });
            this.validator.current.updateValue(true,userData);
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot){

        if (prevState.value !== this.state.value) {
            this.buildField()
        }

        if (prevProps.userData !== this.props.userData) {
            const {userData} = this.props;
            if (typeof userData !== "undefined"){
                this.setState({
                    value : userData
                }, this.buildField);
                this.clearErrors();
                this.validator.current.updateValue(false,userData);
            }
        }

        if (prevProps.parentValue !== this.props.parentValue && this.props.parentValue !== '') {
            const {userData,parentValue,values} = this.props;
            const {value} = this.state;
            let currentValue = (!value) ? (userData) ? userData : '' : value;
            let selectValues;
            if (parentValue) {
                selectValues = this.props.parentChange(parentValue);
                this.setState({
                    value: ""
                });
                this.validator.current.updateValue(true,"");
            } else {
                selectValues = values;
            }

            const entries = Object.entries(selectValues);

            if (entries.length <= 2 && parentValue !== '' && parentValue !== null) {
                currentValue = entries[entries.length - 1][0];
                this.updateValue(false,currentValue);
            }
        }

    }


    isValid = (silent) => {
        return this.validator.current.validate(silent);
    };


    getValue = () => {
         const {value} = this.state;
         return value.toString();
    };


    setValue = (option) => {
        this.setState({
            value: option
        })
    };


    getData = (silent) => {
        let errors = this.isValid(silent);
        if(errors){
            return false;
        }else{
            const {fieldName} = this.props;
            const {value} = this.state;
            return {
                name: fieldName,
                value: value
            };
        }
    };


    handleInput = (e,silent = false) => {
        this.markDirty();
        let val = (typeof e.target == "undefined")?e:e.target.value;
        this.updateValue(silent,val);
    };


    updateValue(silent,value){
        const {changeCallBack} = this.props;
        const val = value[0]?.label ?? this.input.current.state.text;
        this.setState({
            value: val
        }, () => {
            const isValidOption = this.input.current.props.options.filter(option => option.label === val).length > 0;

            if (typeof changeCallBack !== "undefined" && isValidOption) {
                changeCallBack(val);
                this.clearErrors();
            }
        });

        const isSubmitted = this.props.form ? this.props.form.current.state.submitted : false;
        this.validator.current.updateValue(isSubmitted, val);

        const {related} = this.props;
        if (typeof related !== "undefined" && related.current) {
            related.current.updateRelatedValue(val);
        }

    }

    updateRelatedValue = (relatedValue) => {
        const {relatedValues} = this.props;
        if(relatedValues) {
            if (relatedValues.indexOf(relatedValue) > -1) {
                this.handleInput(1);
            } else {
                this.handleInput(null, true);
            }
        }
    };


    onFocus = () => {
        this.markDirty();
    };


    storeErrors(errorMessage){

        this.userMessages = errorMessage;
        this.validator.current.setErrors(this.userMessages);
    }


    clearErrors(){
        this.userMessages = [];
        this.validator.current.setErrors(this.userMessages);
        this.setState({input_class:["field_ok"]})
    }


    handleError = (hasError,messages) => {
        let i = this.state.input_class.indexOf('field_error');
        let add = (hasError && i < 0)?'field_error':false;
        let remove = (!hasError && i > -1)?1:0;
        let start = (i > -1)?i:0;
        let newVal = this.state.input_class;

        if (!hasError && this.state.input_class.indexOf('field_ok') < 0) {
            add = 'field_ok';
        }

        if (hasError && !remove) {
            i = this.state.input_class.indexOf('field_ok');
            remove = (hasError && i > -1)?1:0;
            start = (i > -1)?i:0;
        }

        if (add) {
            newVal.splice(start,remove,add);
        } else {
            newVal.splice(start,remove);
        }

        if (hasError) {
            this.storeErrors(messages.splice(0,1));
        } else {
            this.clearErrors();
        }

        this.setState({input_class: newVal, hasError: hasError});
    };

    clearValue() {
        this.input.current.clear();
        this.setState({ rawInput: '', value: '' });
        this.validator.current.updateValue(false, '');
        this.props.changeCallBack('');
        this.input.current.focus();
    }

    buildField() {
        const {label,subLabel,note_class,style,values,isEditable,note,fieldName,id,validation,parentValue,related, selectClass, hideLabel, inputClasses, index, testHook} = this.props;
        const {input_class} = this.state;

        let selectClassStr = (typeof selectClass === "undefined")?'':selectClass;
        let fieldID = (typeof id === "undefined")?fieldName:id;
        let currentIsEditable = (typeof isEditable === "undefined" || isEditable === true)?this.isEditable:isEditable;

        let jsx = [];
        let errorJsx = [];
        let i = 0;
        let selectValues = [];

        if (label && !hideLabel) {
            jsx.push(
                <div key={i}>
                    <LabelElement
                        key={`${i}-label`}
                        value={label}
                        htmlFor={fieldID}
                        className={input_class.join(' ')}
                        required={this.props.required}
                    />
                    {subLabel && <span className={'label-sub'} >{subLabel}</span>}
                </div>
            );

            i++;
        } else {
            jsx.push(
                <div key={i} className="mt-2"/>
            );
            i++;
        }

        if (parentValue) {
            selectValues = this.props.parentChange(parentValue);
        } else {
            Object.keys(values).forEach((key,index) => {
                let option = {};
                option[key] = key !== "" ? values[index] : "";
                const label = values[key].label ?? values[key];
                if (key === "") {
                    selectValues.unshift({ id: key, label: label });
                } else {
                    selectValues.push({ id: key, label: label });
                }
            });
        }

        if (typeof inputClasses !== "undefined" && input_class.indexOf(inputClasses) < 0) {
            input_class.push(inputClasses);
        }

        let indexValue = (typeof index === "undefined") ? "" : "_Item-" + index;
        let testHookWithItemIndex = testHook + indexValue;

        let fieldClass;

        if (this.state.isMenuOpen) {
            if (this.state.rawInput.length === 0 && this.state.value?.length === 0 && this.state.value[0]?.length !== 0) {
                fieldClass = 'location-search-input';
            }
        }

        const showClearButton = (this.state.rawInput.length > 0 || this.state.value?.length > 0 || this.state.value[0]?.length === 0);

        jsx.push(
            <div key={`${i}-div`} className={'type-ahead-select select' + selectClassStr + ' ' +  input_class.join(' ')} style={style}>

                <Typeahead
                    id={`${id}-typeahead`}
                    options={selectValues}
                    placeholder={this.props.placeHolder ? this.props.placeHolder : "Please select a state"}
                    onChange={this.handleInput.bind(this)}
                    defaultSelected={[this.state.value]}
                    selected={[{ id: this.state.value, label: this.state.value }]}
                    ref={this.input}
                    onMenuToggle={isOpen => this.setState({ isMenuOpen: isOpen })}
                    onInputChange={text => { this.setState({ rawInput: text }); this.props.changeCallBack(text)}}
                    emptyLabel='No States found'
                    data-incomplete={(this.props.incomplete && !this.state.value) ? "inc" + index : ""}
                    inputProps={{
                        "autoComplete": "off",
                        className: `${fieldClass} ${input_class.join(' ')}`,
                        "data-cy": `${testHookWithItemIndex}`,
                        id: id,
                        name: fieldName
                    }}
                />
                { showClearButton && (
                    <TitleHover title={"Clear State"}><button
                        type="button"
                        className="type-ahead-clear-button"
                        onClick={this.clearValue.bind(this)}
                    ><SVG src={"close"} alt={"clear field"} aria-hidden="true"/><span className="sr-only">close</span></button></TitleHover>
                )}
                {!this.state.isMenuOpen && this.state.value?.length === 0 && this.state.value[0]?.length !== 0 && currentIsEditable && <div className="type-ahead-select__arrow"/>}
            </div>
        );
        i++;

        if (note && typeof related === "undefined") {
            jsx.push(<NoteElement className={note_class} value={note } key={`${i}-validation`}/>);
            i++;
        }

        errorJsx.push(
            <Validation
                value={this.state.value}
                validation={validation}
                className={input_class.join(' ')}
                related={related}
                onHasError={this.handleError}
                ref={this.validator}
                values={values}
                key={`${i}-validation-comp`}
                {...this.props}
                testHook={testHookWithItemIndex}
            />
        );

        return (
            <>
                {jsx}

                <div key={`${i}-errors`} className="fieldErrors">{errorJsx}</div>
            </>
        );
    }

    markDirty(){
        if (!this.state.isDirty) {
            this.setState({
                isDirty: true
            });
        }
    }

    render() {
        return this.buildField();
    }
}

export default TypeAheadSelect;
