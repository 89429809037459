import { createRef, Component } from 'react';
import Submit from "@forms/inputs/submit";
import {scroller} from "react-scroll";
import { Scrollbars } from 'react-custom-scrollbars-2';

class Form extends Component {

    constructor(props) {
        super(props);

        this.elements = [];
        this.formData = [];
        this.submitButton = createRef();
        this.scrollerRef = createRef();
        this.hideSubmit = props.hideSubmit;

        this.state = {
            submitted: false,
            breakpoint: false
        }

        this.detectScreenSize = this.detectScreenSize.bind(this);
    }


    componentDidMount() {
        window.addEventListener('resize', this.detectScreenSize);
        this.detectScreenSize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.detectScreenSize);
    }

    detectScreenSize() {
        let breakpoint = false;
        const modal = document.getElementById('get-quote-form-step-instruction-driver-information-add-modal')
        if (modal) {
            breakpoint = window.innerHeight > 840
        }
        this.setState({
            breakpoint: breakpoint
        });
    }

    onSubmit(e) {
        e.preventDefault();

        const {action} = this.props;

        let result = this.getDataFromForm();
        let formValid = result.valid;
        let formName = result.formName;

        if (action && formValid) {
            this.props.appProcessorObj?.scrollToTop();
            action(this.formData,formName);
        }

        if (!formValid) {
            const {errors} = result;
            const panels = document.querySelectorAll('[data-panel]');
            for(let i=0; i <panels.length;i++) {
                const cardErrors = errors.filter((error) => {
                    return error.includes("-" + i.toString())
                })
                if (cardErrors.length > 0) {
                    panels[i].classList.remove('d-none');
                    document.querySelector('[data-top="' + i + '"]').classList.remove('hover');
                    let btn = document.querySelector('[data-button="' + i + '"]');
                    if (btn) {
                        btn.setAttribute('aria-expanded', 'true');
                        btn.querySelector(".text").innerText = "Hide";
                        btn.classList.add("rotate180");
                    }
                }
            }
            if (result.firstError !== null) {
                const modalId = 'get-quote-form-step-instruction-driver-information-add-modal';

                const modal = document.getElementById(modalId);

                if (modal) {
                    const getElemDistance = ( elem ) => {
                        let location = 0;
                        if (elem.offsetParent) {
                            do {
                                location += elem.offsetTop;
                                elem = elem.offsetParent;
                            } while (elem);
                        }
                        return location >= 0 ? location : 0;
                    };

                    const elem = document.getElementById(result.firstError);
                    const location = getElemDistance( elem );

                    const wrap = document.getElementById('wrap');
                    if (wrap) {
                        this.scrollerRef.current.scrollTop(location - wrap.offsetTop - 100)
                    }


                    scroller.scrollTo(result.firstError, {
                        duration: 500,
                        offset: -50,
                        smooth: true,
                        containerId: modalId
                    });
                }
                else {
                    scroller.scrollTo(result.firstError, {
                        duration: 500,
                        offset: -130,
                        smooth: true
                    });
                }
                this.setState({
                    submitted: true
                })
            } else {
                this.props.appProcessorObj.scrollToError();
            }
        }
    }

    getDataFromForm(silent = false) {
        const {inputs} = this.props;
        let filteredInputs = [];
        let formName = '';
        let formIndex = 0;
        let firstError = null;

        for (const element in inputs) {
            if(element === "formName") {
                formName = inputs[element];
            } else {
                formIndex = parseInt(element.split("-")[1]);
                if (typeof filteredInputs[formIndex] === "undefined") {
                    filteredInputs[formIndex] = []
                }
                if (inputs[element].current !== null) {
                    filteredInputs[formIndex].push(inputs[element]);
                }
            }
        }

        let formValid = true;
        let i = 0;
        let errors = [];
        while (i <= formIndex) {
            if (typeof filteredInputs[i] !== "undefined") {
                for (const elementIndex in filteredInputs[i]) {
                    if (!filteredInputs[i][elementIndex].current.props.skipValidation) {
                        let chk = filteredInputs[i][elementIndex].current.getData(silent, filteredInputs[i]);
                        if (chk) {
                            if (typeof this.formData[i] === "undefined") {
                                this.formData[i] = {}
                            }
                            this.formData[i][chk.name] = chk.value;
                        } else {
                            if (firstError === null) {
                                firstError = filteredInputs[i][elementIndex].current.props.id ? filteredInputs[i][elementIndex].current.props.id : filteredInputs[i][elementIndex].current.props.fieldName
                            }
                            errors.push(filteredInputs[i][elementIndex].current.props.id ? filteredInputs[i][elementIndex].current.props.id : filteredInputs[i][elementIndex].current.props.fieldName);
                            formValid = false;
                        }
                    }
                }
            }
            i++;
        }

        return {
            errors: errors,
            valid: formValid,
            formName: formName,
            firstError: firstError,
            data: this.formData
        };
    }


    render() {
        const {submit,className, replacement, overflow} = this.props;

        return (
            <form className={className}  method={'post'} onSubmit={() => { return false}}>

                    {(overflow && this.state.breakpoint) ? (
                        <div id="wrap" className="wrap">
                            <Scrollbars
                                universal
                                autoHeight
                                autoHeightMin={275}
                                autoHeightMax={450}
                                ref={(ref) => (this.scrollerRef.current = ref)}
                            >
                                <div className={"pr-4 pl-1 py-3"}>
                                    {this.props.children}
                                </div>
                            </Scrollbars>

                        </div>
                    ) : (
                        <>{this.props.children}</>
                    )}


                {!this.props.hideSubmit && <Submit
                    key={0}
                    {...submit}
                    onClick={this.onSubmit.bind(this)}
                    ref={this.submitButton}
                    loading={this.props.loading}
                />}
                {(this.props.hideSubmit && this.props.replacement) && (
                    <>{ replacement }</>
                )}
            </form>
        )
    }

}

export default Form;