import { useState } from "react";
import SVG from "@components/general/svg";
import TitleHover from "@components/general/title-hover";
import {SHARED_ASSETS_PATH} from "@constants";

const HelpBanner = () => {

    const [display,setDisplay] = useState(true);


    const handleClick = () => {
        setDisplay(!display)
    }


    return (
        <div aria-live="polite">
            {display ? (
                <div className="help">
                    <TitleHover title={"Close"}><button
                        className={'link'}
                        type={"button"}
                        onClick={handleClick}
                    ><SVG img={SHARED_ASSETS_PATH + 'svg/help-close.svg'} alt="" aria-hidden="true"/><span className="sr-only">close</span></button></TitleHover>
                    <h4>Entering your address is the <br/>quickest way to get a quote</h4>
                    <p className="small mb-0">We use it to find drivers and vehicles at <br/>your home and save you time.</p>
                </div>
            ) : (
                <div className="help closed">
                    <TitleHover title={"Open"}><button
                        className={'link'}
                        type={"button"}
                        onClick={handleClick}
                    ><SVG img={SHARED_ASSETS_PATH + 'svg/help-open.svg'} alt="" aria-hidden="true"/><span className="sr-only">expand</span></button></TitleHover>
                </div>
            )}
        </div>
    )


}

export default HelpBanner