import {useEffect} from "react";
import useLiveChat from "@hooks/useLiveChat";


const LiveChatIcon = () => {

    const {
        updateLiveChatVisibility
    } = useLiveChat();

    useEffect(() => {
        updateLiveChatVisibility("minimize");
    }, []);


    return <div/>;

};


export default LiveChatIcon;

