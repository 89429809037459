import {Image} from "react-bootstrap";
import {CARRIER_IMAGE_PATH} from "@constants";
import {providers} from "@config/site"


function InsurerLogos({carrierId, mainOnly = false}) {

    const carriers = mainOnly ? providers.main : [...providers.main, ...providers.others];


    const renderProvider = () => {
        let logo = CARRIER_IMAGE_PATH + carrierId + "--color.png";
        return (
            <div className={`logo`} key={Math.random()}>
                <Image className="large" src={logo} alt={"company logo for " + carrierId + " Insurance"}/>
            </div>
        )
    }


    const renderProviders = () => {
        let html = [];

        while (html.length < 28) {
            for (const element of carriers) {
                let logo = CARRIER_IMAGE_PATH + element + "--thumbnail--mono.png";
                html.push(
                    <div className={`logo`} key={Math.random()}>
                        <Image src={logo} alt={"company logo for " + element + " Insurance"}/>
                    </div>
                )
            }
        }

        return html;
    }


    return (
        <div id="insurers-container" className={`insurers`}>
            <div className={`contents`}>
                {carrierId ?
                    (
                        <div className={`slide-track justify-content-center`}>
                            {renderProvider()}
                        </div>

                    ) : (
                        <div className={`slide-track ` + (mainOnly ? "animateMainLogos": 'animateLogos')}>
                            {renderProviders()}
                        </div>
                    )
                }
            </div>
        </div>
    )

}

export default InsurerLogos;
