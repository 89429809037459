const PageTitle = () => {

    return (
        <div className='pageTitle'>
            <h1>Let's get started</h1>
            <h2 className={'small'}>Compare and buy auto insurance</h2>
        </div>
    )

}

export default PageTitle