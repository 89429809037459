import {useEffect, useState} from "react";

import ManualAddress from "@components/general/manual-address";
import SVG from "@components/general/svg";
import Loading from "@components/general/loading";
import {stateLookup} from "@utilities";
import Toast from "@components/general/toast";
import {SHARED_ASSETS_PATH} from "@constants";


const AddressEdit = (props) => {

    const {
        loading,
        address,
        handleChange
    } = props;


    const [display, setDisplay] = useState(false);
    const [toast, setToast] = useState(false);
    const [streetView, updateStreetView] = useState(null)

    useEffect(() => {
        if (address?.hasOwnProperty("street_view")) {
            updateStreetView(address.street_view)
        }
    }, [])

    const displayAddress = () => {
        setDisplay(true);
        setToast(false);
    }

    const updateAddress = (data) => {
        const address_str = (data.apartment ? "Apt/Unit #" + data.apartment + " " : "") + data.route + ", " + data.locality + ", " + stateLookup("label", data.administrative_area_level_1, "abv") + " " + data.postal_code + ", USA";
        updateStreetView(address_str)
        setDisplay(false)
        setToast(true)
        handleChange(data)
    };


    return (
        <>
            {toast && (<Toast type={'address'}>Address Updated</Toast>)}

            <div className={"addressEdit " + (loading ? "" : "p-4")}>
                {loading ? (
                    <Loading/>
                ) : (
                    <>
                        <div className="icon"><SVG img={SHARED_ASSETS_PATH + 'svg/map-marker.svg'}/></div>
                        <div className="text">
                            <h2 className={`${address.apartment ? "tiny" : "small"}`}>{streetView}</h2>
                            <button
                                type={"button"}
                                className={'btn-link'}
                                data-cy="change-address"
                                onClick={displayAddress}
                            >Edit Address
                            </button>
                        </div>
                        {display && (
                            <ManualAddress
                                edit={true}
                                address={address}
                                close={() => setDisplay(false)}
                                action={updateAddress}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    )

};

export default AddressEdit;