import formInputs from "@config/forms/get-quote";
import Form from "@forms/form";
import Utils from "@components/general/AVB_utils";
import {getPostAPI, hijackExternalLinks, propExists, resetPagePosition} from "@utilities";
import { createRef, Component } from "react";
import TextField from "@forms/inputs/text";
import SwitchField from "@forms/inputs/switch";
import VWO from "@vendor/vwo";
import GoalConversion from "@vendor/goal-conversion"
import Header from "@forms/header";
import IoaAddressLookup from "@forms/inputs/ioa_address_lookup";
import addressFormInputs from "@config/forms/get-address";
import AddressConfirmationModal from "@modals/address-confirmation-modal";
import AddressEdit from "@forms/inputs/address_edit";
import MaskedTextField from "@forms/inputs/maskedtext";



class DetailsStep extends Component {

    constructor(props) {
        super(props);
        this.state = {
            privacyPolicy: formInputs.HasAcceptedDisclaimer,
            showPreviousAddress: false,
            showAddressConfirmation: false,
            address: '',
            mainAddress: props.appProcessorObj.params?.InsuredAddress
        };
        this.elements = {};
        this.form = createRef();
        this.buildPrivacyPolicy = this.buildPrivacyPolicy.bind(this);
        this.detectScreenSize = this.detectScreenSize.bind(this);
        this.setShowPreviousAddress = this.setShowPreviousAddress.bind(this);
    }


    componentDidMount() {
        hijackExternalLinks();
        resetPagePosition();
        const formLoading = propExists(this, "props.appObj.state.formLoading") ? this.props.appObj.state.formLoading : false;
        if (!formLoading) {
            Utils.getNationwidePrivacyPolicy(this.buildPrivacyPolicy);
        }
        window.addEventListener('resize', this.detectScreenSize);
        this.detectScreenSize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.detectScreenSize);
    }

    detectScreenSize() {
        this.setState({
            isLargeScreen: window.matchMedia("(min-width: 768px)").matches
        });
    }

    submitAddressConfirmation() {
        this.props.handleAction(this.state.formData, this.elements.formName);
    }

    cancelAddressConfirmation() {
        this.setState({showAddressConfirmation: false}, () => {
            document.getElementById("get-quote-form-container-submit").focus()
        })
    }

    async prepForSubmit(formData, formName) {
        formData[0].HasAcceptedDisclaimer = true;

        let one_address = Utils.makeBool(formData[0].HasLivedInCurrentAddressLessThan60Days);

        if (!one_address) {
            this.props.handleAction(formData, formName);
        } else {
            formData[0].PostalCode = formData[0].ZipCode;
            formData[0].ConstituentState = formData[0].State;
            formData[0].Apartment = formData[0].AptUnit;

            const verifiedAddress = await Utils.isValidGoogleMapsAddress(formData);
            if (verifiedAddress.isValid) {
                formData[0].StreetName = verifiedAddress.address.StreetName;
                this.props.handleAction(formData, formName);
            } else {
                formData[0].StreetName = formData[0].StreetAddress;
                const address = `${formData[0].StreetAddress} ${formData[0].City} ${formData[0].County} ${formData[0].State} ${formData[0].ZipCode}`;
                this.setState({
                    address: address,
                    showAddressConfirmation: true,
                    formData: formData
                });
            }
        }
    }

    buildPrivacyPolicy({LegalDisclosure}) {
        let {
            ...privacyLinks
        } = formInputs.HasAcceptedDisclaimer.privacyPolicies;

        // If the Nationwide privacy API call has resolved, parse out the privacy link from the response.
        if (LegalDisclosure) {
            let nationwidePrivacyLink = LegalDisclosure.Description.split("Link for Privacy Policy -")[1];
            nationwidePrivacyLink = nationwidePrivacyLink.replace(/<|>/g, '').trim();
            privacyLinks = {...privacyLinks, privacyLinkNationwide: nationwidePrivacyLink}
        }

        const links = formInputs.HasAcceptedDisclaimer.subLabel["1"].replace(
            /{{termsAndConditionsLink}}|{{privacyLinkIOA}}|{{privacyLinkProgressive}}|{{privacyLinkSafeCo}}|{{privacyLinkNationwide}}|{{privacyLinkTravellers}}/gi, (matched) => {
                return privacyLinks[matched.replace(/{{|}}/g, '')];
            })

        this.setState({
            privacyPolicy: {
                ...formInputs.HasAcceptedDisclaimer,
                subLabel: {
                    "1": links
                }
            },
            privacyText: links
        })
    }

    setShowPreviousAddress(updatedSelection) {
        this.setState({
            showPreviousAddress: updatedSelection === 'Yes'
        })
    }

    async changeAddress(data) {
        this.setState({
            mainAddress: data
        })
        const insuredAddress = {
            "Apartment": data.apartment ?? '',
            "City": data.locality,
            "County": data.administrative_area_level_2,
            "HouseNumber": data.route.split(" ")[0],
            "PostalCode": data.postal_code,
            "ZipCode": data.postal_code,
            "Street1": data.route,
            "Street2": "",
            "StreetAddress": data.route,
            "StreetName": data.route,
            "StateType": data.administrative_area_level_1,
            "ConstituentState": data.administrative_area_level_1,
            "State": data.administrative_area_level_1,
        }
        const apiData = {
            ApplicationId: this.props.appProcessorObj.ApplicationId,
            InsuredAddress: insuredAddress
        }
        await getPostAPI("api/submit-initial-address", apiData).then(() => {
            this.props.appObj.appObj.InsuredAddress = insuredAddress
        });
    }


    render() {
        const {data, appObj} = this.props;

        let i = 0;
        for (const element in formInputs) {
            this.elements[element + '-' + i] = createRef();
        }
        for (const element in addressFormInputs) {
            this.elements[element + '-' + i] = createRef();
        }
        this.elements.formName = 'DetailsStep';

        for (const element in formInputs) {
            formInputs[element].form = this.form;

            if (typeof data[this.elements.formName] !== "undefined"
                && typeof data[this.elements.formName][i] !== "undefined"
                && typeof data[this.elements.formName][i][element] !== "undefined"
            ) {
                formInputs[element].value = data[this.elements.formName][i][element];
            }
        }


        const params = propExists(this, "props.appProcessorObj.params") ? this.props.appProcessorObj.params : null;
        const formLoading = propExists(this, "props.appObj.state.formLoading") ? this.props.appObj.state.formLoading : false;

        return (
            <>
                <div id="get-quote-form-container" className={'get-quote-form'}>
                    <GoalConversion
                        goalId={VWO.APPLICANT_DETAILS_GOAL_ID}
                    />

                    <div id="form-body">

                        <div className="container">
                            <Header
                                header="Details"
                                headerId="get-quote-form-step-instruction-details"
                                subText={<>Why do we ask this information? To save you time and hassle we<br/> use this information to pull vehicle and driver details.</>}
                            />
                        </div>

                        <Form action={this.prepForSubmit.bind(this)}
                              submit={{
                                  "value": "Continue to Drivers",
                                  "fieldName": "get-quote-form-container-submit",
                                  "testHook": "th_YourDetails_Submit",
                                  "hideSeparator": true
                              }}
                              className="form"
                              inputs={this.elements}
                              {...this.props}
                              ref={this.form}
                              loading={formLoading}
                        >

                            <div className="container">

                                {this.props.appObj.state.enableEditAddress && (
                                    <div className='row'>
                                        <div className='col-12 col-md-10 col-lg-7 col-xl-6'>
                                            <AddressEdit
                                                handleChange={this.changeAddress.bind(this)}
                                                loading={formLoading}
                                                address={this.state.mainAddress}
                                            />

                                        </div>
                                    </div>
                                )}

                                <div>
                                    <div className='fullname'>

                                        <div className='formField'><TextField
                                            loading={formLoading}
                                            userData={params.FirstName} {...formInputs.FirstName}
                                            ref={this.elements['FirstName-' + i]}/></div>

                                        <div className='formField'><MaskedTextField
                                            loading={formLoading}
                                            inputOnlyClass={"limit-60"}
                                            userData={params.MiddleName} {...formInputs.MiddleName}
                                            ref={this.elements['MiddleName-' + i]}/></div>

                                        <div className='formField lastname'><TextField
                                            loading={formLoading}
                                            userData={params.LastName}  {...formInputs.LastName}
                                            ref={this.elements['LastName-' + i]}/></div>

                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 col-md-10 col-lg-8'>
                                        <div className='formField'>
                                            <SwitchField
                                                userData={'No'} {...formInputs.HasLivedInCurrentAddressLessThan60Days}
                                                ref={this.elements['HasLivedInCurrentAddressLessThan60Days-' + i]}
                                                changeCallBack={this.setShowPreviousAddress}/>
                                        </div>
                                    </div>
                                </div>

                                {this.state.showPreviousAddress && (
                                    <fieldset className="mt-3">
                                        <legend className="label">Previous Address</legend>
                                        <p className="label-sub">Please enter the previous house number and street name
                                            and select the full address</p>
                                        <IoaAddressLookup data={data} appObj={appObj} elements={this.elements}
                                                          greyBackground inset/>
                                    </fieldset>
                                )}

                                <div className='row'>
                                    <div className='col-12 col-md-10 col-lg-8'>
                                        <div className="divider d-md-block"/>
                                    </div>
                                </div>

                                <div className='row checkbox-container'>
                                    <div className='col-12 col-md-10 col-lg-8'>
                                        <div className='formField'>
                                            <p className='label-sub pt-2' dangerouslySetInnerHTML={{__html: this.state.privacyPolicy.subLabel[1]}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>

                    </div>
                </div>
                {this.state.showAddressConfirmation && (
                    <AddressConfirmationModal
                        address={this.state.address}
                        handleCancel={this.cancelAddressConfirmation.bind(this)}
                        handleSubmit={this.submitAddressConfirmation.bind(this)}
                    />
                )}

            </>
        );
    }
}


export default DetailsStep;
