import VWO from "@vendor/vwo";
import GoalConversion from "@vendor/goal-conversion"

import PictureContainer from "@steps/AddressStep/components/PictureContainer";
import LogoContainer from "@steps/AddressStep/components/LogoContainer";
import HelpBanner from "@steps/AddressStep/components/HelpBanner";
import PageTitle from "@steps/AddressStep/components/PageTitle";
import AddressFormContainer from "@steps/AddressStep/components/AddressFormContainer";


const AddressStep = ({handleAction, initialAddress}) => {

    return (
        <div className="grid">

            <GoalConversion
                goalId={VWO.APPLICANT_DETAILS_GOAL_ID}
            />

            <PictureContainer/>

            <div className="form">
                <div className="form-container">
                    <div className="form-wrapper">

                        <HelpBanner/>

                        <PageTitle/>

                        <AddressFormContainer
                            handleAction={handleAction}
                            initialAddress={initialAddress}
                            button={"Start your quote"}
                        />

                    </div>
                </div>
            </div>

            <LogoContainer/>

        </div>
    );

}

export default AddressStep;
