import { useEffect } from "react";
import InputMask from 'react-input-mask';


const MaterialInput = ({handleKeyup, id, type="text", mask=null, className, cy, submitted, value, error=false, placeholder, text, required=true}) => {

    useEffect(() => {
        if (value) {
            document.getElementById(id + "-label").classList.add("shrink")
        }
    }, [value, error]);


    const handleFocus = (e) => {
        const label = document.getElementById(e.target.id + "-label");
        if (label) {
            label.classList.add("shrink")
        }
    }


    const handleBlur = (e) => {
        if (e.target.value === "") {
            const label = document.getElementById(e.target.id + "-label");
            if (label) {
                if (!label.classList.contains("error")) {
                    label.classList.remove("shrink")
                }
            }
        }
    }

    let classes = `${className} relative`;
    if (submitted) {
        classes = error ? 'warning' : value ? 'selected' : 'relative';
    }


    return (
        <>
            <span className={classes}>
                <InputMask
                    id={id}
                    mask={mask}
                    name={id}
                    type={type}
                    value={value}
                    className={error ? "error" : ""}
                    placeholder={placeholder}
                    autoComplete={"off"}
                    data-cy={"th_" + cy}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleKeyup}
                />
                <label
                    id={id + "-label"}
                    htmlFor={id}
                    className={error ? "error shrink" : ""}
                >{ text } {required && <span className="required">*</span>}</label>
            </span>

            {error && (
                <div className="fieldErrors">
                    <p data-cy={`th_${cy}-error`} className="field_error">{typeof error === "boolean" ?  `${text} is required` : `${error}`}</p>
                </div>
            )}

        </>
    )

}

export default MaterialInput;