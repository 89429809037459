import {useState} from "react";
import {stateLookup} from "@utilities";
import useValidation from "@hooks/useValidation";
import FormContainer from "@steps/AddressStep/components/FormContainer";
import SVG from "@components/general/svg";
import * as validationRules from "@components/complete-payment/validation/address_form";
import {SHARED_ASSETS_PATH} from "@constants";

const addressObject = {
    Apartment: "",
    City: "",
    County: "",
    PostalCode: "",
    ConstituentState: "",
    Street1: ""
}

const createAddressObject = (address) => {
    if (!!address.locality && !!address.postal_code && !!address.administrative_area_level_1) {
        return {
            City: address.locality,
            PostalCode: address.postal_code,
            Street1: `${address.street_number} ${address.route}`,
            ConstituentState: address.administrative_area_level_1,
            County: address.administrative_area_level_2
        }
    }
    return addressObject;
}


const AddressFormContainer = ({handleAction, initialAddress, insuredAddress, button}) => {

    const [address, setAddress] = useState(createAddressObject(initialAddress));

    const [errors, setErrors] = useState(addressObject);

    const [submitted, setSubmitted] = useState(false);

    const [missingInfo, setMissingInfo] = useState(false)

    const [duplicate, setDuplicate] = useState(false);

    const [validate] = useValidation(validationRules);

    const handleKeyup = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const updatedAddress = Object.assign({}, address);
        updatedAddress[name] = value;
        if (name === "Street1") {
            const [houseNumber, ...streetName] = value.trim().split(/(?=[^0-9]+$)/);
            updatedAddress["HouseNumber"] = houseNumber;
            updatedAddress["StreetName"] = streetName.join("").trim();
        }
        if (submitted) {
            validateForm(updatedAddress)
        }
        setAddress(updatedAddress);
    }


    const handleApartment = (data) => {
        if (submitted) {
            validateForm({...address, Apartment: data})
        }
        setAddress({...address, Apartment: data})
    }


    const handleSelection = (data, clear = false) => {
        const updatedAddress = {
            HouseNumber: data.street_number,
            StreetView: data.StreetView,
            Apartment: Object.keys(data).length ? (data.subpremise ?? address.Apartment) : "",
            City: data.locality ?? data.political ?? "",
            County: data.administrative_area_level_2 ?? "",
            PostalCode: data.postal_code ?? "",
            ConstituentState: data.administrative_area_level_1 ? stateLookup('abv', data.administrative_area_level_1, "label") : "",
            Street1: (data.street_number ? data.street_number + " " : "") + (data.route ?? ""),
            StreetName: data.route ?? ""
        }

        if (clear) {
            clearErrors()
        }

        validateForm(updatedAddress, Object.keys(data).length === 0 ? 'clear' : 'selection')

        setAddress(updatedAddress);
    }


    const clearErrors = () => {
        setErrors(addressObject);
        setDuplicate(false);
    }


    const clearMissing = () => {
        setMissingInfo(false);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);

        if (validateForm(address, 'selection')) {
            const formattedAddress = Object.assign({}, address);
            formattedAddress.ConstituentState = stateLookup('label', address.ConstituentState, 'state')
            window.scrollTo({top: 0});
            handleAction(formattedAddress, "AddressStep")
        }

    }


    const validateForm = (data, type) => {
        const [errors, errorCount] = validate(data);

        let valid = true;
        const updatedErrors = Object.assign({}, addressObject);

        let isDuplicate = false;

        if (!errors.Street1) {

            const streetView = data?.StreetView;
            const addressName = data?.Street1.toString().replace(/\s/g, "").toUpperCase();
            const insuredAddressName = insuredAddress?.Street1.toString().replace(/\s/g, "").toUpperCase();

            if (streetView && insuredAddress?.StreetView === streetView) {
                isDuplicate = true;
                valid = false;
            }
            else if (addressName && insuredAddressName === addressName) {
                isDuplicate = true;
                errors.Street1 = "Street address must be different from your primary address";
                valid = false;
            }

            setDuplicate(isDuplicate);

        }


        if (errors.hasOwnProperty("Street1")) {
            updatedErrors["AddressSearch"] = true;
        }

        if (type === 'selection') {
            const missingAddressInfo = isDuplicate ? false : (errorCount > 0 && errorCount < 4) && Object.entries(errors).some(([key, value]) => {
                return key !== "Apartment" && value;
            })
            setMissingInfo(missingAddressInfo);
            setSubmitted(true);
        }

        setErrors(Object.assign({}, updatedErrors, errors));

        return errorCount > 0 ? false : valid;
    }


    return (

        <>

            {missingInfo && (
                <div className="banner error">
                    <SVG img={SHARED_ASSETS_PATH + 'svg/warning-circle.svg'}/>Some information was missing from your address
                </div>
            )}

            <FormContainer
                data={{
                    address,
                    errors,
                    missingInfo,
                    duplicate,
                    submitted,
                    button
                }}
                actions={{
                    handleKeyup,
                    handleSubmit,
                    handleSelection,
                    handleApartment,
                    clearErrors,
                    clearMissing
                }}
            />
        </>

    );

}

export default AddressFormContainer;