import {spamMessage} from "@config/site"
import SVG from "@components/general/svg";
import {SHARED_ASSETS_PATH} from "@constants";


const FormSubmission = ({ handleSubmit, text }) => {

    return (
        <div className="d-flex flex-column align-items-md-end base-submit-holder mt-1">

            <button
                type="button"
                onClick={handleSubmit}
                data-cy={"th_YourDetails_Submit"}
            ><span className="ioa-pr-sm">{ text }</span><SVG img={SHARED_ASSETS_PATH + "svg/arrow-right-white.svg"} alt="right arrow icon" className="ioa-pr-xl"/></button>

            {spamMessage && (
                <div className={"text-md-right text-center"}>
                    <SVG src={'lock'} className="mr-1" alt="Secure padlock icon"/>
                    <span className="privacy-disclosure ioa-pl-sm">{ spamMessage }</span>
                </div>
            )}

        </div>
    );

}

export default FormSubmission;