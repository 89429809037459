import { useEffect, useState } from "react";

import SearchForm from "@steps/AddressStep/components/SearchForm";
import AddressForm from "@steps/AddressStep/components/AddressForm";
import FormSubmission from "@steps/AddressStep/components/FormSubmission";


const FormContainer = (props) => {

    const {
        actions: {
            handleSubmit,
            handleKeyup,
            handleSelection,
            clearErrors,
            clearMissing,
            handleApartment
        },
        data: {
            button,
            address,
            errors,
            missingInfo,
            duplicate,
            submitted
        }
    } = props;

    const searchFormError = Object.entries(errors).some(([key, value]) => {
        return key !== "Apartment" && value;

    });

    const apartmentFieldError = errors.hasOwnProperty("Apartment") && errors.Apartment ? errors.Apartment : false;

    const hasInitialAddress = address.ConstituentState && address.City && address.PostalCode;
    const [display, setDisplay] = useState(hasInitialAddress);


    useEffect(() => {
        if (missingInfo) {
            setDisplay(missingInfo);
            const firstErrorField = Object.keys(errors).find((i) => errors[i] === true);
            const errorField = document.getElementById(firstErrorField);
            if (errorField) {
                errorField.focus();
            }
        }
    }, [missingInfo])


    const showManualAddress = () => {
        setDisplay(true);
        handleSelection({});
        clearErrors();
    }


    const hideManualAddress = () => {
        setDisplay(false);
        handleSelection({});
        clearErrors();
        clearMissing();
    }


    return (

        <form autoComplete="off" className="material">

            {display ? (
                <AddressForm
                    address={address}
                    errors={errors}
                    submitted={submitted}
                    handleClick={hideManualAddress}
                    handleKeyup={handleKeyup}
                />
            ) : (
                <SearchForm
                    address={address}
                    submitted={submitted}
                    searchFormError={duplicate ? duplicate : searchFormError}
                    apartmentFieldError={apartmentFieldError}
                    duplicate={duplicate}
                    handleClick={showManualAddress}
                    handleSelection={handleSelection}
                    handleApartment={handleApartment}
                />
            )}

            <FormSubmission
                handleSubmit={handleSubmit}
                text={button}
            />

        </form>
    )

}

export default FormContainer