import {required} from "@hooks/useValidation/rules/required";


export const required_if = ({value, conditions, data}) => {

    const fieldValue = data[conditions.fieldName];

    if (fieldValue) {
        return fieldValue.trim() ? required(value) : true;
    }

    return true;

}