import { Component } from 'react';
import Utils from "@components/general/AVB_utils";
import {isUsaState, propExists, validateVin} from "@utilities";
import {logValidationError} from "@vendor/analytics";
import {addDays, addYears, differenceInYears, differenceInDays, startOfDay} from "date-fns";


class Validation extends Component {

    constructor(props) {
        super(props);
        this.userMessage = [];
        this.state = {
            value: props.value,
            userMessage: ''
        };
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.value !== props.value && props.value !== "") {
            return {
                value: props.value
            };
        }
        return null
    }

    setErrors(messages) {
        this.userMessage = messages;
    }

    updateValue(silent, value) {
        this.setState({value: value}, () => this.validate(silent));
    }

    validate(silent = false, value = this.state.value, siblingFormElements = undefined) {
        const {validation, related, values} = this.props;
        let errorCount = 0;
        let errorMessages = [];

        for (const validator in validation) {

            let validationObj = validation[validator];


            if (validationObj.type === 'startWithLetter') {
                let nameRegex = /^[a-z]/i
                let valid = value.trim().match(nameRegex);
                if (!valid) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }


            /**
             * @inner 'name'
             * @description Field must contain only letters or numbers
             */
            if (validationObj.type === 'name') {
                let nameRegex = /^[a-zA-Z\s.,\-']+$/i;
                let valid = (!value || false) ? true : (value.trim().match(nameRegex) || value.trim().length === 0);
                if (!valid) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }

            /**
             * @inner 'less_than_1000'
             */
            if (validationObj.type === "less_than_1000") {
                if (value) {
                    if (Number.isNaN(Number.parseFloat(value))) {
                        value = 0;
                    }
                    let isValid = value < 1000;
                    if (!isValid) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }

            /**
             * @inner 'commute_mileage'
             */
            if (validationObj.type === "commute_mileage") {
                if (value && siblingFormElements && siblingFormElements instanceof Array) {
                    let annualMileage = siblingFormElements.filter(function findAnnualMileage(element) {
                        return element.current.props.fieldName === "AnnualMileage";
                    })[0].current.state.value;

                    let isValid = value < (annualMileage / 2);
                    if (!isValid) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }

            /**
             * @inner 'round_number'
             */
            if (validationObj.type === "round_number") {
                if (value) {

                    let stringValue = value.toString();
                    let isValid = stringValue.match(/\d+/g)
                    if (!isValid || stringValue.match(/\.+/g)) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }

            /**
             * @inner 'license_required_if_state_not_usa'
             */
            if (validationObj.type === "license_required_if_state_not_usa") {
                let driverLicenseState = related.current.getData(true);

                if (isUsaState(driverLicenseState.value)) {
                    if (!value || value === '' || value === undefined || (typeof value === "string" && value.trim() === '')) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }

            /**
             * @inner 'zipcode'
             */
            if (validationObj.type === "zipcode") {
                let valid = value.trim().match(/(^\d{5}$)|(^\d{5}-\d{4}$)/) || value.trim().length === 0;
                if (!valid) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }

            /**
             * @inner 'lessThanTimeWithCarrier'
             */
            if (validationObj.type === "lessThanTimeWithCarrier") {
                const thatValue = Number(related.current.getData(true).value);
                const thisValue = Number(value) / 10 - 1;
                if (thatValue < thisValue) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }

            if (validationObj.type === "more_than_manufacture_year") {
                if (differenceInDays(new Date(value), new Date(related)) < 0) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }

            /**
             * @inner 'required'
             * @description Check for empty string on no value
             */
            if (validationObj.type === 'required') {
                if (!value || value === '' || value === undefined || (typeof value === "string" && value.trim() === '')) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }

            /**
             * @inner 'required_date'
             * @description Check for empty string on no value
             */
            if (validationObj.type === 'required_date') {
                if (!/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }


            /**
             * @inner 'required_if'
             * @description Check field value if related field has been entered
             */
            if (validationObj.type === "required_if") {

                let chk = related.current.getData(true).value;
                let chkHasValue = chk === undefined ? false : typeof chk === "string" ? chk.trim().length > 0 : Array.isArray(chk) ? chk.length > 0 : typeof chk === "object" ? Object.keys(chk).length > 0 : false;
                if (chkHasValue) {
                    let valid = value === undefined ? false : typeof value === "string" ? value.trim().length > 0 : Array.isArray(value) ? value.length > 0 : typeof value === "object" ? Object.keys(value).length > 0 : false;
                    if (!valid) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }


            /**
             * @inner 'check_uim'
             */
            if (validationObj.type === "check_uim") {
                if (value) {
                    const cleanValue = value.toString().trim().toLowerCase();
                    const lookupArray = this.props.lookup;
                    let chk = related.current.getData(true);
                    let restriction = lookupArray.find(restriction => restriction.Value === Number(chk.value));
                    if (restriction) {
                        let isValid = restriction.UninsuredMotoristOptions.find(restriction => restriction.Value === Number(cleanValue));
                        if (isValid === undefined) {
                            errorMessages.push(validationObj.message);
                            errorCount++;
                        }
                    }
                }
            }


            /**
             * @inner 'checkYear'
             */
            if (validationObj.type === "checkYear") {
                if (value) {
                    let chk = related.current.getData(true);
                    if (value === "0" && chk.value === "0") {
                        errorMessages.push(validationObj.message);
                        errorCount++;

                    }
                }
            }


            /**
             * @inner 'requiredNotZero'
             * @description Check for value or value is 0 int or '0' string
             */
            if (validationObj.type === 'requiredNotZero') {
                if (!value || value === '' || value <= 0 || value === '0' || value.length === 0) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }


            /**
             * @inner 'requiredNotZeroInArray'
             * @description Check if value of 0 int or '0' string is in the current value array - checkboxes | radio
             */
            if (validationObj.type === 'requiredNotZeroInArray') {
                if (!value || value === '' || value.includes(0) || value.includes("0") || value.length === 0) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }


            /**
             * @inner 'atLeastOne'
             * @description Check if at least one value is selected - checkboxes | radio
             */
            if (validationObj.type === 'atLeastOne') {
                if (typeof value === "undefined" || value === '' || value.length < 1 || values[value] === "undefined") {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }

            /**
             * @inner 'typeAheadValidOption'
             * @description Check if a valid typeahead option has been either selected or typed
             */
            if (validationObj.type === 'typeAheadValidOption') {
                if (typeof value === "undefined" || value === '' || value.length < 1 || values.filter(val => val.label === value).length === 0) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }


            /**
             * @inner 'length'
             * @description Check length of value against check
             */
            if (validationObj.type === 'length') {
                const ln = validationObj.check;
                if (value) {
                    if (value.length !== ln) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }

            /**
             * @inner 'expiry_date'
             * @description Check expiry date
             */
            if (validationObj.type === 'expiry_date') {
                if (value) {
                    let valid = true;

                    if (value.trim().replace(/[^a-z0-9]/gi, "").length !== 4) {
                        valid = false;
                    }

                    const ln = validationObj.check;
                    const thisDate = new Date();
                    const thisYear = thisDate.getFullYear() % 100;
                    const thisMonth = Number(thisDate.getMonth() + 1);

                    const date = value.split("/");
                    const m = Number(date[0]);
                    const y = Number(date[1]);

                    const limit = y - (m > thisMonth ? ln - 1 : ln);

                    if ((m < 1 || m > 12) || (y < thisYear) || limit > thisYear || (y === thisYear && m < thisMonth)) {
                        valid = false;
                    }

                    if (!valid) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }


            /**
             * @inner 'number'
             * @description Check if the value is numbers only - string test
             */
            if (validationObj.type === 'number') {
                if (isNaN(Number(value))) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }

            /**
             * @inner 'email'
             * @description Check if the value is a valid email - string test
             */
            if (validationObj.type === "email") {
                if (value && value !== '') {
                    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (!re.test(value.toLowerCase())) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }


            /**
             * @inner 'credit_card'
             * @description Check if the value is a valid credit card number - string test
             */
            if (validationObj.type === "credit_card") {
                if (value && value !== '') {
                    if (!Utils.valid_credit_card(value)) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }

            if (validationObj.type === "cvv") {
                if (value && value !== '') {
                    if (!Utils.valid_cvv(value)) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }

            /**
             * @inner 'credit_card_type'
             * @description Check if the value is a valid credit card type - string test
             */
            if (validationObj.type === "credit_card_type") {
                if (value && value !== '') {
                    const filter = validationObj.filter ? validationObj.filter : [];
                    if (Utils.GetCardType(value, filter) === "") {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }


            /**
             * @inner 'contains_key'
             * @description Check if the value is a key in the object
             */
            if (validationObj.type === "contains_key") {
                if (value) {
                    const cleanValue = value.toString().trim().toLowerCase();
                    const lookupArray = Object.keys(this.props.lookup);

                    if (cleanValue && lookupArray.length > 0) {
                        if (!lookupArray.includes(cleanValue)) {
                            errorMessages.push(validationObj.message);
                            errorCount++;
                        }
                    }
                }
            }


            /**
             * @inner 'multi_word'
             * @description Check if the value has two or more words - string test
             */
            if (validationObj.type === "multi_word") {

                const cleanValue = value ? value.trim() : "";
                const matches = cleanValue.match(/\b[^\d\s]+\b/g);

                if (cleanValue && matches && matches.length < 2) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }


            /**
             * @inner 'house_number'
             * @description Check if the address object is populated, and it contains a house number
             */
            if (validationObj.type === "house_number") {
                if (value) {
                    if (!Utils.haveHouseNumber(value)) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }

            /**
             * @inner 'valid_address'
             * @description Check if the address object is populated and valid
             */
            if (validationObj.type === "valid_address") {
                if (value) {
                    if (!Utils.isValidAddress(value)) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }

            /**
             * @inner 'address_selected'
             * @description Check if the address object is populated
             */
            if (validationObj.type === "address_selected") {
                if (value && value !== '' && value.length > 5) {
                    if (!Utils.addressSelected(value)) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }


            /**
             * @inner 'same_as'
             * @description Check if the value of the field is the same as the value of the related field
             */
            if (validationObj.type === "same_as") {

                let chk = related.current.getData(true);
                if (chk.value) {
                    if (value) {
                        if (value.toLowerCase() !== chk.value.toLowerCase()) {
                            errorMessages.push(validationObj.message);
                            errorCount++;
                        }
                    }
                }
            }


            /**
             * @inner 'vin'
             * @description Check if the value @string is a valid US vin number
             */
            if (validationObj.type === "vin") {
                if (value && value !== '') {
                    if (!validateVin(value)) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }


            /**
             * @inner 'license_no'
             * @description Check if the value @string is a valid US license_no
             */
            if (validationObj.type === "license_no") {
                let state = related.current.getData(true);
                if (value && value !== '') {
                    let test = Utils.validateDriversLicense(state.value, value);
                    if (test.status === 0) {
                        errorMessages.push(validationObj.message + " " + test.message);
                        errorCount++;
                    }
                }
            }


            /**
             * @inner 'not_past_date'
             * @description Check if the date is in the past
             */
            if (validationObj.type === "not_past_date") {
                if (this.props.userData !== this.props.value) {
                    if (value) {
                        if (differenceInDays(startOfDay(new Date(value)), startOfDay(new Date())) < 0) {
                            errorMessages.push(validationObj.message);
                            errorCount++;
                        }
                    }
                }
            }


            /**
             * @inner 'date_within_30_days'
             * @description Check if the date is more than 30 days in the future
             */
            if (validationObj.type === "date_within_30_days") {
                if (this.props.userData !== this.props.value) {
                    if (value) {
                        if (differenceInDays(startOfDay(new Date(value)), startOfDay(addDays(new Date(), 30))) > 0) {
                            errorMessages.push(validationObj.message);
                            errorCount++;
                        }
                    }
                }
            }


            if (validationObj.type === "not_future_date") {
                if (this.props.userData !== this.props.value) {
                    if (value) {
                        if (differenceInDays(startOfDay(new Date(value)), startOfDay(new Date())) > 0) {
                            errorMessages.push(validationObj.message);
                            errorCount++;
                        }
                    }
                }
            }

            if (validationObj.type === "date_within_3_years") {
                if (this.props.userData !== this.props.value) {
                    if (value) {
                        if (differenceInDays(startOfDay(new Date(value)), startOfDay(addYears(new Date(), -3))) < 0) {
                            errorMessages.push(validationObj.message);
                            errorCount++;
                        }
                    }
                }
            }

            /**
             * @inner 'telephone'
             * @description Check if the value is a valid phone number - string test
             */
            if (validationObj.type === "telephone") {
                value = (value) ? value.replace(/\D+/g, '') : '';
                if (value || value === '') {
                    let re = /^[0-9]*$/gm;
                    if (!re.test(value) || value.length > 10 || value.length < 10) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }

            if (validationObj.type === "optional_telephone") {
                value = (value) ? value.replace(/\D+/g, '') : '';
                if (value && value !== "") {
                    let re = /^[0-9]*$/gm;
                    if (!re.test(value) || value.length > 10 || value.length < 10) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }

            /**
             * @inner 'pniMaritalStatus'
             * @description Check if a value is a valid PNI relation.
             * ex. Cannot have Spouse relation to PNI if PNIs marital status is Single
             */
            if (validationObj.type === "pniMaritalStatus") {
                if (this.props.form.current.formData.length > 0) {

                    let numOfSpouses = 0;
                    const drivers = this.props.form.current.formData;
                    for (let i = 1; i < drivers.length; i++) {
                        if (propExists(this, `props.form.current.props.inputs.RelationshipToPrimaryNamedInsured-${i}`)) {
                            const x = this.props.form.current.props.inputs["RelationshipToPrimaryNamedInsured-"+i].current?.getValue()
                            if ([20, "20"].includes(x)) {
                                numOfSpouses = numOfSpouses + 1;
                            }

                        }
                    }

                    if (this.props.index !== 0) {
                        const driverData = this.props.form.current.formData[this.props.index];
                        const pniData = this.props.form.current.formData[0];
                        const hasSpousalMaritalStatus = [30, 40, "30", "40"].includes(value);

                        const pniMaritalStatus = [30, 40, "30", "40"].includes(pniData.MaritalStatus);

                        // Relation to PNI is Spouse, but MS is not married or separated
                        if ([20, "20"].includes(driverData.RelationshipToPrimaryNamedInsured) && !hasSpousalMaritalStatus) {
                            errorMessages.push("Driver must be married or separated to have a spouse relationship to the Policyholder");
                            errorCount++;
                        } else if ([20, "20"].includes(driverData.RelationshipToPrimaryNamedInsured) && !pniMaritalStatus) {
                            errorMessages.push("Policyholder must either be married or separated to have a spouse");
                            errorCount++;
                        } else {
                            if (numOfSpouses > 1 && [20, "20"].includes(driverData.RelationshipToPrimaryNamedInsured)) {
                                errorMessages.push("Policyholder may only have one spouse");
                                errorCount++;
                            }
                        }
                    } else {
                        const pniMaritalStatus = [30, 40, "30", "40"].includes(drivers[0].MaritalStatus);

                        if (this.props.form.current.state.submitted) {

                            for (let i = 1; i < drivers.length; i++) {
                                if (propExists(this, `props.form.current.props.inputs.MaritalStatus-${i}`)) {
                                    const currentMaritalStatus = drivers[i].MaritalStatus;
                                    const hasSpousalMaritalStatus = [30, 40, "30", "40"].includes(currentMaritalStatus);

                                    if ([20, "20"].includes(drivers[i].RelationshipToPrimaryNamedInsured) && !hasSpousalMaritalStatus) {
                                        this.props.form.current.props.inputs['MaritalStatus-' + i].current.handleError(true, ["Driver must be married or separated to have a spouse relationship to the Policyholder"]);
                                    } else if ([20, "20"].includes(drivers[i].RelationshipToPrimaryNamedInsured) && !pniMaritalStatus) {
                                        this.props.form.current.props.inputs['MaritalStatus-' + i].current.handleError(true, ["Policyholder must either be married or separated to have a spouse"]);
                                    } else {

                                        if (numOfSpouses > 1 && [20, "20"].includes(drivers[i].RelationshipToPrimaryNamedInsured)) {
                                            this.props.form.current.props.inputs['MaritalStatus-' + i].current.handleError(true, ["Policyholder may only have one spouse"]);
                                        } else {
                                            this.props.form.current.props.inputs['MaritalStatus-' + i]?.current.handleError(false);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }

            /**
             * @inner 'singleSpouse'
             * @description Check that the PNI only has one spouse
             */
            if (validationObj.type === "singleSpouse") {
                const spouseCount = Object.keys(this.props.form.current.props.inputs).reduce((inputs, current) => {
                    if (current.includes('RelationshipToPrimaryNamedInsured')) {
                        const value = this.props.form.current.props.inputs[current].current?.state.value;
                        if (value === "20") {
                            inputs.push(value);
                        }
                    }

                    return inputs;
                }, [])

                if (spouseCount.length > 1 && value === "20") {
                    errorMessages.push("Policyholder may only have one spouse");
                    errorCount++;
                }

            }

            /**
             * @inner 'vin_exists'
             * @description Check if the vin value has already been added
             */
            if (validationObj.type === "vin_exists") {
                value = (value) ? value.replace(/\W+/g, '') : '';
                if (value) {
                    let currentVehicles = this.props.appProcessorObj.appTransformedData.Vehicles;
                    let matches = currentVehicles.filter((result) => {
                        return result.VehicleIdentificationNumber?.toLowerCase() === value.toLowerCase();
                    });
                    if (matches.length > 0) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }

            /**
             * @inner 'over_age'
             * @description Check if the year value is less than 150
             */
            if (validationObj.type === "over_age") {

                if (value || value === '') {
                    if (differenceInYears(startOfDay(new Date(value)), startOfDay(addYears(new Date(), -150))) < 0) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }

            /**
             * @inner 'under_age'
             * @description Check if the year value is less than 15
             */
            if (validationObj.type === "under_age") {
                if (value || value === '') {
                    if (differenceInYears(startOfDay(new Date()), new Date(value)) < 15) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }
            
            if (validationObj.type === "unique") {
                let cellphoneNumbers = [];

                let drivers = 0;
                if (this.props.form.current.props.drivers) {
                    drivers = this.props.form.current.props.drivers.length
                } else if (this.props.form.current.props.appProcessorObj?.appTransformedData?.Drivers) {
                    drivers = this.props.form.current.props.appProcessorObj.appTransformedData.Drivers
                        .filter(x => x.RatingType === "Rated").length;
                }

                for (let i = 0; i < drivers; i++) {
                    const field = this.props.form?.current?.props.inputs['CellPhoneNumber-' + i];
                    const cell = field.current.getValue();
                    cellphoneNumbers.push(cell);
                }
                const isDuplicate = cellphoneNumbers.some(function (item, idx) {
                    return cellphoneNumbers.indexOf(item) !== idx
                });

                if (isDuplicate) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                } 
            }


            /**
             * @inner 'valid_date'
             * @description Check date is valid
             */
            if (validationObj.type === "valid_date") {
                if (value || value === '') {

                    let dateParts = value.split("/");

                    let year = parseInt(dateParts[2]);
                    let month = parseInt(dateParts[0]);
                    let day = parseInt(dateParts[1]);

                    let isInvalidDate = day === null || month === null || year === null || day > 31;
                    let isInvalidFor30daysMonth = ([4, 6, 9, 11].includes(month) && day > 30);
                    let isInvalidFebruary =  (year % 4 !== 0 && month === 2 && day > 28) || (year % 4 === 0 && month === 2 && day > 29);
                    let isDateOutsideBoundaries = year < new Date().getFullYear() - 100 || year > new Date().getFullYear() + 20;

                    if (isInvalidDate || isInvalidFor30daysMonth || isInvalidFebruary || isDateOutsideBoundaries) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }

            /**
             * @inner 'address_required_with_street'
             * @description Check for empty string on no value
             */
            if (validationObj.type === 'address_required_with_street') {
                let street = related.current.props.userData;
                if (street) {
                    if (!value || (typeof value === "string" && value.trim() === '')) {
                        errorMessages.push(validationObj.message);
                        errorCount++;
                    }
                }
            }

            /**
             * @inner 'address_lookup_no_results'
             * @description Check for a property set on the input when no Google Maps results are returned
             */
            if (validationObj.type === 'address_lookup_no_results') {
                if (this.props.noResults && !this.props.streetAddressInvalid) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }

            /**
             * @inner 'address_lookup_invalid'
             * @description Check for a property set on the input when the selected address is invalid
             */
            if (validationObj.type === 'address_lookup_invalid') {
                if (this.props.streetAddressInvalid) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }

            /**
             * @inner 'address_street_has_street_and_number'
             * @description check if the streetName field has a name and a number
             */
            if (validationObj.type === 'address_street_has_street_and_number') {
                const regex = /^(\d+)[a-zA-Z0-9 \-./]*( +)([a-zA-Z0-9 '\-,_./]){3,}$/;
                if (!value.match(regex)) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }

            if (validationObj.type === 'checkAgeFirstLicensed') {
                let age = related?.current?.getData(true).value;
                if (age) {
                    age = Utils.calculateAge(age).toString()
                } else {
                    age = this.props.age;
                }

                if (Number(value) > Number(age)) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }

            if (validationObj.type === 'alphanumeric') {
                if (value.trim() && !value.match(/^[a-z0-9]+$/i)) {
                    errorMessages.push(validationObj.message);
                    errorCount++;
                }
            }

        }

        if (!silent) {

            for (const error of errorMessages) {
                logValidationError(this.props.fieldName, error);
            }

            this.props.onHasError((errorCount > 0), errorMessages);
        }

        return errorCount;

    }

    render() {

        let jsError = [];
        let i = 0;

        if (Array.isArray(this.userMessage)) {
            if (this.userMessage.length > 0) {
                for (const message in this.userMessage) {
                    jsError.push(<div data-cy={`${this.props.testHook}-error`} className={`field_error ${this.props.className}`} key={i}>{this.userMessage[message]}</div>);
                    i++;
                }
            }
        } else {
            if (this.userMessage) {
                jsError.push(<div data-cy={`${this.props.testHook}-error`} className={`field_error ${this.props.className}`} key={i}>{this.userMessage}</div>);
            }
        }

        return (
            <div>{jsError}</div>
        );
    }
}

export default Validation;