import { createRef, Component } from 'react';
import NoteElement from "@forms/note";
import LabelElement from "@forms/label";
import Validation from "@forms/validation";
import SVG from "@components/general/svg";
import TitleHover from "@components/general/title-hover";


class TextField extends Component {

    constructor(props) {
        super(props);

        this.userMessages = [];
        this.validator = createRef();

        let startingClass = "";
        if (props.hasOwnProperty("incomplete")) {
            startingClass = props.incomplete ? "field_incomplete" : "field_ok";
        }

        this.state = {
            isEditable: false,
            value: props.userData ?? '',
            validation: '',
            related_element: false,
            input_class: [startingClass],
            hasError: true,
            isDirty: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.userData !== prevProps.userData) {
            this.setState({
                value: this.props.userData ?? '',
            }, () => this.isValid(false, this.state.value));
        }
    }

    updateValue(silent, value) {
        const {related, changeCallBack} = this.props;

        this.validator.current.updateValue(silent, value);

        if (typeof related !== "undefined" && related.current) {
            related.current.updateRelatedValue(value);
        }

        this.setState({
            value: value
        }, () => {
            if (typeof changeCallBack !== "undefined") {
                changeCallBack(value);
            }
        });
    }


    updateRelatedValue = (relatedValue) => {
        const {relatedValues} = this.props;
        if (relatedValues) {
            if (relatedValues.indexOf(relatedValue) > -1) {
                this.handleInput(1);
            } else {
                this.handleInput(null, true);
            }
        }
    };


    handleInput = (e) => {
        const {changeCallBack} = this.props;
        const isSubmitted = this.props.form ? this.props.form.current.state.submitted : false;
        const val = e.target.value;

        this.markDirty();

        if (this.state.input_class.indexOf('field_incomplete') !== -1) {
            const classes = this.state.input_class;
            if (val !== "") {
                classes.push("field_ok");
            } else {
                const i = this.state.input_class.indexOf('field_ok');
                if (i !== -1) {
                    classes.splice(i,1)
                }
            }
            this.setState({
                input_class: classes
            })
        }

        this.setState({value: val}, () => {
            if (typeof changeCallBack !== "undefined") {
                changeCallBack(val);
            }
        });

        if (isSubmitted || this.props.userData) {
            this.validator.current.updateValue(false, e.target.value);
        }


    };


    isValid = (silent, filteredInputs) => {
        return this.validator.current.validate(silent, this.state.value, filteredInputs);
    };


    getData = (silent, filteredInputs) => {
        let errors = this.isValid(silent, filteredInputs);
        if (errors) {
            return false;
        } else {
            const {fieldName} = this.props;
            const {value} = this.state;
            return {
                name: fieldName,
                value: value
            };
        }
    };


    getValue() {
        const {value} = this.props;
        return value;
    };


    storeErrors(errorMessage) {
        this.userMessages = errorMessage;
        this.validator.current.setErrors(this.userMessages);
    }


    clearErrors() {
        this.userMessages = [];
        this.validator.current.setErrors(this.userMessages);
    }


    handleError = (hasError, messages) => {

        let i = this.state.input_class.indexOf('field_error');
        let add = (hasError && i < 0) ? 'field_error' : false;
        let remove = (!hasError && i > -1) ? 1 : 0;
        let start = (i > -1) ? i : 0;
        let newVal = this.state.input_class;

        if (!hasError && this.state.input_class.indexOf('field_ok') < 0) {
            add = 'field_ok';
        }

        if (hasError && !remove) {
            i = this.state.input_class.indexOf('field_ok');
            remove = (hasError && i > -1) ? 1 : 0;
            start = (i > -1) ? i : 0;
        }

        if (add) {
            newVal.splice(start, remove, add);
        } else {
            newVal.splice(start, remove);
        }

        if (hasError) {
            this.storeErrors(messages.splice(0, 1));
        } else {
            this.clearErrors();
        }

        this.setState({input_class: newVal, hasError: hasError});
    };


    buildField() {

        const {
            label,
            ariaLabel,
            isEditable,
            placeHolder,
            note,
            fieldName,
            id,
            validation,
            holder_class,
            related,
            fieldType,
            subLabel,
            inputClasses,
            inputOnlyClass,
            userData,
            index,
            testHook,
            disableOnLoad,
            isClearable
        } = this.props;
        const {value, input_class} = this.state;

        if (typeof inputClasses !== "undefined" && input_class.indexOf(inputClasses) < 0) {
            input_class.push(inputClasses);
        }

        let currentVal = value;

        let fieldID = (typeof id === "undefined") ? fieldName : id;
        let textJsx = [];
        let errorJsx = [];
        let i = 0;

        if (label) {
            let labelText = label;
            if (ariaLabel) {
                labelText = `<span aria-label=${ariaLabel}>${label}</span>`;
            }
            textJsx.push(
                <div key={i}>
                    <LabelElement
                        value={labelText}
                        optional={this.props.optional}
                        htmlFor={fieldID}
                        className={input_class.join(' ')}
                        required={this.props.required}
                    />
                    {subLabel && <span className={'label-sub'}>{subLabel}</span>}
                </div>
            );
            i++;
        }

        if ((typeof isEditable !== "undefined") && !isEditable) {
            input_class.push('disabled');
        } else {
            let start = input_class.indexOf('disabled');
            if (start > -1) {
                input_class.splice(start, 1);
            }
        }

        let inputFieldType = (typeof fieldType === "undefined") ? 'text' : fieldType;

        let indexValue = (typeof index === "undefined") ? "" : "_Item-" + index;
        let testHookWithItemIndex = testHook + indexValue;

        let inputJsx = [];
        let element = <input
            type={inputFieldType}
            placeholder={placeHolder??""}
            value={currentVal}
            name={fieldName}
            id={fieldID}
            ref={this.props.inputRef ?? null}
            key={i}
            data-incomplete={(this.props.incomplete && !currentVal) ? "inc" + index : ""}
            min={this.props.min}
            disabled={(typeof isEditable !== "undefined" && !isEditable) || (isClearable && disableOnLoad && value === userData)}
            className={input_class.join(' ') + " " + inputOnlyClass}
            onFocus={this.props.handleInput}
            onBlur={this.props.handleInput ? this.props.handleInput : this.handleInput}
            onChange={this.props.handleInput ? this.props.handleInput : this.handleInput}
            data-cy={testHookWithItemIndex}
            readOnly={this.props.readOnly ? this.props.readOnly : false}
            onKeyUp={this.props.onKeyUp ?? null}
            autoComplete={this.props.autoComplete ? this.props.autoComplete : 'on'}
        />;
        inputJsx.push(element);

        {
            (this.props.isClearable && value.length > 0) && (
                inputJsx.push(
                    <TitleHover title={this.props.clearText}><button
                        type="button"
                        className="type-ahead-clear-button"
                        onClick={this.clearField.bind(this)}
                    ><SVG src={"close"} alt={"clear field"} aria-hidden="true"/><span className="sr-only">close</span></button></TitleHover>
                ))
        }
        i++;

        errorJsx.push(<Validation
            value={currentVal}
            validation={validation}
            related={related}
            key={i}
            className={input_class.join(' ')}
            onHasError={this.handleError}
            ref={this.validator}
            testHook={testHookWithItemIndex}
            {...this.props}
        />);
        i++;

        if (note) {
            textJsx.push(<NoteElement value={note} key={i}/>);
            i++;
        }

        const containerClass = `text-container ${holder_class}`;
        return (
            <div className={containerClass}>
                {textJsx}
                <div className={"position-relative " + input_class.join(' ')}>{inputJsx}</div>
                <div className="fieldErrors">
                    {errorJsx}
                </div>
            </div>
        );
    }

    clearField() {
        this.setState({
            value: ''
        });
        this.handleInput({
            target: {
                value: ""
            }
        })
    }


    markDirty() {
        if (!this.state.isDirty) {
            this.setState({isDirty: true});
        }
    }

    render() {
        return this.buildField();
    }

}


export default TextField;