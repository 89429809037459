import { createRef, Component } from 'react';
import NoteElement from "@forms/note";
import LabelElement from "@forms/label";
import Validation from "@forms/validation";
import InputMask from 'react-input-mask';
import {propExists} from "@utilities"


class MaskedTextField extends Component {

    constructor(props) {
        super(props);

        this.userMessages = [];
        this.validator = createRef();

        this.state = {
            isEditable: false,
            value: props.userData,
            validation: '',
            related_element: false,
            input_class:[],
            hasError:true,
            isDirty:false,
            maskChar: props.placeholderChar
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.parentValue !== this.props.parentValue) {
            this.buildField();
        }
    }


    handleInput = (e) => {
        this.markDirty();
        let val = (e.target.value) ? e.target.value.replace(/\s/gi,'').replace(/_/gi,'') : e.target.value;

        if (this.props.validLengths) {
            if (this.props.validLengths.includes(val.length)) {
                this.setState({
                    maskChar: null
                })
            } else {
                this.setState({
                    maskChar: "_"
                })
            }
        }
        

        this.setState({value: val},()=> {
            const isSubmitted = propExists(this, "props.form.current.state.submitted") ? this.props.form.current.state.submitted : false;
            if (isSubmitted) {
                this.validator.current.updateValue(false, val);
            }
        });
    };


    isValid = (silent) => {
        return this.validator.current !== null ? this.validator.current.validate(silent) : '';
    };


    getData = (silent) => {
        let errors = this.isValid(silent);
        if(errors){
            return false;
        }else{
            const {fieldName} = this.props;
            const {value} = this.state;
            return {
                name: fieldName,
                value: value
            };
        }
    };

    getValue = () => {
        const {value} = this.state;
        return value ? value.toString() : "";
    };


    storeErrors(errorMessage){

        this.userMessages = errorMessage;
        this.validator.current.setErrors(this.userMessages);
    }



    clearErrors(){
        this.userMessages = [];
        this.validator.current.setErrors(this.userMessages);
        this.setState({
            input_class: []
        })
    }


    handleError = (hasError,messages) => {
        let i = this.state.input_class.indexOf('field_error');
        let add = (hasError && i < 0)?'field_error':false;
        let remove = (!hasError && i > -1)?1:0;
        let start = (i > -1)?i:0;
        let newVal = this.state.input_class;

        if (!hasError && this.state.input_class.indexOf('field_ok') < 0) {
            add = 'field_ok';
        }

        if (hasError && !remove) {
            i = this.state.input_class.indexOf('field_ok');
            remove = (hasError && i > -1)?1:0;
            start = (i > -1)?i:0;
        }

        if (add) {
            newVal.splice(start,remove,add);
        } else {
            newVal.splice(start,remove);
        }

        if (hasError) {
            this.storeErrors(messages.splice(0, 1));
        } else {
            this.clearErrors();
        }

        this.setState({input_class: newVal, hasError: hasError});
    };


    buildField() {
        const {
            label,
            isEditable,
            placeHolder,
            note,
            fieldName,
            id,
            validation,
            holder_class,
            related,
            mask,
            subLabel,
            inputClasses,
            inputType="text",
            inputOnlyClass,
            testHook
        } = this.props;

        const {
            value,
            input_class,
            maskChar
        } = this.state;


        if (isEditable) {
            input_class.push('disabled');
        } else {
            let start = input_class.indexOf('disabled');
            if (start > -1) {
                input_class.splice(start, 1);
            }
        }

        let fieldID = (typeof id === "undefined")?fieldName:id;
        let textJsx = [];
        let errorJsx = [];
        let i = 0;

        if (label) {
            textJsx.push(
                <div key={i}>
                    <LabelElement
                        value={label}
                        htmlFor={fieldName}
                        className={input_class.join(' ')}
                        required={this.props.required}
                        optional={this.props.optional}
                    />
                    {subLabel && <span className={'label-sub'} >{subLabel}</span>}
                </div>);
            i++;
        }

        let element = <InputMask
            type={inputType}
            placeholder={(placeHolder)?placeHolder:''}
            defaultValue={value}
            maskChar={maskChar}
            name={fieldName}
            id={fieldID}
            mask={mask}
            key={i}
            disabled={isEditable}
            className={inputClasses + " " + input_class.join(' ') + " " + inputOnlyClass}
            onChange={this.handleInput}
            readOnly={this.props.readOnly ? this.props.readOnly : false}
            data-cy={testHook}
        />;
        textJsx.push(element);
        i++;


        if (this.props.skip !== true) {
            errorJsx.push(<Validation
                value={value}
                validation={validation}
                related={related}
                key={i}
                className={inputClasses + " " + input_class.join(' ')}
                onHasError={this.handleError}
                ref={this.validator}
                testHook={testHook}
                {...this.props}
            />);
        }
        i++;

        if(note){
            textJsx.push(<NoteElement value={note} key={i}/>);
            i++;
        }


        return (
            <div className={holder_class}>
                { textJsx }
                <div className="fieldErrors">
                    { errorJsx }
                </div>
            </div>
        );
    }


    markDirty(){
        if(!this.state.isDirty) {
            this.setState({isDirty: true});
        }
    }


    render() {
        if (this.props.displayValue !== undefined && this.props.parentValue !== undefined) {
            if (this.props.displayValue === this.props.parentValue) {
                return this.buildField();
            } else {
                return '';
            }
        } else {
            return this.buildField();
        }
    }

}


export default MaskedTextField;
