import { createRef, Component } from 'react';
import TextField from "@forms/inputs/text";
import Utils from '@components/general/AVB_utils';

class GoogleMapsLookup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            query: props.userData ?? '',
        }
        this.autoCompleteRef = null;
        this.autoCompleteContainerRef = createRef();

        this.setTextareaRef = element => {
            if (element) {
                this.autoCompleteRef = element;
                this.autoComplete = new window.google.maps.places.Autocomplete(
                    element,
                    { types: ["address"], componentRestrictions: { country: "us" } }
                );
        
                // Below sets an event listener to disable autocomplete on the input, turning off
                // Chromes saved address autofill. Setting this directly on the inputs HTML causes this setting
                // to be overwritten.
                google.maps.event.addDomListener(element, 'focus', e => e.target.setAttribute('autocomplete', 'address-lookup'));
        
                this.autoComplete.setFields(["address_components", "formatted_address"]);
                this.autoComplete.addListener("place_changed", this.parseAddress.bind(this));
            }
        };
    }

    async parseAddress() {
        const addressObject = this.autoComplete.getPlace();
        const addressComponents = {};
        if ('address_components' in addressObject) {
            for (let i = 0; i < addressObject.address_components.length; i++) {
                const addressComponent = addressObject.address_components[i];
                let addressType = addressComponent.types[0];
                if (addressType === 'route') {// Use the long form street name (e.g Avenue instead of Ave)
                    addressComponents[addressType] = addressComponent.long_name;
                } else {
                    addressComponents[addressType] = addressComponent.short_name;
                }
            }
            const streetNumber = addressComponents['street_number'] ? addressComponents['street_number'] : '';
            const streetWasInvalid = this.props.formRef.current.state.hasError;
            if (this.autoCompleteRef.value.split(' ')[0].trim().replace("-","") !== streetNumber.trim().replace("-","")) {
                // Set the component state and pass the selected address back to the parent
                this.setState({
                    query: `${streetNumber}${addressComponents['route']}`,
                    streetAddressInvalid: true
                });
                this.props.onAddressSelected({
                    streetAddress: (streetNumber ? streetNumber + " " : "") + addressComponents['route'],
                    city: addressComponents['locality'] ?? addressComponents['neighborhood'],
                    county: addressComponents['administrative_area_level_2'],
                    state: Utils.getStateType(addressComponents['administrative_area_level_1']),
                    zipCode: addressComponents['postal_code'],
                    streetAddressInvalid: true
                })
            } else {
                // Set the component state and pass the selected address back to the parent
                this.setState({
                    query: `${streetNumber}${addressComponents['route']}`,
                    city: addressComponents['locality'] ?? addressComponents['neighborhood'],
                    county: addressComponents['administrative_area_level_2'],
                    state: Utils.getStateType(addressComponents['administrative_area_level_1']),
                    zipCode: addressComponents['postal_code'],
                    streetInvalid: false,
                    streetAddressInvalid: false
                }, () => {
                    if (streetWasInvalid) {
                        this.props.formRef.current.isValid()
                    }
                });
                this.props.onAddressSelected({
                    streetAddress: (streetNumber ? streetNumber + " " : "") + addressComponents['route'],
                    city: addressComponents['locality'] ?? addressComponents['neighborhood'],
                    county: addressComponents['administrative_area_level_2'],
                    state: Utils.getStateType(addressComponents['administrative_area_level_1']),
                    zipCode: addressComponents['postal_code'],
                })
            }
        }
    }

    isValid = (silent, filteredInputs) => {
        return this.validator.current.validate(silent, this.state.value, filteredInputs);
    };

    checkResults = (event) => {
        let autocompleteService = new google.maps.places.AutocompleteService();
        const inputValue = event.target.value;
        autocompleteService.getPlacePredictions(
                {
                    input: inputValue,
                    type: ["address"],
                    componentRestrictionstypes: { country: "us" }
                },
                (predictions, status) => {
                    if (inputValue.length > 5 && !predictions) {
                        this.setState({
                            streetInvalid: true
                        }, this.props.formRef.current.isValid)
                    }
                }
        );
    }

    render() {
        return (
            <>
                <TextField
                    ref={this.props.formRef}
                    inputRef={this.setTextareaRef}
                    userData={this.state.query}
                    changeCallBack={value => { this.setState({ query: value }); this.props.onManualChange(value)}}
                    placeHolder="Enter a City"
                    onKeyUp={this.checkResults}
                    noResults={this.state.streetInvalid}
                    streetAddressInvalid={this.state.streetAddressInvalid}
                    streetName={this.state.streetAddress}
                    {...this.props}
                />
            </>
        );
    }
}

export default GoogleMapsLookup;