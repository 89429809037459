import Utils from "@components/general/AVB_utils";
import {
    formatPhone,
    getFetchAPI,
    getPostAPI,
    getTokenFromCookie,
    mapGenderStr,
    propExists,
    stateLookup
} from "@utilities"
import fetch from "isomorphic-unfetch";
import * as signalR from "@microsoft/signalr";
import {COOKIE_AFFILIATE_APPLICATION_ID, warmTransferTypes} from "@constants";
import {
    browserName,
    deviceType,
    fullBrowserVersion,
    getUA,
    mobileModel,
    mobileVendor,
    osName,
    osVersion
} from 'react-device-detect';
import Cookies from "universal-cookie";
import { phone } from "@config/site";
import * as Sentry from "@sentry/react";


class AVB_IOA_app {

    constructor() {
        this.step = 0;
        this.appData = {};
        this.appTransformedData = {
            RemovedYoungDrivers: []
        };
        this.ApplicationId = '';
        this.WarmTransferMessage = '';
        this.StateType = '';
        this.InsuredAddress = {};
        this.API = process.env.NEXT_STATIC_RTU_API;
        this.applicationObj = null;
        this.ajaxLastCall = '';
        this.ajaxLastType = '';
        this.quoteSelection = '';
        this.showUmpd = null;
        this.showPip = null;
        this.pip = '';
        this.pipLabel = '';
        this.paymentMethodSelection = '';
        this.quoteOptions = '';
        this.manualDLN = [];
        this.RealTimeQuotes = [];
        this.Cover = [];
        this.ConsumerDataRef = '';
        this.timeOutPeriod = process.env.REQUEST_TIMEOUT_PERIOD;
        this.timeOutTimer = {};
        this.driverRef = null;
        this.driverDiscountRef = null;
        this.driverRefDiscountCallBack = null;
        this.carrierToken = null;
        this.isEditingQuote = false;
        this.initialQuotesReceived = false;
        this.params = {};
        this.paymentProviderType = 0;
        this.campaignPhoneNumber = phone;
    }

    DriverRelationMapping = {
        OTHER: 10,
        SPOUSE: 20,
        INSURED: 30,
        PARENT: 40,
        CHILD: 50
    }

    showWarmTransferScreen(error, warmTransferType = warmTransferTypes.UNKNOWN, warmTransferMessage = '', connection = null) {
        if (connection !== null) {
            connection.stop();
        }

        Sentry.captureException(error + "; correlationId: " + this.ApplicationId);

        this.applicationObj.setShowRealTimeQuotes(false); 
        this.applicationObj.cancelInterstitial();
        this.WarmTransferMessage = warmTransferMessage;
        this.applicationObj.showWarmTransfer(warmTransferType);
    }

    showQuoteScreen(connection, cover) {
        this.applicationObj.setShowRealTimeQuotes(false);
        if (connection !== null) {
            connection.stop();
        }

        for (const element in this.timeOutTimer) {
            clearTimeout(this.timeOutTimer[element]);
            delete this.timeOutTimer[element];
        }
        const data = {
            Quotes: []
        }
        const assignedValues = Object.assign({}, cover, data)
        this.applicationObj.setAPIData(assignedValues);
        this.applicationObj.increaseStep(6);
        this.applicationObj.cancelInterstitial();
    }

    scrollToTop() {
        let top = (this.applicationObj.scrollTopRef.current) ? this.applicationObj.scrollTopRef.current.offsetTop : 0;
        window.scrollTo({top: top});
    }

    scrollToError() {
        window.scrollTo({behavior: 'smooth', top: this.applicationObj.scrollErrorRef.current.offsetTop});
    }

    async startApplication(siteCodes, dimensions) {

        let {app, code, consumerRef, applicationIdRef} = siteCodes;
        const {width, height} = dimensions;

        let consumerData = !!consumerRef ? await getFetchAPI("api/consumer-data?consumerDataId=" + consumerRef, 500) : false;

        const data = [];
        const contactForm = [];
        const quoteForm = [];
        let consumerAddressPresent = true;
        let vin = '';
        let street2 = '';
        let phone = '';
        let outcome = true;

        data['DetailsStep'] = [];
        data['ContactDetails'] = [];

        if (consumerData) {
            if (consumerData.IsSuccessful) {
                contactForm.Email = consumerData.Email ?? '';
                contactForm.EmailConfirm = consumerData.Email ?? '';
                contactForm.PrimaryPhoneNumber = consumerData.Phone ?? "";

                quoteForm.FirstName = consumerData.FirstName ?? '';
                quoteForm.MiddleName = consumerData.MiddleNameInitial ?? '';
                quoteForm.LastName = consumerData.LastName ?? '';

                vin = consumerData.Vin ?? "";
                phone = consumerData.Phone ?? "";
                code = consumerData.CampaignCode ? consumerData.CampaignCode : "";
                if (code) {
                    const cookies = new Cookies();
                    cookies.set(COOKIE_AFFILIATE_APPLICATION_ID, code, {
                        path: '/',
                        secure: process.env.NEXT_STATIC_ENV !== "local",
                        domain: process.env.NEXT_STATIC_ENV === "local" ? "localhost" : "simplyioa.com",
                        expires: ''
                    });
                }
                if ("Address" in consumerData) {
                    if (consumerData?.Address?.Street1 &&
                        consumerData?.Address?.StreetName &&
                        consumerData?.Address.City &&
                        consumerData?.Address.PostalCode) {
                        quoteForm.InsuredAddress = {
                            "street_number": consumerData.Address.HouseNumber ?? '',
                            "route": consumerData.Address.Street1 ?? '',
                            "street_name": consumerData.Address.StreetName ?? '',
                            "apartment": consumerData.Address.Apartment ?? '',
                            "locality": consumerData.Address.City ?? '',
                            "administrative_area_level_1": consumerData.Address.ConstituentState ?? '',
                            "administrative_area_level_2": consumerData.Address.County ?? '',
                            "postal_code": consumerData.Address.PostalCode ?? '',
                            "street_view": `${consumerData.Address.Apartment ? "Apt/Unit #" + consumerData.Address.Apartment + " " : ""}${consumerData.Address.Street1}, ${consumerData.Address.City}, ${stateLookup('state', consumerData.Address.ConstituentState, 'abv')} ${consumerData.Address.PostalCode}, USA`
                        }
                    }

                    if (consumerData?.Address?.ConstituentState) {
                        this.StateType = consumerData.Address.ConstituentState;
                    }
                } else {
                    consumerAddressPresent = false;
                }
                if (consumerData.ApplicationSourceDetails) {
                    quoteForm.ApplicationSourceDetails = {
                        "ApplicationSource": consumerData.ApplicationSourceDetails.ApplicationSource ?? '',
                        "ApplicationSourceId": consumerData.ApplicationSourceDetails.ApplicationSourceId ?? '',
                        "TenantId": consumerData.ApplicationSourceDetails.TenantId ?? ''
                    }
                }
            }
        } else if (code) {
            const campaignDetails = await getFetchAPI(`api/marketing/campaigns/campaign-details/${code}`);
            this.saveCampaignDetails(campaignDetails, false);
        }

        data['DetailsStep'].push(quoteForm);
        data['ContactDetails'].push(contactForm);

        let postData = Object.assign({}, ...data['DetailsStep'], ...data['ContactDetails']);
        postData.AddressLine1 = propExists(quoteForm, "InsuredAddress") ? postData.InsuredAddress.street_number + " " + postData.InsuredAddress.street_name : "";
        postData.AddressLine2 = propExists(quoteForm, "InsuredAddress") ? street2 : "";
        postData.City = propExists(quoteForm, "InsuredAddress") ? postData.InsuredAddress.locality : "";
        postData.State = propExists(quoteForm, "InsuredAddress") ? postData.InsuredAddress.administrative_area_level_1 : "";
        postData.County = propExists(quoteForm, "InsuredAddress") ? postData.InsuredAddress.administrative_area_level_2 : "";
        postData.ZipCode = propExists(quoteForm, "InsuredAddress") ? postData.InsuredAddress.postal_code : "";
        postData.EmailAddress = postData.Email;
        postData.EmailConfirm = postData.Email;
        postData.AffiliateApplicationId = app;
        postData.AffiliateCampaignCode = code;
        postData.PersonalAutoDeviceInformation = this.getDeviceInfo(height, width);
        postData.PersonalAutoVinVehicleLookup = {"Vin": vin};
        postData.ApplicationSourceDetails = propExists(quoteForm, "ApplicationSourceDetails") ? Object.assign({}, quoteForm.ApplicationSourceDetails) : "";
        delete postData.PrimaryPhoneNumber;
        delete postData.Email;

        postData = Object.entries(postData).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {})

        let request = {
            AffiliateApplicationId: postData.AffiliateApplicationId,
            AffiliateCampaignCode: postData.AffiliateCampaignCode,
            FirstName: postData.FirstName,
            LastName: postData.LastName,
            MiddleName: postData.MiddleName,
            AddressLine1: postData.AddressLine1,
            AddressLine2: postData.AddressLine2,
            City: postData.City,
            State: postData.State,
            County: postData.County,
            ZipCode: postData.ZipCode,
            EmailAddress: postData.EmailAddress,
            PersonalAutoDeviceInformation: postData.PersonalAutoDeviceInformation,
            PersonalAutoVinVehicleLookup: postData.PersonalAutoVinVehicleLookup,
            ApplicationSourceDetails: postData.ApplicationSourceDetails
        };
        if (phone) {
            request.PhoneNumber = phone;
        }

        let applicationDetails = await getPostAPI("api/start", request);

        if (consumerData) {
            this.InsuredAddress = {
                "Apartment": consumerData.Address?.Apartment ?? '',
                "City": consumerData.Address?.City ?? '',
                "County": consumerData.Address?.County ?? '',
                "HouseNumber": consumerData.Address?.Street1?.split(" ")[0],
                "PostalCode": consumerData.Address?.PostalCode ?? '',
                "Street1": consumerData.Address?.Street1 ?? '',
                "StreetAddress": consumerData.Address?.Street1,
                "StreetName": consumerData.Address?.StreetName ?? '',
                "ConstituentState": consumerData.Address?.ConstituentState ?? '',
            }
            this.ConsumerDataRef = consumerRef;

            const {ApplicationId: applicationId} = applicationDetails;
            const verifiedAddress = await Utils.isValidGoogleMapsAddress([this.InsuredAddress]);
            outcome = verifiedAddress.isValid;

            if (!outcome) {
                outcome = false;
                this.InsuredAddress = {}
            } else {
                const startData = {
                    ApplicationId: applicationId,
                    AffiliateCode: code,
                    InsuredAddress: verifiedAddress?.address
                }
                const campaignDetails = await getFetchAPI(`api/marketing/campaigns/campaign-details?applicationId=${applicationId}&state=${verifiedAddress.address.ConstituentState}`);
                this.saveCampaignDetails(campaignDetails, false);
                setTimeout(async () => getPostAPI("api/submit-initial-address", startData).then(async () => {
                }), 4000)
            }
        }

        if (applicationIdRef) {
            applicationDetails.ApplicationId = applicationIdRef;
        }

        this.ApplicationId = applicationDetails.ApplicationId;

        if (isNaN(postData)) {
            this.traceApplicationStarted(applicationDetails)
        } else {
            this.processStartApplications(applicationDetails)
        }

        postData.InsuredAddress = quoteForm.InsuredAddress;
        postData.PhoneNumber = formatPhone(phone);
        this.params = postData;
        return {
            outcome: outcome,
            consumerAddressPresent: consumerAddressPresent,
            googleMapsFound: outcome
        };
    }

    getDeviceInfo(height, width) {
        return {
            "UserAgent": getUA.replace(/[()/;]/g, ' '),
            "BrowserName": browserName,
            "BrowserVersion": fullBrowserVersion,
            "DeviceType": deviceType,
            "PixelWidth": width,
            "PixelHeight": height,
            "MobileVendor": mobileVendor,
            "MobileModel": mobileModel,
            "OperatingSystemName": osName,
            "OperatingSystemVersion": osVersion
        };
    }

    startQuoteProcess() {
        this.ajaxLastCall = null;
        this.ajaxLastType = null;

        const endpoint = `api/quotes/start?state=${this.StateType}&applicationId=${this.ApplicationId}`;
        let request = {
            Token: this.carrierToken ? this.carrierToken : ""
        };

        getPostAPI(endpoint, request).then(
            async (response) => {
                this.processAPIResponse(response, 'startQuoteProcess', undefined);
            });
    }

    processStartApplications(result) {
        this.applicationObj.setAPIData(result);
        this.applicationObj.cancelInterstitial();
    }

    traceApplicationStarted(result) {
        let {showingRetrieval} = this.applicationObj.state;

        if (showingRetrieval) {
            const queryString = window.location.search;
            const urlParams = new window.URLSearchParams(queryString);

            this.appTransformedData.QuoteId = urlParams.has('ref') ? urlParams.get('ref') : result.ApplicationId;
            this.traceUser(10);
        } else {
            this.appTransformedData.QuoteId = result.ApplicationId;
            this.traceUser(0);
        }
    }

    processSuccess(result) {
        this.applicationObj.setAPIData(result);
        this.applicationObj.increaseStep();
        this.applicationObj.cancelInterstitial();
    }

    setApplicationObj(applicationObj) {
        this.applicationObj = applicationObj;
    }

    storeUserData(data, formName) {
        this.appData[formName] = data;

        if (!this.applicationObj.state.showingAdditional) {
            this.applicationObj.showInterstitial();

            this.mapUserData();

            switch (formName) {
                case 'Payment':
                    this.postPaymentData(formName);
                    break;
                case 'QuoteEditForm':
                    this.postEditQuoteData(formName);
                    break;
                case 'AddressStep':
                    this.postAddressData(formName);
                    break;
                case 'DetailsStep':
                    this.postInitialApplicantData(formName);
                    break;
                case 'DriversForm':
                    this.postDriverDetailsData(formName);
                    break;
                case 'VehiclesForm':
                    this.postVehicleDetailsData(formName);
                    break;
                case 'Quotes':
                    this.submitChosenCarrier(formName);
                    break;
                case 'PreviousCarrier':
                    this.postPreviousCarrierData(formName);
                    break;
                case 'ContactDetails':
                    this.submitContactDetails(formName);
                    break;
                default:
                    this.postUserData(formName);
            }
        } else {
            switch (formName) {
                case 'AdditionalVehicle':
                    this.getVehiclesByVin();
                    break;


                case 'AdditionalDriver':
                    this.addDriver();
                    break;
            }
        }
    }

    traceUser(step) {
        const endPoint = '/api/tracing/trace';
        let data = {
            applicationId: this.ApplicationId,
            step: step
        };
        this.ajaxLastCall = null;
        this.ajaxLastType = null;

        this.callAPI(this.API + endPoint, data, true, '', () => {
        }, true);
    }

    async jumpToQuotes(result) {
        this.ApplicationId = result.ApplicationId;
        this.StateType =  result.Applicant.InsuredAddress.StateType;

        this.appTransformedData.QuoteId = this.ApplicationId;
        this.appTransformedData.StateType = this.StateType;

        this.appTransformedData.HasAcceptedDisclaimer = result.HasAcceptedDisclaimer;
        this.appTransformedData.HasAcceptedMarketingCommunication = result.HasAcceptedMarketingCommunication;
        this.appTransformedData.DesiredPolicyEffectiveDate = result.DesiredPolicyEffectiveDate;
        this.appTransformedData.Applicant = result.Applicant;
        this.appTransformedData.Vehicles = result.Vehicles;
        this.appTransformedData.Drivers = result.Drivers;
        this.appTransformedData.Quotes = result.Quotes;
        this.appTransformedData.Limits = result.Limits ? result.Limits : [];
        this.appTransformedData.OptionalBodilyInjuryOptions = result.OptionalBodilyInjuryOptions ? result.OptionalBodilyInjuryOptions : [];
        this.appTransformedData.ComprehensiveRestrictions = result.ComprehensiveRestrictions ? result.ComprehensiveRestrictions : [];
        this.appTransformedData.OwnershipRestrictions = result.OwnershipRestrictions ? result.OwnershipRestrictions : [];
        this.appTransformedData.QuoteCoverDetails = result.QuoteCoverDetails ? result.QuoteCoverDetails : [];
        this.appTransformedData.MedicalPaymentsLiability = result.MedicalPaymentsLiability ? result.MedicalPaymentsLiability : [];
        this.appTransformedData.UninsuredMotoristBodilyInjuryLimits = result.UninsuredMotoristBodilyInjuryLimits ? result.UninsuredMotoristBodilyInjuryLimits : [];
        this.appTransformedData.AllowTelematics = result.AllowTelematics;

        const coverageValuesURL = "api/cover?state=" + this.StateType + "&applicationId=" + this.ApplicationId;
        const coverageValues = await getFetchAPI(coverageValuesURL);
        const assignedValues = Object.assign({}, coverageValues, this.appTransformedData);

        this.applicationObj.setAPIData(assignedValues);
        this.applicationObj.increaseStep(6);
        this.applicationObj.cancelRetrieval();

        if (result.Quotes === null) {
            this.showQuoteScreen(null, assignedValues);
            return;
        }
        this.applicationObj.cancelInterstitial();
    }

    mapUserData() {
        this.appTransformedData.Step = Utils.getStepEnum(this.applicationObj.state.currentStep);
        this.appTransformedData.QuoteId = this.ApplicationId;

        if (this.appTransformedData.Step === Utils.getStepEnum(0)) {
            this.prepDataFromAddressForm();
        }

        if (this.appTransformedData.Step === Utils.getStepEnum(1)) {
            this.prepDataFromGetQuoteForm();
        }

        if (this.appTransformedData.Step === Utils.getStepEnum(2)) {
            this.prepDataFromDriverForm();
        }

        if (this.appTransformedData.Step === Utils.getStepEnum(3)) {
            this.prepDataFromVehiclesForm();
        }

        if (this.appTransformedData.Step === Utils.getStepEnum(4)) {
            this.prepDataFromCarrierForm();
        }

        if (this.appTransformedData.Step === Utils.getStepEnum(5)) {
            this.prepDataFromContactForm();
        }

        if (this.appTransformedData.Step === Utils.getStepEnum(6)) {
            if (!this.isEditingQuote) {
                this.prepDataFromQuoteForm();
            }
        }

        if (this.appTransformedData.Step === Utils.getStepEnum(8)) {
            this.prepDataFromPaymentForm();
        }

    }

    postUserData(formName) {
        const endPoint = '/api';
        let data = this.appTransformedData;
        this.callAPI(this.API + endPoint, data, true, formName, undefined, false, warmTransferTypes.SAVE);
    }

    async postPaymentData(formName) {
        const endPoint = 'api/payment';
        let _this = this;

        let data = this.appTransformedData[formName];

        let request = {
            ApplicationId: data.QuoteId,
            SelectedCarrierId: data.SelectedCarrierId,
            PaymentPlan: data.PaymentPlan,
            ConstituentState: data.StateTypeId,
            LineOfBusinessTypeId: data.LineOfBusinessTypeId,
            IsPaymentAcknowledgementAccepted: data.IsPaymentAcknowledgementAccepted,
            PolicySourceLookup: data.PolicySourceLookup,
            PaymentSource: data.PaymentSource,
        }
        let connection = new signalR.HubConnectionBuilder()
            .withUrl(process.env.NEXT_STATIC_QUOTE_CALLBACK_URL,
            { 
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }  
            })
            .build();

        connection.start().then(function () {
            connection.invoke("CacheConnection", data.QuoteId).catch(function () {
                _this.applicationObj.showWarmTransfer()
            });

        }).catch(function () {
            _this.applicationObj.showWarmTransfer()
        });

        connection.on("ReceivePolicyPaymentResult", function (response) {
            connection.stop();
            if (!response.IsSuccessful) {
                _this.applicationObj.showWarmTransfer();
            } else {
               _this.processSuccess(response);
            }
        });
        let paymentResult = await getPostAPI(endPoint, request);
        if (!paymentResult || paymentResult.ShouldWarmTransfer) {
             _this.applicationObj.showWarmTransfer();
        }
    }

    postEditQuoteData(data, formName) {
        this.isEditingQuote = true;

        let request = {
            QuoteCoverRequirement: Utils.normalizeCoverageDetails(data.QuoteCoverRequirement),
            Token: this.carrierToken ?? ""
        };

        const endPoint = `api/cover?state=${this.StateType}&applicationId=${this.ApplicationId}`;

        getPostAPI(endPoint, request).then(
            async (response) => {
                this.processAPIResponse(response, formName, undefined);
            });
    }

    postAddressData(formName) {
        let data = {
            ApplicationId: this.appTransformedData.QuoteId,
            InsuredAddress: this.appTransformedData.InsuredAddress,
            AffiliateCode: this.params.AffiliateCampaignCode
        };

        if (data.InsuredAddress.StreetView)
            delete data.InsuredAddress.StreetView;

        if (!!this.ConsumerDataRef) {
            data['applicationSourceDetails'] = {
                applicationSource: 4,
                applicationSourceId: this.ConsumerDataRef
            };
        }

        getPostAPI("api/submit-initial-address", data).then(
            async (response) => {
                const campaignDetails = await getFetchAPI(`api/marketing/campaigns/campaign-details?applicationId=${data.ApplicationId}&state=${data.InsuredAddress.ConstituentState}`);
                this.saveCampaignDetails(campaignDetails, false);
                if (response.IsSuccessful) {
                    this.processAPIResponse(response, formName, () => this.saveCampaignDetails(campaignDetails, true));
                } else {
                    this.processAPIResponse(response, formName, undefined);
                }
            });
    }

    postInitialApplicantData(formName) {
        const endPoint = '/api/submit-initial-applicant';
        let data = {
            ApplicationId: this.ApplicationId,
            ConstituentState: this.StateType,
            FirstName: this.appTransformedData.Applicant.FirstName,
            MiddleName: this.appTransformedData.Applicant.MiddleName,
            LastName: this.appTransformedData.Applicant.LastName,
            WillBeInCurrentAddress30DaysAfterPolicyStart: this.appTransformedData.Applicant.WillBeInCurrentAddress30DaysAfterPolicyStart,
            HasAcceptedDisclaimer: this.appTransformedData.HasAcceptedDisclaimer,
            HasLivedInCurrentAddressLessThan60Days: this.appTransformedData.Applicant.HasLivedInCurrentAddressLessThan60Days,
            IsLookupAddressSameAsInsured: this.appTransformedData.Applicant.IsLookupAddressSameAsInsured,
            LookupAddress: this.appTransformedData.Applicant.LookupAddress,
            Metadata: {
                GoogleClickId: this.applicationObj.state.gclid
            }
        }

        this.callAPI(this.API + endPoint, data, true, formName, undefined, false);
    }

    postDriverDetailsData(formName) {
        const endPoint = '/api/submit-driver-details?applicationId=' + this.ApplicationId + '&state=' + this.StateType;
        const drivers = this.appTransformedData.Drivers.filter(driver => driver.hasOwnProperty("ReferenceId"));
        this.appTransformedData.Applicant = Object.assign(this.appTransformedData.Applicant, {
            FirstName: drivers[0].FirstName,
            MiddleName: drivers[0].MiddleName,
            LastName: drivers[0].LastName
        });

        drivers.forEach(driver => {
            delete driver.Open;
            delete driver.IsNew;
            delete driver.type;
            delete driver.showAge;
            delete driver.Policyholder;
        });

        let data = {
            Drivers: this.appTransformedData.Drivers.filter(driver => driver.hasOwnProperty("ReferenceId")),
            RemovedYoungDrivers: this.appTransformedData.RemovedYoungDrivers
        };

        this.appTransformedData.Drivers = this.appTransformedData.Drivers.filter(driver => driver.hasOwnProperty("ReferenceId"));

        data.Drivers = Utils.normalizeDriversData(data.Drivers);

        this.callAPI(this.API + endPoint, data, true, formName, undefined, false);
    }

    postVehicleDetailsData(formName) {
        const endPoint = '/api/submit-vehicle-details?applicationId=' + this.ApplicationId + '&state=' + this.StateType;
        let data = {
            Vehicles: this.appTransformedData.Vehicles.filter(vehicle => vehicle.hasOwnProperty("id")).map(vehicle => {
                if (vehicle.VehicleUsageType !== "15") {
                    vehicle.CommuteDaysPerWeek = null;
                    vehicle.CommuteMilesOneWay = null;
                }
                vehicle.VehicleOwnershipType = parseInt(vehicle.VehicleOwnershipType);
                vehicle.VehicleUsageType = parseInt(vehicle.VehicleUsageType);

                delete vehicle.IsNew;
                delete vehicle.Open;
                return vehicle;
            })
        }
        this.callAPI(this.API + endPoint, data, true, formName, undefined, false);
    }

    submitChosenCarrier(formName) {
        const endPoint = '/api/quotes/submit-chosen-carrier';
        let data = {
            ApplicationId: this.ApplicationId,
            ConstituentState: this.StateType,
            SelectedCarrierId: this.appTransformedData.SelectedCarrierId,
            IsGuidePrice: this.appTransformedData.IsGuidePrice
        }
        this.callAPI(this.API + endPoint, data, true, formName, undefined, false);
    }

    postPreviousCarrierData(formName) {
        const endPoint = '/api/current-coverage/submit-current-coverage';

        let data = {
            ApplicationId: this.ApplicationId,
            ConstituentState: this.StateType,
            CarrierName: this.appTransformedData.CurrentOrRecentCoverage.CarrierName,
            TimeWithCarrier: this.appTransformedData.CurrentOrRecentCoverage.TimeWithCarrier,
            LiabilityLimit: this.appTransformedData.CurrentOrRecentCoverage.LiabilityLimit,
            PolicyExpirationDate: this.appTransformedData.CurrentOrRecentCoverage.PolicyExpirationDate,
            ContinuousCoverage: this.appTransformedData.CurrentOrRecentCoverage.ContinuousCoverage,
            HadIneligibleCarrier: this.appTransformedData.CurrentOrRecentCoverage.HadIneligibleCarrier,
            DesiredPolicyEffectiveDate: this.appTransformedData.DesiredPolicyEffectiveDate,
        }
        this.callAPI(this.API + endPoint, data, true, formName, undefined, false);
    }

    submitContactDetails(formName) {
        const endPoint = '/api/contact-details/submit-contact-details';
        const secondaryNumber = propExists(this, "appTransformedData.Applicant.SecondaryPhoneNumber") ? this.appTransformedData.Applicant.SecondaryPhoneNumber : null;
        const containsNumbers = /\d/.test(secondaryNumber);

        let data = {
            ApplicationId: this.ApplicationId,
            ConstituentState: this.StateType,
            AllowTelematics: this.appTransformedData.AllowTelematics,
            PrimaryPhoneNumber: this.appTransformedData.Applicant.PrimaryPhoneNumber,
            SecondaryPhoneNumber: containsNumbers ? secondaryNumber : null,
            Email: this.appTransformedData.Applicant.Email,
            HasAcceptedMarketingCommunication: this.appTransformedData.HasAcceptedMarketingCommunication,
            HasAcceptedAffiliateRules: this.appTransformedData.HasAcceptedAffiliateRules ?? false
        }
        this.callAPI(this.API + endPoint, data, true, formName, undefined, false);
    }

    getVehicles() {
        const endPoint = '/api/vehicles';
        let data = {
            applicationId: this.ApplicationId,
            state: this.StateType
        };
        this.callAPI(this.API + endPoint, data, false, 'processVehicles', undefined, false, warmTransferTypes.GET_VEHICLE);
    }

    processVehicles(result) {
        this.appTransformedData.Vehicles = result.Vehicles;
        this.applicationObj.setAPIData(result);
        this.applicationObj.increaseStep();
        this.applicationObj.cancelInterstitial();
    }

    getDrivers() {
        const endPoint = '/api/drivers?applicationId=' + this.ApplicationId + '&state=' + this.StateType;
        this.callAPI(this.API + endPoint, undefined, true, 'processDrivers', undefined, false, warmTransferTypes.GET_HOUSEHOLD_DISCOVERY);
    }

    processDrivers(result) {
        result.Drivers = result.Drivers.map(item => {
            item.MiddleName = item.MiddleName ? item.MiddleName.charAt(0).toUpperCase() : "";
            return item;
        });

        this.appTransformedData.Drivers = result.Drivers;
        this.appTransformedData.Applicant.MiddleName = result.Drivers.length ? result.Drivers[0].MiddleName.charAt(0).toUpperCase() : this.appTransformedData.Applicant.MiddleName;
        this.applicationObj.setAPIData(result);
        this.updateFieldVariations(result);
    }

    getVehiclesByVin() {

        let vin = this.appData['AdditionalVehicle'][0].vin;
        const endpoint = `api/vehicle?vin=${vin}`;

        getFetchAPI(endpoint).then(
            async (response) => {

                this.ProcessVehiclesByVin(response);
            });
    }

    ProcessVehiclesByVin(result) {
        if (result.IsSuccessful) {
            this.applicationObj.AdditionalRef.setFound(result.Vehicle);
        } else {
            this.applicationObj.AdditionalRef.setFound(false);
        }

        this.ajaxLastCall = null;
        this.ajaxLastType = null;
    }

    addNewVehicleToCollection(Vehicle) {
        this.appTransformedData.Vehicles = this.appTransformedData.Vehicles.map(vehicle => {
            delete vehicle.IsNew;
            delete vehicle.Open;
            return vehicle;
        }).concat(Vehicle);

        this.appData['userRemovedVehicles'] = [];

        this.applicationObj.setAPIData(this.appTransformedData);
        this.applicationObj.resetVehicleMessages();
        this.applicationObj.cancelAdditional();
        this.applicationObj.cancelInterstitial();
        document.body.style.overflow = null;
    }

    processVehicleDetails() {
        this.ajaxLastCall = null;
        this.ajaxLastType = null;
        const endpoint = '/api/current-coverage';
        const data = {
            applicationId: this.ApplicationId,
            state: this.StateType
        };
        this.callAPI(this.API + endpoint, data, false, 'updateFieldVariations');

    }

    saveCampaignDetails(result, allowStepIncrease = true) {
        const {CampaignDetails: campaignDetails} = result;
        if (campaignDetails) {
            this.campaignPhoneNumber = campaignDetails.PhoneNumber;
            this.applicationObj.updateCampaignDetails(campaignDetails);
            this.appTransformedData.CampaignDetails = campaignDetails;
        }
        if (allowStepIncrease) {
            this.applicationObj.increaseStep();
            this.applicationObj.cancelInterstitial();
        }
    }

    async processContactResponse() {
        this.ajaxLastCall = null;
        this.ajaxLastType = null;

        const enrichMvrEndpoint = `api/enrichment/enrich-mvr?applicationId=${this.ApplicationId}&state=${this.StateType}`;
        const notifyUpdateContactEndpoint = `api/quotes/contact-details-updated?applicationId=${this.ApplicationId}&state=${this.StateType}`;

        getPostAPI(enrichMvrEndpoint)
            .then(async () => getPostAPI(notifyUpdateContactEndpoint))
            .then(async (response) => {
                this.processAPIResponse(response, 'quoteWait', undefined)
                })
    }

    processAffiliateInformation(result) {
        this.startQuoteProcess();
        this.applicationObj.setAPIData(result);
        this.applicationObj.increaseStep();
        this.applicationObj.cancelInterstitial();
    }

    updateFieldVariations(result) {
        this.applicationObj.setFieldVariations(result);
        this.applicationObj.increaseStep();
        this.applicationObj.cancelInterstitial();
    }

    processQuote() {
        this.processQuoteCountdownLatch = 5;
        this.getPaymentProviderType();
        this.getNationwideDisclaimerInfo();
        this.getElectronicDocumentOptions();
        this.getTelematicsOptions();
        this.getUninsuredMotoristOptions();
    }

    getPaymentOptions() {
        const endPoint = '/api/payment/options';
        let data = {
            applicationId: this.ApplicationId,
            state: this.StateType,
            lineOfBusinessType: 'PersonalAuto',
            carrierId: this.quoteSelection.CarrierId,
        };
        this.callAPI(this.API + endPoint, data, false, 'processPaymentOptions');
    }

    getTelematicsOptions() {
        const endPoint = '/api/carrier-telematics?carrierId=' + this.quoteSelection.CarrierId + '&state=' + this.StateType;
        this.callAPI(this.API + endPoint, {} , false, 'processTelematicsOptions');
    }

    getPaymentProviderType() {
        const data = {
            ApplicationId: this.ApplicationId,
            ConstituentState: this.StateType,
            LineOfBusinessTypeId: 10,
            CarrierId: this.quoteSelection.CarrierId
        };

        const endPoint = '/api/payment/request-details';
        this.callAPI(this.API + endPoint, data, true, 'loadPaymentDetails');
    }

    async getNationwideDisclaimerInfo() {
        const endPoint = `api/legal-disclosures?carrierId=99&disclosureIds=8,24,43,23,19,31,39,27,41,44`;
        await getFetchAPI(endPoint).then(
            async (response) => {
                this.processLoadNationwideDisclaimerDetails(response);
            });
    }

    processPaymentOptions(result) {
        this.applicationObj.setAPIData(result);
        this.applicationObj.setAdditionalPaymentData();
        this.applicationObj.increaseStep();
        this.applicationObj.cancelInterstitial();
    }

    processLoadPaymentDetails(result) {
        this.paymentProviderType = result.ProviderType;
        this.checkTelematicsAndElectronicDocumentOptionsHaveReturned();
    }

    processLoadNationwideDisclaimerDetails(result) {
        this.applicationObj.setNationwideDisclaimerData(result);
        this.checkTelematicsAndElectronicDocumentOptionsHaveReturned();
    }

    processTelematicsOptions(result) {
        this.applicationObj.setTelematicsData(result);
        this.checkTelematicsAndElectronicDocumentOptionsHaveReturned();
    }

    processElectronicDocumentOptions(result) {
        this.applicationObj.setElectronicDocumentsOptions(result);
        this.checkTelematicsAndElectronicDocumentOptionsHaveReturned();
    }

    processUmOptions(result) {
        this.applicationObj.setUmOptions(result);
        this.checkTelematicsAndElectronicDocumentOptionsHaveReturned();
    }

    getDriverLicense(firstName, middleInitial, lastName, dateOfBirth, gender, driverRef, dlState, manualDLN) {
        this.applicationObj.showInterstitial('Updating Driver Details');

        if (Utils.useDrivingLicenseNumberAutoGeneration(dlState)) {
            let data = {
                FirstName: firstName,
                MiddleInitial: middleInitial,
                LastName: lastName,
                DateOfBirth: dateOfBirth,
                Gender: gender,
                DriverLicenseState: stateLookup('state', dlState, 'enum')
            };

            this.driverRef = driverRef;
            this.ajaxLastCall = null;
            this.ajaxLastType = null;
            this.manualDLN = manualDLN;

            if (!manualDLN.find(dln => dln.id === driverRef && dln.value)) {

                getPostAPI("api/driver-licence-number", data).then(
                    async (response) => {

                        if (response.IsSuccessful) {
                            this.processDriverLicence(response);
                        }
                    });

            }
        }
    }

    processDriverLicence(result) {
        for (let x = 0; x < this.appTransformedData.Drivers.length; x++) {
            const referenceId = this.appTransformedData.Drivers[x].ReferenceId;
            if (typeof referenceId !== "undefined") {
                if (referenceId === this.driverRef) {
                    this.appTransformedData.Drivers[x].DriverLicenseNumber = result.DriverLicenceNumber;
                }
            }
        }
    }

    processAPIResponse(result, formName, callBack, raiseFrontEndErrorsAndTimeouts = true) {
        for (const element in this.timeOutTimer) {
            clearTimeout(this.timeOutTimer[element]);
            delete this.timeOutTimer[element];
        }

        if (result.ApplicationId && Utils.isValidApplicationId(result.ApplicationId)) {
            this.ApplicationId = result.ApplicationId;
        }

        if (process.env.NEXT_STATIC_MOCKERROR) {
            this.applicationObj.cancelInterstitial();
            this.applicationObj.showUserError('This is the error message.');
            return;
        }

        if (result.IsSuccessful) {

            if (result.ShouldWarmTransfer) {
                this.showWarmTransferScreen(
                    'Warm transfer encountered',
                    null,
                    result.WarmTransferMessage);
            } else {
                let callBackFunction = this.callbackPostAPICall(formName, callBack);

                if (typeof callBackFunction === "function") {
                    callBackFunction(result);
                }
            }
        } else {

            try {

                if (result.Errors) {

                    if (raiseFrontEndErrorsAndTimeouts) {
                        this.showWarmTransferScreen('Rest Exception encountered : ' + JSON.stringify(result.Errors));
                    }
                } else {
                    if (result.ShouldWarmTransfer) {
                        this.showWarmTransferScreen(
                            'Warm Transfer encountered',
                            null,
                            result.WarmTransferMessage);
                    } else {
                        if (raiseFrontEndErrorsAndTimeouts) {
                            this.showWarmTransferScreen('Unknown API response encountered : ' + JSON.stringify(result));
                        }
                    }
                }
            } catch (e) {
                if (raiseFrontEndErrorsAndTimeouts) {
                    this.showWarmTransferScreen('Unhandled exception encountered : ' + JSON.stringify(e));
                }
            }
        }
    }

    processAPIError(error, formName, raiseFrontEndErrorsAndTimeouts, warmTransferType = warmTransferTypes.OTHER) {

        let ignoreList = ['getVehicleImages'];

        if (ignoreList.indexOf(formName) === -1) {
            if (raiseFrontEndErrorsAndTimeouts) {
                this.showWarmTransferScreen('Unhandled error in form "' + formName + '".  Error details "' + error + '"', warmTransferType);
            }
        }
    }

    callAPI(apiUrl, data, post = false, formName, callBack, skipRaisingFrontEndErrorsAndTimeouts = false, warmTransferType = warmTransferTypes.OTHER) {

        let raiseFrontEndErrorsAndTimeouts = !(skipRaisingFrontEndErrorsAndTimeouts === true);

        if (raiseFrontEndErrorsAndTimeouts) {
            this.timeOutTimer[formName] = setTimeout(this.showWarmTransferScreen.bind(this, 'Call to ' + apiUrl + ' timed out.', warmTransferType.TIMEOUT), this.timeOutPeriod);
        }

        let method = (post) ? 'POST' : 'GET';

        var settings = {
            "async": true,
            "method": method
        };

        let url;
        let urlAddOn;

        url = apiUrl;
        urlAddOn = '?';
        settings.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        if (post) {
            settings.headers['X-AUTO-CSRF-TOKEN'] = getTokenFromCookie('AUTO-CSRF-TOKEN');
        }

        if (post) {
            settings.body = JSON.stringify(data);
        } else {
            if (Utils.queryParams(data) !== '') {
                url = url + urlAddOn + Utils.queryParams(data);
            }
        }

        let statusCode;

        if (this.isEditingQuote === true || this.ajaxLastCall !== url || this.ajaxLastType !== post) {

            fetch(url, settings)
                .then((res) => {
                    statusCode = res.status;
                    return res.json();
                })
                .then(
                    (result) => {
                        const updatedResult = {
                            ...result,
                            ...{
                                IsSuccessful: statusCode === 200
                            }
                        }
                        this.ajaxLastCall = url;
                        this.ajaxLastType = post;
                        this.processAPIResponse(updatedResult, formName, callBack, raiseFrontEndErrorsAndTimeouts);
                    },
                    (error) => {
                        this.ajaxLastCall = url;
                        this.ajaxLastType = post;
                        this.processAPIError(error, formName, raiseFrontEndErrorsAndTimeouts, warmTransferType.ERROR);
                    }
                );
        } else {
            for (const element in this.timeOutTimer) {
                clearTimeout(this.timeOutTimer[element]);
                delete this.timeOutTimer[element];
            }
            this.ajaxLastCall = null;
            this.ajaxLastType = null;
        }
    }

    callbackPostAPICall(formName, callBackFunction = false) {
        let callBack;

        if (callBackFunction === false) {
            switch (formName) {

                case 'StartForm':
                    callBack = this.processStartApplications.bind(this);
                    break;

                case 'StartFormSkipReload':
                    callBack = this.traceApplicationStarted.bind(this);
                    break;

                case 'DetailsStep':
                    callBack = this.getDrivers.bind(this);
                    break;

                case 'processDrivers':
                    callBack = this.processDrivers.bind(this);
                    break;

                case 'DriversForm':
                    callBack = this.getVehicles.bind(this);
                    break;

                case 'processVehicleDriver':
                    callBack = this.processVehicleDriver.bind(this);
                    break;

                case 'updateFieldVariations':
                    callBack = this.updateFieldVariations.bind(this);
                    break;

                case 'VehiclesForm':
                    callBack = this.processVehicleDetails.bind(this);
                    break;

                case 'AdditionalVehicle':
                    callBack = this.getVehiclesByVin.bind(this);
                    break;

                case 'AdditionalVehicleProcess':
                    callBack = this.ProcessVehiclesByVin.bind(this);
                    break;

                case 'processVehicles':
                    callBack = this.processVehicles.bind(this);
                    break;

                case 'PreviousCarrier':
                    callBack = this.processAffiliateInformation.bind(this);
                    break;

                case 'ContactDetails':
                    callBack = this.processContactResponse.bind(this);
                    break;

                case 'Quotes':
                    callBack = this.processQuote.bind(this);
                    break;

                case 'quoteWait':// processQuote will change - the loading screen will wait for a callabck. WIP.
                    callBack = this.quoteCallBack.bind(this);
                    break;

                case 'QuoteEditForm':// processQuote will change - the loading screen will wait for a callabck. WIP.
                    callBack = this.quoteCallBack.bind(this);
                    break;

                case 'processUmOptions':
                    callBack = this.processUmOptions.bind(this);
                    break;

                case 'processRequiresCarrierUpdate':
                    callBack = this.processRequiresCarrierUpdate.bind(this);
                    break;

                case 'loadPaymentDetails':
                    callBack = this.processLoadPaymentDetails.bind(this)
                    break;

                case 'processPaymentOptions':
                    callBack = this.processPaymentOptions.bind(this);
                    break;

                case 'processTelematicsOptions':
                    callBack = this.processTelematicsOptions.bind(this);
                    break;

                case 'Success':
                    callBack = this.processSuccess.bind(this);
                    break;

                case 'processDriverLicence':
                    callBack = this.processDriverLicence.bind(this);
                    break;


                case 'processDiscounts':
                    callBack = this.processDiscounts.bind(this);
                    break;

                case 'startQuoteProcess':
                    callBack = null;
                    break;

                case 'processElectronicDocumentOptions':
                    callBack = this.processElectronicDocumentOptions.bind(this);
                    break;

                default:
                    callBack = this.defaultPostAPIRequest.bind(this);
                    break;
            }
        } else {
            callBack = callBackFunction;
        }

        return callBack;
    }

    prepDataFromDriverForm() {
        let primaryDriver = this.appData['DriversForm'][0];
        let removedDrivers = this.appData['userRemovedDrivers'];
        let newDriversSet = [];
        let length = (this.appData['DriversForm'].length > this.appTransformedData.Drivers.length) ? this.appData['DriversForm'].length : this.appTransformedData.Drivers.length;

        for (let element = 0; element < length; element++) {

            if (typeof removedDrivers === "undefined" || !removedDrivers.includes(parseInt(element))) {
                let driver = {};

                if (typeof this.appTransformedData.Drivers !== "undefined" && typeof this.appTransformedData.Drivers[element] !== "undefined") {
                    driver = this.appTransformedData.Drivers.find(d => d.ReferenceId === this.appData['DriversForm'][element].ReferenceId)
                }

                driver.Industry = this.appData['DriversForm'][element].Industry;
                driver.Occupation = this.appData['DriversForm'][element].Occupation;
                driver.EducationType = this.appData['DriversForm'][element].EducationType;
                driver.PrimaryResidence = this.appData['DriversForm'][element].PrimaryResidence;
                driver.MaritalStatus = this.appData['DriversForm'][element].MaritalStatus;
                driver.DriverLicenseType = this.appData['DriversForm'][element].DriverLicenseType;
                driver.DriverLicenseNumber = [30, "30"].includes(driver.DriverLicenseType) ? null : this.appData['DriversForm'][element].DriverLicenseNumber;
                driver.DriverLicenseState = this.appData['DriversForm'][element].DriverLicenseState;
                driver.RelationshipToPrimaryNamedInsured = parseInt(this.appData['DriversForm'][element].RelationshipToPrimaryNamedInsured);
                driver.DateOfBirth = this.appData['DriversForm'][element].DateOfBirth;
                if ([30, 40, "30", "40"].includes(driver.DriverLicenseType)) {
                    driver.AgeFirstLicensed = null;
                } else {
                    driver.AgeFirstLicensed = this.appData['DriversForm'][element].AgeFirstLicensed ?? null
                }


                let DistantStudentDiscount = Utils.makeBool(this.appData['DriversForm'][element].DistantStudentDiscount);
                let GoodStudentDiscount = Utils.makeBool(this.appData['DriversForm'][element].GoodStudentDiscount);
                let DrivingCourse = Utils.makeBool(this.appData['DriversForm'][element].DrivingCourse);
                driver.ActualDiscounts = Utils.calculateDriverDiscountResponse(DistantStudentDiscount, GoodStudentDiscount, DrivingCourse);
                driver.HasSelfDeclaredDrivingViolation = typeof this.appData['DriversForm'][element].DrivingViolations !== "undefined" ? Utils.makeBool(this.appData['DriversForm'][element].DrivingViolations) : null;

                newDriversSet.push(driver);
            }
        }

        this.appTransformedData.Drivers = newDriversSet;


        let newSet = [];
        for (const node in this.appTransformedData.Drivers) {

            if (typeof this.appData['DriversForm'][node] !== "undefined") {
                newSet.push(this.appTransformedData.Drivers[node]);
            }
        }

        for (const index in newSet) {

            if (newSet[index].ReferenceId === primaryDriver.ReferenceId) {
                let temp = newSet.splice(index, 1);
                newSet.unshift(temp[0]);
            }
        }

        this.appTransformedData.Drivers = newSet;
    }

    prepDataFromGetQuoteForm() {
        for (const element in this.appData['DetailsStep']) {
            this.appTransformedData.HasAcceptedDisclaimer = Utils.makeBool(this.appData['DetailsStep'][element].HasAcceptedDisclaimer);

            this.appTransformedData.Applicant = {};
            this.appTransformedData.Applicant.FirstName = this.appData['DetailsStep'][element].FirstName.trim();
            this.appTransformedData.Applicant.MiddleName = this.appData['DetailsStep'][element]?.MiddleName ?? "";
            this.appTransformedData.Applicant.LastName = this.appData['DetailsStep'][element].LastName.trim();

            let one_address = Utils.makeBool(this.appData['DetailsStep'][element].HasLivedInCurrentAddressLessThan60Days);
            this.appTransformedData.Applicant.HasLivedInCurrentAddressLessThan60Days = one_address;

            this.appTransformedData.Applicant.WillBeInCurrentAddress30DaysAfterPolicyStart = true;
            this.appTransformedData.Applicant.IsLookupAddressSameAsInsured = (!one_address);

            this.appTransformedData.Applicant.InsuredAddress = this.InsuredAddress;

            if (!one_address) {
                this.appTransformedData.Applicant.LookupAddress = this.InsuredAddress;
            } else {
                const streetAddress = this.appData['DetailsStep'][element].StreetAddress
                this.appTransformedData.Applicant.LookupAddress = {
                    HouseNumber: streetAddress.split(" ")[0],
                    Apartment: this.appData['DetailsStep'][element].AptUnit,
                    City: this.appData['DetailsStep'][element].City,
                    County: this.appData['DetailsStep'][element].County,
                    ConstituentState: stateLookup('label', this.appData['DetailsStep'][element].State, "state"),
                    PostalCode: this.appData['DetailsStep'][element].ZipCode,
                    StreetName: this.appData['DetailsStep'][element].StreetName,
                    Street1: streetAddress
                }
            }
        }
    }

    prepDataFromAddressForm() {
        this.appTransformedData.InsuredAddress = this.appData['AddressStep'];
        this.InsuredAddress = this.appTransformedData.InsuredAddress;
        this.StateType = this.appData['AddressStep'].ConstituentState;
    }

    prepDataFromVehiclesForm() {
        if (typeof this.appTransformedData.Vehicles === "undefined") {
            this.appTransformedData.Vehicles = [];
        }
        this.appTransformedData.Vehicles = this.appTransformedData.Vehicles.filter(vehicle => {
            return !!this.appData['VehiclesForm'].find(v => v.ReferenceId === vehicle.ReferenceId)
        })

        for (const element in this.appData['VehiclesForm']) {

            let vehicle = {};
            if (typeof this.appTransformedData.Vehicles !== "undefined" && typeof this.appTransformedData.Vehicles[element] !== "undefined") {
                vehicle = this.appTransformedData.Vehicles[element];
            }
            vehicle.PurchaseDate = this.appData['VehiclesForm'][element].PurchaseDate;
            vehicle.VehicleUsageType = this.appData['VehiclesForm'][element].VehicleUsageType;
            vehicle.AnnualMileage = this.appData['VehiclesForm'][element].AnnualMileage;
            vehicle.VehicleOwnershipType = this.appData['VehiclesForm'][element].VehicleOwnershipType;
            vehicle.IsThisVehicleUsedForRideSharing = Utils.makeBool(this.appData['VehiclesForm'][element].IsThisVehicleUsedForRideSharing);
            vehicle.IsThisVehicleUsedForDelivery = Utils.makeBool(this.appData['VehiclesForm'][element].IsThisVehicleUsedForDelivery);
            vehicle.HasAutomaticBrakingTechnology = Utils.makeBool(this.appData['VehiclesForm'][element].HasAutomaticBrakingTechnology);
            vehicle.CommuteMilesOneWay = this.appData['VehiclesForm'][element].CommuteMilesOneWay;
            vehicle.CommuteDaysPerWeek = this.appData['VehiclesForm'][element].CommuteDaysPerWeek;
            vehicle.PrimaryDriver = this.appData['VehiclesForm'][element].PrimaryDriver;

            if (this.appData['VehiclesForm'][element].HasGaragedLocation === 'No') {
                vehicle.HasGaragedLocation = true;
                vehicle.GaragedLocation = this.appData['VehiclesForm'][element].GaragedLocation;
                vehicle.GarageAddress = this.appData['VehiclesForm'][element].GarageAddress;
                vehicle.GarageAddressOnly = this.appData['VehiclesForm'][element].GarageAddressOnly;
                vehicle.GarageAddressApt = this.appData['VehiclesForm'][element].GarageAddressApt;
            } else {
                vehicle.HasGaragedLocation = false;
                vehicle.GaragedLocation = null;
                vehicle.GarageAddress = null;
                vehicle.GarageAddressOnly = null;
                vehicle.GarageAddressApt = null;
            }

            this.appTransformedData.Vehicles[element] = vehicle;
        }

        let newSet = [];
        for (const element in this.appTransformedData.Vehicles) {

            if (typeof this.appData['VehiclesForm'][element] !== "undefined") {
                let add = true;


                if (typeof this.appData['userRemovedVehicles'] !== "undefined" && this.appData['userRemovedVehicles'].indexOf(parseInt(element)) > -1) {
                    add = false;
                }

                if (add) {
                    newSet.push(this.appTransformedData.Vehicles[element]);
                }
            }

        }

        this.appTransformedData.Vehicles = newSet;
    }

    prepDataFromCarrierForm() {
        let data = this.appTransformedData;
        let formData = this.appData['PreviousCarrier'];


        if (typeof data.CurrentOrRecentCoverage === "undefined") {
            data.CurrentOrRecentCoverage = {};
        }

        data.DesiredPolicyEffectiveDate = formData[0].DesiredPolicyEffectiveDate;
        data.CurrentOrRecentCoverage.CarrierName = formData[0].CarrierName;
        data.CurrentOrRecentCoverage.TimeWithCarrier = parseInt(formData[0].TimeWithCarrier);
        data.CurrentOrRecentCoverage.LiabilityLimit = formData[0].LiabilityLimit;
        data.CurrentOrRecentCoverage.PolicyExpirationDate = formData[0].PolicyExpirationDate;

        if (typeof data.CurrentOrRecentCoverage.ContinuousCoverage === "undefined") {
            data.CurrentOrRecentCoverage.ContinuousCoverage = {};
        }

        data.CurrentOrRecentCoverage.ContinuousCoverage.NumberOfYears = formData[0].NumberOfYears;
        data.CurrentOrRecentCoverage.ContinuousCoverage.NumberOfMonths = formData[0].NumberOfMonths;
        data.CurrentOrRecentCoverage.HadIneligibleCarrier = Utils.makeBool(formData[0].HadIneligibleCarrier);
    }

    prepDataFromContactForm() {
        let data = this.appTransformedData;
        let formData = this.appData['ContactDetails'];

        data.Applicant.Email = formData[0].Email;
        data.Applicant.ConfirmEmail = formData[0].EmailConfirm;
        data.Applicant.PrimaryPhoneNumber = formData[0].PrimaryPhoneNumber;

        data.AllowTelematics = Utils.makeBool(formData[0].AllowTelematics);
        data.HasAcceptedMarketingCommunication = Utils.makeBool(formData[0].HasAcceptedMarketingCommunication);
        data.HasAcceptedAffiliateRules = formData[0].hasOwnProperty('HasAcceptedContestRules') ? Utils.makeBool(formData[0].HasAcceptedContestRules) : null;

        if (!!formData[0].SecondaryPhoneNumber && formData[0].SecondaryPhoneNumber !== "") {
            data.Applicant.SecondaryPhoneNumber = formData[0].SecondaryPhoneNumber;
        }

        this.ajaxLastCall = null;
        this.ajaxLastType = null;
    }

    prepDataFromQuoteForm() {
        let data = this.appTransformedData;
        let formData = this.appData['Quotes'];

        this.quoteSelection = formData[0].QuoteSelection;
        data.BillingSelection = formData[0].BillingSelection;
        data.QuoteCoverDetails = formData[0].QuoteCoverDetails;
        data.SelectedCarrierId = formData[0].QuoteSelection.CarrierId;
        data.IsGuidePrice = formData[0].QuoteSelection.IsGuidePrice;
        data.Quotes = formData[0].Quotes;

        this.ajaxLastCall = null;
        this.ajaxLastType = null;
    }

    prepDataFromPaymentForm() {
        let data = {
            "QuoteId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            "Carrier": "Unspecified",
            "PaymentPlan": {
                "PaymentPlanType": "Unspecified",
                "InitialPayment": {
                    "PaymentMethod": "Unspecified",
                    "Amount": 0,
                    "CardPayment": {
                        "CardType": "string",
                        "CardholderName": "string",
                        "CardNumber": "string",
                        "ExpiryDate": {
                            "Month": 0,
                            "Year": 0
                        },
                        "SecurityCode": "string",
                        "BillingZipCode": "string"
                    }
                },
                "RecurringPayment": {
                    "PaymentMethod": "Unspecified",
                    "CardPayment": {
                        "CardType": "string",
                        "CardholderName": "string",
                        "CardNumber": "string",
                        "ExpiryDate": {
                            "Month": 0,
                            "Year": 0
                        },
                        "SecurityCode": "string",
                        "BillingZipCode": "string"
                    },
                    "NumberOfPayments": 0,
                    "HasSignedElectronically": true,
                    "HasConsentedToRecurringPayments": true
                },
                "ElectronicPayment": {}
            },
            "StateTypeId": 0,
            "LineOfBusinessTypeId": 0,
            "PolicySourceLookup": "string",
            "PaymentSource": "string",
            "IsPaymentAcknowledgementAccepted": null
        };
        let formData = this.appData['Payment'];

        let isProgressive = "9";
        let postProgressivePaymentAcknowledgement = (this.appTransformedData.SelectedCarrierId === isProgressive) && (this.StateType === "Georgia");


        data.QuoteId = this.ApplicationId;
        data.Carrier = this.quoteOptions.CarrierName;
        data.SelectedCarrierId = this.quoteOptions.CarrierId;
        data.PaymentSource = formData.PaymentSource;

        if (postProgressivePaymentAcknowledgement) {
            data.IsPaymentAcknowledgementAccepted = Utils.makeBool(formData.isPaymentAcknowledgementAccepted);
        }

        data.PaymentPlan.PaymentPlanType = Utils.paymentPlan(this.paymentMethodSelection.IsPayInFull, this.paymentMethodSelection.NumberOfPayments);
        data.PaymentPlan.InitialPayment.PaymentMethod = 'CreditCard';
        data.PaymentPlan.InitialPayment.Amount = this.paymentMethodSelection.DepositAmount;


        let paymentDetails;

        if (formData.isExternalPayment) {
            paymentDetails = {
                PaymentMethodId: formData.paymentMethodId,
                CorrelationId: formData.correlationId,
                PaymentMethodType: formData.paymentMethodTypeCode,
                PaymentMethodSubType: formData.paymentMethodSubTypeCode,
                CommercialIndicator: formData.commercialIndicator,
                ExpirationMonth: formData.cardExpirationMonth,
                ExpirationYear: formData.cardExpirationYear,
                AccountNumberShort: formData.accountNumberShort,
                CardType: formData.cardTypeCode,
            }

            data.PaymentPlan.InitialPayment.SubmittedPaymentDetails = {
                PaymentDetailsCaptureProviderType: formData.providerType,
                Details: paymentDetails
            };
            delete data.PaymentPlan.InitialPayment.CardPayment;
        } else {
            let expiry = formData.CardExpiry.split('/');
            formData.CardNumber = formData.CardNumber.replace(/[^0-9]+/g, "");
            paymentDetails = {
                CardType: Utils.GetCardType(formData.CardNumber),
                CardholderName: formData.CardholderName,
                CardNumber: formData.CardNumber,
                SecurityCode: formData.CardCVV,
                ExpiryDate: {
                    Year: expiry[1].trim(),
                    Month: expiry[0].trim(),
                },
                BillingZipCode: formData.BillingZipCode,
            }
            data.PaymentPlan.InitialPayment.CardPayment = paymentDetails;
        }

        if (this.paymentMethodSelection.IsEftPayment) {
            data.PaymentPlan.ElectronicPayment.AccountName = formData.AccountName;
            data.PaymentPlan.ElectronicPayment.AccountNumber = formData.AccountNumber;
            data.PaymentPlan.ElectronicPayment.RoutingNumber = formData.RoutingNumber;

        }

        if (this.paymentMethodSelection.IsEftPayment || this.paymentMethodSelection.IsMonthlyPayment ) {
            if (formData.isExternalPayment) {
                data.PaymentPlan.RecurringPayment.SubmittedPaymentDetails = paymentDetails;
                delete data.PaymentPlan.RecurringPayment.CardPayment;
            } else {
                data.PaymentPlan.RecurringPayment.CardPayment = paymentDetails;
            }
            data.PaymentPlan.RecurringPayment.NumberOfPayments = this.paymentMethodSelection.NumberOfPayments;
            data.PaymentPlan.RecurringPayment.HasSignedElectronically = true;
            data.PaymentPlan.RecurringPayment.HasConsentedToRecurringPayments = true;
        }

        if (this.paymentMethodSelection.IsPayInFull) {
            delete data.PaymentPlan.RecurringPayment;
        }

        data.StateTypeId = stateLookup('state', this.StateType, 'enum')
        data.LineOfBusinessTypeId = 10;
        data.PolicySourceLookup = '';

        this.ajaxLastCall = null;
        this.ajaxLastType = null;

        this.appTransformedData['Payment'] = data;
    }

    restartCallBack(result) {
        this.isEditingQuote = true;
        this.initialQuotesReceived = false;
        this.ApplicationId = result.QuoteId;
        this.StateType = stateLookup('enum', result.Applicant.InsuredAddress.StateType, 'state')

        this.appTransformedData.HasAcceptedDisclaimer = result.HasAcceptedDisclaimer;
        this.appTransformedData.DesiredPolicyEffectiveDate = result.DesiredPolicyEffectiveDate;
        this.appTransformedData.Applicant = result.Applicant;
        this.appTransformedData.Vehicles = result.Vehicles;
        this.appTransformedData.Drivers = result.Drivers;
        this.appTransformedData.Quotes = result.Quotes ?? [];
        this.appTransformedData.Limits = result.Limits ?? [];
        this.appTransformedData.ComprehensiveRestrictions = result.ComprehensiveRestrictions ? result.ComprehensiveRestrictions : [];
        this.appTransformedData.OwnershipRestrictions = result.OwnershipRestrictions ? result.OwnershipRestrictions : [];
        this.appTransformedData.QuoteCoverDetails = result.QuoteCoverDetails ? result.QuoteCoverDetails : [];
        this.appTransformedData.MedicalPaymentsLiability = result.MedicalPaymentsLiability ? result.MedicalPaymentsLiability : [];
        this.appTransformedData.UninsuredMotoristBodilyInjuryLimits = result.UninsuredMotoristBodilyInjuryLimits ? result.UninsuredMotoristBodilyInjuryLimits : [];
        this.appTransformedData.AllowTelematics = result.AllowTelematics;

        this.quoteCallBack().then();
    }

    async quoteCallBack() {

        let _this = this;
        let quoteId = this.ApplicationId;

        const coverageValuesURL = "api/cover?state=" + this.StateType + "&applicationId=" + quoteId;
        const coverageValues = await getFetchAPI(coverageValuesURL);

        if (coverageValues.ShouldWarmTransfer === true) {
            _this.showWarmTransferScreen('Failed to fetch the coverage details', warmTransferTypes.GET_QUOTES.ERROR, coverageValues.WarmTransferMessage);
            return;
        }

        let connection = new signalR.HubConnectionBuilder().withUrl(process.env.NEXT_STATIC_QUOTE_CALLBACK_URL,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            }).build();

        this.RealTimeQuotes.length = 0;

        this.timeOutTimer['quoteCallBack'] = setTimeout(_this.showQuoteScreen.bind(_this, connection, coverageValues), this.timeOutPeriod);

        if (process.env.NEXT_STATIC_MOCKQUOTEDATA === 'true') {
            _this.displayQuotes({});
        }

        connection.on("ReceiveQuote", function (jsonResponse) {
            _this.displayQuote(jsonResponse, coverageValues);
        });

        connection.on("ReceiveQuotes", function (jsonResponse) {
            const assignedValues = Object.assign({}, coverageValues, jsonResponse)
            _this.displayQuotes(assignedValues);
            connection.stop();
        });

        connection.start().then(function () {
            connection.invoke("CacheConnection", quoteId).catch(function (err) {
                _this.showWarmTransferScreen(
                    'Unhandled exception encountered connection.invoke("CacheConnection") : ' + JSON.stringify(err),
                    warmTransferTypes.GET_QUOTES.ERROR,
                    '',
                    connection);
            });
        }).catch(function (err) {
            _this.showWarmTransferScreen(
                'Unhandled exception encountered connection.start() : ' + JSON.stringify(err),
                warmTransferTypes.GET_QUOTES.ERROR,
                '',
                connection);
        });
    }

    displayQuote(json, cover) {
        const promise = new Promise(resolve => {

            resolve(this.RealTimeQuotes = [...this.RealTimeQuotes, {...json}]);
        });

        promise.then(() => {
            this.Cover = cover;
        }).then(() => {
            this.applicationObj.cancelInterstitial();
            this.applicationObj.setShowRealTimeQuotes(true);
        })
    }

    displayQuotes(json) {
        this.applicationObj.setShowRealTimeQuotes(false);

        for (const element in this.timeOutTimer) {
            clearTimeout(this.timeOutTimer[element]);
            delete this.timeOutTimer[element];
        }

        if (json.ShouldUseWarmTransfer) {
            this.showWarmTransferScreen(
                'Warm transfer encountered',
                warmTransferTypes.NO_QUOTE,
                json.WarmTransferMessage);
        } else {
            this.quoteSelection = json;

            this.applicationObj.setAPIData(json);
            if (!this.initialQuotesReceived) {
                this.applicationObj.increaseStep(6);
                this.applicationObj.cancelRetrieval();
                this.initialQuotesReceived = true;
            }
            if (this.isEditingQuote) {
                this.isEditingQuote = false;
            }

            this.applicationObj.cancelInterstitial();

        }

    }

    defaultPostAPIRequest() {
        this.applicationObj.increaseStep();
        this.applicationObj.cancelInterstitial();
    }

    deleteDriver(drivers, currentFormData, youngDriver = null) {
        if (youngDriver) {
            this.appTransformedData.RemovedYoungDrivers.push(youngDriver)
        }
        this.appTransformedData.ShowPolicyholderModal = false;
        this.appTransformedData.Drivers = drivers.map(driver => {
            const updatedData = currentFormData.find(data => data.ReferenceId === driver.ReferenceId)
            return Object.assign({}, driver, updatedData);
        });

        this.applicationObj.setAPIData(this.appTransformedData);
    }

    addDriver(data, currentFormData, ref, setAsMainApplicant = false) {
        this.applicationObj.showInterstitial('Saving Driver Details');
        this.appTransformedData.ShowPolicyholderModal = false;
        let driver = {};

        driver.Occupation = data[0].Occupation;
        driver.Industry = data[0].Industry;
        driver.Employment = data[0].Employment;
        driver.EducationType = data[0].EducationType;
        driver.PrimaryResidence = data[0].PrimaryResidence;
        driver.MaritalStatus = data[0].MaritalStatus;
        driver.RelationshipToPrimaryNamedInsured = data[0].RelationshipToPrimaryNamedInsured;
        driver.GenderType = mapGenderStr(data[0].GenderType);

        driver.ReferenceId = Utils.uuidv4();
        driver.FirstName = data[0].FirstName;
        driver.MiddleName = data[0].MiddleName;
        driver.LastName = data[0].LastName;
        driver.DriverLicenseNumber = data[0].DriverLicenseNumber;
        driver.DrivingViolations = data[0]?.DrivingViolations;
        driver.AgeFirstLicensed = data[0]?.AgeFirstLicensed;

        driver.DriverLicenseState = stateLookup('label', data[0].DriverLicenseState, 'enum')
        driver.DriverLicenseType = data[0]?.DriverLicenseType;
        driver.IsManuallyAdded = true;
        driver.Open = true;
        driver.IsNew = true;


        driver.DateOfBirth = data[0].DateOfBirth;

        if (setAsMainApplicant) {
            driver.RelationshipToPrimaryNamedInsured = 30;

            Object.assign(this.appTransformedData.Applicant,
                {FirstName: driver.FirstName, LastName: driver.LastName});
        }
        this.appTransformedData.Drivers = this.appTransformedData.Drivers.concat(driver);
        this.appTransformedData.Drivers = this.appTransformedData.Drivers.map(driver => {
            const updatedData = currentFormData.find(data => data.ReferenceId === driver.ReferenceId)
            return Object.assign({}, driver, updatedData);
        });

        this.applicationObj.setAPIData(this.appTransformedData);

        return driver;
    }

    getLegalDisclosures(callback, carrierId, disclosureIds) {
        let endpoint = `api/legal-disclosures?carrierId=${carrierId}&disclosureIds=${disclosureIds}`;

        getFetchAPI(endpoint).then(
            async (response) => {

                if (response.IsSuccessful) {
                    callback(response);
                }
            });
    }

    getDiscounts(dateOfBirth, driverRef, MaritalStatus, Relationship, PrimaryDriver, callBack) {
        this.driverDiscountRef = driverRef;
        this.driverRefDiscountCallBack = callBack;

        if (Relationship === null) {
            this.processDiscounts({
                "EligibleDiscounts": 0,
                "ApplicationId": this.ApplicationId,
                "ShouldWarmTransfer": false,
                "IsSuccessful": true
            })
        } else {
            const endPoint = `/api/${this.StateType}/${this.ApplicationId}/eligibleDiscounts`;
            let data = {
                dateOfBirth: dateOfBirth,
                maritalStatus: parseInt(MaritalStatus),
                isApplicant: PrimaryDriver,
                relationshipToPrimaryInsured: parseInt(Relationship)
            };

            if (PrimaryDriver === true) {
                data.relationshipToPrimaryInsured = this.DriverRelationMapping.INSURED;
            }

            this.ajaxLastCall = null;
            this.ajaxLastType = null;
            setTimeout(() => {
                this.callAPI(this.API + endPoint, data, true, 'processDiscounts');
            }, 2000);
        }
    }

    getDiscountsAsync(dateOfBirth, driverRef, MaritalStatus, PrimaryDriver, relationshipToPrimaryInsured) {
        const endPoint = `/api/${this.StateType}/${this.ApplicationId}/eligibleDiscounts`;

        let data = {
            dateOfBirth: dateOfBirth,
            maritalStatus: MaritalStatus,
            isApplicant: PrimaryDriver
        };

        if (relationshipToPrimaryInsured) {
            data.relationshipToPrimaryInsured = parseInt(relationshipToPrimaryInsured)
        }

        this.ajaxLastCall = null;
        this.ajaxLastType = null;

        return new Promise((resolve) => {
            this.callAPI(this.API + endPoint, data, true, 'processDiscounts', result => {
                for (let x = 0; x < this.appTransformedData.Drivers.length; x++) {
                    if (typeof this.appTransformedData.Drivers[x].ReferenceId !== "undefined") {
                        if (this.appTransformedData.Drivers[x].ReferenceId === driverRef) {
                            this.appTransformedData.Drivers[x].EligibleDiscounts = result.EligibleDiscounts;
                        }
                    }
                }

                resolve({
                    driverRef: driverRef,
                    eligibleDiscounts: result.EligibleDiscounts,
                    appData: this.appTransformedData
                });
            }, true);
        });
    }

    processDiscounts(result) {
        for (let x = 0; x < this.appTransformedData.Drivers.length; x++) {
            if (typeof this.appTransformedData.Drivers[x].ReferenceId !== "undefined") {
                if (this.appTransformedData.Drivers[x].ReferenceId === this.driverDiscountRef) {
                    this.appTransformedData.Drivers[x].EligibleDiscounts = result.EligibleDiscounts;
                }
            }
        }
        this.driverRefDiscountCallBack(this.driverDiscountRef, result.EligibleDiscounts, this.appTransformedData);
        this.appTransformedData.ShowPolicyholderModal = false;
        this.applicationObj.setAPIData(this.appTransformedData);
    }

    getElectronicDocumentOptions() {
        if (this.quoteSelection.CarrierId === 999) {
            const endPoint = '/api/electronic-document-configuration';
            let data = {
                "CarrierId": this.quoteSelection.CarrierId,
                "State": this.StateType
            };
            this.callAPI(this.API + endPoint, data, true, 'processElectronicDocumentOptions');
        }
        else {
            this.checkTelematicsAndElectronicDocumentOptionsHaveReturned();
        }
    }

    getUninsuredMotoristOptions() {
        const endPoint = '/api/show-um-options';
        let data = {
            "CarrierId": this.quoteSelection.CarrierId,
            "State": this.StateType
        };
        this.callAPI(this.API + endPoint, data, true, 'processUmOptions');
    }

    checkTelematicsAndElectronicDocumentOptionsHaveReturned() {
        this.processQuoteCountdownLatch--;
        if (this.processQuoteCountdownLatch <= 0) {
            this.getPaymentOptions();
        }
    }
}

export default AVB_IOA_app;