import * as states from "@config/states";

import MaterialTypeAhead from "@components/general/material/type-ahead";
import MaterialInput from "@components/general/material/input";


const getStateLabels = (states, excludeInt) => {
    excludeInt = excludeInt ?? true;
    return states.filter((state) => {
        return !(excludeInt && state.international)
    }).map((state) => {
        return {label: state.label}
    });

}

const stateOptions = getStateLabels(states);


const AddressForm = ({address, errors, submitted, handleClick, handleKeyup}) => {

    const checkForSpaces = (event) => {
        const value = event.target.value;
        if (/^\S*$/.test(value)) {
            handleKeyup(event);
        }
    }

    return (
        <div>
            <div className="mt-4">

                <div className="row">
                    <div className="col-12 col-md-8 col-lg-9 mb-4 first">
                        <MaterialInput
                            handleKeyup={handleKeyup}
                            id={'Street1'}
                            cy={'StreetAddress'}
                            submitted={submitted}
                            placeholder={'ex. 100 Main Street'}
                            text={'Street Address'}
                            value={address.Street1}
                            error={errors.Street1}
                        />
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 mb-4">
                        <MaterialInput
                            handleKeyup={checkForSpaces}
                            id={'Apartment'}
                            cy={'AptUnit'}
                            submitted={submitted}
                            placeholder={'Optional'}
                            text={'Apt/Unit'}
                            required={false}
                            value={address.Apartment}
                            error={errors.Apartment}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6 mb-4 first">
                        <MaterialInput
                            handleKeyup={handleKeyup}
                            id={'City'}
                            cy={'City'}
                            submitted={submitted}
                            placeholder={'ex. Panama'}
                            text={'City/Town'}
                            value={address.City}
                            error={errors.City}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-4">
                        <MaterialInput
                            handleKeyup={handleKeyup}
                            id={'County'}
                            cy={'County'}
                            submitted={submitted}
                            placeholder={'ex. Bay County'}
                            text={'County'}
                            value={address.County}
                            error={errors.County}
                            required={false}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6 col-md-6 mb-4 first">
                        <MaterialTypeAhead
                            handleKeyup={handleKeyup}
                            id={'ConstituentState'}
                            cy={'State'}
                            submitted={submitted}
                            placeholder={'Please select a state'}
                            text={'State'}
                            value={address.ConstituentState}
                            options={stateOptions}
                            error={errors.ConstituentState}
                            preselectedState={address.ConstituentState}
                        />
                    </div>
                    <div className="col-6 col-md-5 col-lg-4 mb-4">
                        <MaterialInput
                            handleKeyup={handleKeyup}
                            id={'PostalCode'}
                            cy={'ZipCode'}
                            type={'tel'}
                            submitted={submitted}
                            placeholder={'ex. 32405'}
                            text={'ZIP Code'}
                            value={address.PostalCode}
                            error={errors.PostalCode}
                        />
                    </div>
                </div>

            </div>

            <button
                type="button"
                className="link dark mb-4"
                onClick={handleClick}
            >Back to address finder
            </button>

        </div>
    );

}


export default AddressForm