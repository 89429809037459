import { Component } from 'react';
import SiteHeader from "@layout/global_header";
import Navigation from "@layout/navigation";
import Footer from "@layout/footer";
import Utils from "@components/general/AVB_utils";

import SVG from "@components/general/svg";

import {Row, Col, Container} from "react-bootstrap";
import {SHARED_ASSETS_PATH} from "@constants";


class SiteForms extends Component {

    constructor(props) {
        super(props);
        this.bg = props.bg ?? '';
        this.state = {
            currentPage: '',
            displayLogo: ''
        };
    }

    componentDidMount() {
        if (process.env.NEXT_STATIC_ENV !== 'local' && !this.props.preventReload) {
            window.addEventListener("beforeunload", Utils.pageUnload);
        }
    }

    componentWillUnmount() {
        if (process.env.NEXT_STATIC_ENV !== 'local'  && !this.props.preventReload) {
            window.removeEventListener("beforeunload", Utils.pageUnload);
        }
    }

    render() {

        return (
            <div className="formPage">

                <SiteHeader/>

                <Navigation
                    bg={this.bg}
                    campaignDetails={this.props.campaignDetails}
                />

                <main>
                    {this.props.maintenance ? (
                        <section className="error my-5 py-5">
                            <Container className="text-center">
                                <Row>
                                    <Col className="mb-5">
                                        <SVG style={{width: "400px", paddingBottom: "2rem"}} img={SHARED_ASSETS_PATH + "svg/logo-red-text-navy.svg"}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className="  semi-bold primary-text text-center mb-5">Temporarily Down for Maintenance</p>
                                        <p className="  semi-bold   text-center">We are performing scheduled maintenance.<br/> We should be back online shortly.<br className='d-none d-sm-inline'/></p>
                                        <p className="  text-center">In the meantime you can contact us on <a href={'tel:' + phone}>{ phone }</a><br/> and an operator will be able to assist you.</p>
                                        <SVG style={{width: "60%", paddingTop: "2rem"}} src="maintenance" />
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    ) : (
                        this.props.children
                    )}
                </main>

                <Footer/>

            </div>
        );

    }

}

export default SiteForms;
