import SVG from "@components/general/svg";
import { useEffect } from "react";
import {trapFocusInModal} from "@utilities";


export default function AddressConfirmationModal({address, handleCancel, handleSubmit}) {


    useEffect(() => {
        trapFocusInModal(modalId, elementId)
        return function cleanup() {
            document.body.style.overflow = null;
        }
    }, [])


    const modalId = "get-quote-form-step-instruction-unassigned-driver-modal";

    const elementId = "confirm-address-modal-submit-button"


    return (
        <div className={'additional'} id={modalId}>
            <div className={'additional__modal'}>

                <button
                    type="button"
                    className={'close-modal'}
                    onClick={handleCancel}
                    data-cy="th_AddressConfirmation_CloseModal"
                />

                <div className={'additional__modal__body'}>
                    <div className={'modalPanel--form'}>

                        <h2>We could not find the address you entered</h2>

                        <p className="explanation pt-1">You may go back to make changes or continue with the address you entered.<br/></p>
                        <p className={'semi-bold mt-4 pb-4'}>{ address }</p>

                        <div className="divider mb-4"/>

                        <form className="form" onSubmit={e => { e.preventDefault() }}>
                            <div className="submit-holder dualButtons">
                                <button className='button' type="button" onClick={handleCancel}>
                                    <SVG aria-hidden="true" src={'arrow-left-grey'} alt={'left arrow'}/> Go Back</button>
                                <button onClick={handleSubmit} type="button" name="submit" id="confirm-address-modal-submit-button" className="modal-submit confirm">Confirm</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );

}