import {Image} from "react-bootstrap";
import { useEffect, useState } from "react";
import {providers} from "@config/site";
import { isEZLynxState, resetPagePosition, trapFocusInModal } from "@utilities";
import { CARRIER_IMAGE_PATH } from "@constants";


export default function RealTimeOverlay(props) {

    const carriers = isEZLynxState(props.stateName) ? [...providers.main, ...providers.others] :  providers.main;
    const [carrier, setCarrier] = useState(carriers[0]);
    const [loop, setLoop] = useState(0);


    useEffect(() => {
        trapFocusInModal('real-time-overlay', '', false);
        resetPagePosition();

        let counter = 1;

        setLoop(
            setInterval(() => {
                const newCarrier = carriers[counter % carriers.length]
                setCarrier(newCarrier)
                counter++;
            }, 4000)
        )
        return function cleanup() {
            clearInterval(loop);
        };
    }, [])


    return (
        <div className="real-time-overlay" id="real-time-overlay">
            <div className="real-time-overlay--container">
                <p className="semi-bold mb-2">We are contacting our insurance partners to get your quotes.</p>
                <p className="mb-4">The best things take time, so please bear with us.</p>
                <div className="real-time-overlay--image mt-3" aria-live="polite">
                    <Image tabIndex='0' className="img-fluid switch"
                           src={CARRIER_IMAGE_PATH + carrier + "--thumbnail--color.png"} alt={carrier}/>
                </div>
            </div>
        </div>
    )

}