import { Component } from "react";
import SVG from "@components/general/svg";
import VWO from "@vendor/vwo";
import GoalConversion from "@vendor/goal-conversion"
import {trapFocusInModal} from "@utilities";
import {SHARED_ASSETS_PATH} from "@constants";
import getStateList from "../../utilities/get-state-list";


class ManualAddress extends Component {

    constructor(props) {
        super(props);
        const address = props.address ?? {};
        this.fields = {
            apartment: "Apartment",
            route: "Street Name",
            locality: "City",
            administrative_area_level_2: "County",
            administrative_area_level_1: "State",
            postal_code: "ZIP Code"
        };

        this.state = {
            fieldValues: {
                apartment: address.apartment??'',
                route: address.route??'',
                locality: address.locality??'',
                administrative_area_level_2: address.administrative_area_level_2??'',
                administrative_area_level_1: address.administrative_area_level_1??'',
                postal_code: address.postal_code??'',
            },
            fieldErrors: {},
            submitted: false
        };

        this.handleInput = this.handleInput.bind(this);
    }

    componentDidMount() {
        trapFocusInModal('manual-address-modal', 'route')
    }

    componentWillUnmount() {
        document.body.style.overflow = null
    }

    submitForm() {
        this.setState({
            submitted: true,
        });
        if (this.formValidation()) {
            let {fieldValues} = this.state;
            this.props.action(fieldValues);
        }
    }


    formValidation() {

        this.clearFieldErrors();
        Object.keys(this.state.fieldValues).forEach(key => {
            if (!['apartment'].includes(key)) {
                if (this.state.fieldValues[key].length <= 0) {
                    this.updateFieldErrors(key, this.fields[key] + ' is required');
                }
                if (['postal_code'].includes(key)) {
                    if (isNaN(this.state.fieldValues[key])) {
                        this.updateFieldErrors(key, this.fields[key] + ' must be a number');
                    }
                }
            } else if (/[^a-z0-9]+$/i.test(this.state.fieldValues[key])) {
                this.updateFieldErrors(key, this.fields[key] + ' must not contain special characters');
            }
        });
        return Object.keys(this.state.fieldErrors).length <= 0;
    }


    clearFieldErrors() {
        let fieldErrors = this.state.fieldErrors;
        Object.keys(fieldErrors).forEach(key => delete fieldErrors[key]);
        this.setState({
            fieldErrors: fieldErrors
        });
    }


    updateFieldErrors(field, error) {
        let fieldErrors = this.state.fieldErrors;
        let errors = fieldErrors[field];
        errors = ( typeof errors !== 'undefined' && errors instanceof Array ) ? errors : [];
        errors.push(error);
        errors = [...new Set(errors)];
        fieldErrors[field] = errors;
        this.setState({
            fieldErrors: fieldErrors
        });
    }


    test(value) {
        let fieldValues = this.state.fieldValues;
        fieldValues.administrative_area_level_1 = value;
        this.setState({
            fieldValues: fieldValues
        });
    }


    handleInput(event) {
        let fieldValues = this.state.fieldValues;
        let field = event.target.id;
        fieldValues[field] = event.target.value;
        this.setState({
            fieldValues: fieldValues
        });

        if (this.state.submitted) {
            this.formValidation();
        }
    }


    displayErrors(field) {
        let fieldErrors = this.state.fieldErrors;
        if (this.isError(field)) {
            return (
                <div className="fieldErrors">
                    <p key={'error_' + field} className="field_error">{fieldErrors[field][0]}</p>
                </div>
            )
        }
    }


    isError(field) {
        let fieldErrors = this.state.fieldErrors;
        return field in fieldErrors;
    }


    render() {
        const stateList = getStateList();
        return (
            <div className={'additional'} id={'manual-address-modal'}>
                <GoalConversion
                    goalId={VWO.MANUAL_ADDRESS_GOAL_ID}
                />

                <div className={'additional__modal additional__modal--large'}>

                    <button
                        type="button"
                        className={'close-modal'}
                        onClick={this.props.close}
                        data-cy="th_ManualAddress_CloseModal"
                    />
                    <div className={'additional__modal__body'}>
                        <div className={'modalPanel--form container'}>

                            {this.props.edit ? (
                                <h2 className={"large mt-2 mb-4"}>Your Address</h2>
                            ) : (
                                <>
                                    <h2>Enter Address</h2>
                                    <p>We could not find your address. Please add your details using the form below.</p>
                                </>
                            )}

                            <div className="form">

                                <div className="">

                                    <div className="row">
                                        <div className="col-12 col-md-8 col-lg-8 mb-3">
                                            <label htmlFor="route">Street Name <span aria-label='required' className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="route"
                                                id="route"
                                                value={this.state.fieldValues.route}
                                                className={this.isError('route') ? 'field_error' : ''}
                                                onChange={this.handleInput}
                                            />
                                            {this.displayErrors('route')}
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 mb-3">
                                            <label htmlFor="apartment">Apt/Unit (optional)</label>
                                            <input
                                                type="text"
                                                name="apartment"
                                                id="apartment"
                                                value={this.state.fieldValues.apartment}
                                                onChange={this.handleInput}
                                            />
                                            {this.displayErrors('apartment')}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6 mb-3">
                                            <label htmlFor="locality">City/Town <span aria-label='required' className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="locality"
                                                id="locality"
                                                value={this.state.fieldValues.locality}
                                                className={this.isError('locality') ? 'field_error' : ''}
                                                onChange={this.handleInput}
                                            />
                                            {this.displayErrors('locality')}
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 mb-3">
                                            <label htmlFor="administrative_area_level_2">County</label>
                                            <input
                                                type="text"
                                                name="administrative_area_level_2"
                                                id="administrative_area_level_2"
                                                value={this.state.fieldValues.administrative_area_level_2}
                                                className={this.isError('administrative_area_level_2') ? 'field_error' : ''}
                                                onChange={this.handleInput}
                                            />
                                            {this.displayErrors('administrative_area_level_2')}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-md-6 mb-3">
                                            <label htmlFor="administrative_area_level_1">State <span aria-label='required' className="required">*</span></label>
                                            <div className={'select'} style={{width: '100%'}}>
                                                <select
                                                    id="administrative_area_level_1"
                                                    name="administrative_area_level_1"
                                                    value={this.state.fieldValues.administrative_area_level_1}
                                                    className={this.isError('administrative_area_level_1') ? 'field_error' : ''}
                                                    onChange={this.handleInput}
                                                >
                                                    <option value="">Please Select</option>
                                                    {stateList.map((state, index) => {
                                                        return (
                                                            <option key={index} value={state.value}>{ state.label }</option>
                                                        )
                                                    })}
                                                </select>
                                                <div className="select__arrow"/>
                                            </div>


                                            {this.displayErrors('administrative_area_level_1')}
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <label htmlFor="postal_code">ZIP Code <span aria-label='required' className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="postal_code"
                                                id="postal_code"
                                                value={this.state.fieldValues.postal_code}
                                                className={this.isError('postal_code') ? 'field_error' : ''}
                                                onChange={this.handleInput}
                                            />
                                            {this.displayErrors('postal_code')}
                                        </div>
                                    </div>

                                    <div className="submit-holder">
                                        <button
                                            type="submit"
                                            onClick={e => { e.preventDefault(); this.submitForm() }}
                                            className="ioa-pl-xl"
                                        >{ this.props.edit ? "Update Address" : "Next" }<SVG img={SHARED_ASSETS_PATH + "svg/arrow-right-white.svg"} alt="right arrow icon"/></button>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default ManualAddress;