export const credit_card_active = (value, conditions) => {

    let valid = true;

    if (value.trim().replace(/[^a-z0-9]/gi, "").length !== 4) {
        valid = false;
    }

    const thisDate = new Date();
    const thisYear = thisDate.getFullYear() % 100;
    const thisMonth = Number(thisDate.getMonth() + 1);

    const date = value.split("/");
    const m = Number(date[0]);
    const y = Number(date[1]);

    const testYear = y - (m > thisMonth ? conditions.limit - 1 : conditions.limit);

    if (testYear > thisYear) {
        valid = false;
    }

    return valid;

}
