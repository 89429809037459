import { createRef, Component } from 'react';
import dynamic from "next/dynamic";
import * as signalR from "@microsoft/signalr";
import Cookies from "universal-cookie";
import AVB_IOA_app from '@components/general/AVB_IOA_app'
import Utils from "@components/general/AVB_utils";
import { propExists, checkAndSetCookie, getFetchAPI, getPostAPI } from "@utilities";
import {
    DEFAULT_WARM_TRANSFER_MESSAGE,
    LIVE_CHAT_CARRIERS,
    CARRIER_NATIONWIDE_NO_PAYMENT_PROVIDER_ID,
    warmTransferTypes, COOKIE_AFFILIATE_CODE, COOKIE_AFFILIATE_APPLICATION_ID,
    COMPLETE_PAYMENT_EXTERNAL_SOURCE, COMPLETE_PAYMENT_CONSUMER_SOURCE, GOOGLE_CLICK_ID
} from "@constants";

import SectionHolder from "@components/general/SectionHolder";
const ProgressBanner = dynamic(() => import("@layout/progress-banner"), {loading: () => <div style={{minHeight: "120px"}}/>} );
const RetrievalFormModal = dynamic(() => import("@modals/retrieve-quote-modal"));
const AddVehicleModal = dynamic(() => import("@modals/add-vehicle-modal"));
const Interstitial = dynamic(() => import("@steps/interstitial"), {loading: () => <SectionHolder/>} );
const RealTimeQuotes = dynamic(() => import("@steps/real-time-quotes"), {loading: () => <SectionHolder/>} );
const WarmTransfer = dynamic(() => import("@steps/warm-transfer"), {loading: () => <SectionHolder/>} );
const Error = dynamic(() => import("@components/general/error"), {loading: () => <SectionHolder/>} );

import AddressStep from "@steps/AddressStep";
import DetailsStep from "@steps/1-your-details";
import BackToAgent from "@components/general/BackToAgent";
import VisualQA from "@components/general/VisualQA";
import LiveChatIcon from "@components/general/LiveChatIcon";

const DriversForm = dynamic(() => import("@steps/2-driver-details"), {loading: () => <SectionHolder/>} );
const VehiclesForm = dynamic(() => import("@steps/3-vehicle-details"), {loading: () => <SectionHolder/>} );
const PreviousCarrier = dynamic(() => import("@steps/4-current-carrier"), {loading: () => <SectionHolder/>} );
const ContactDetails = dynamic(() => import("@steps/5-contact-details"), {loading: () => <SectionHolder/>} );
const Quotes  = dynamic(() => import("@steps/6-quotes"), {loading: () => <SectionHolder/>} );
const Payment = dynamic(() => import("@steps/PaymentStep"), {loading: () => <SectionHolder/>} );
const NationwidePayment = dynamic(() => import("@steps/PaymentStep/nationwide-payment"), {loading: () => <SectionHolder/>} );
const Success  = dynamic(() => import("@steps/success"), {loading: () => <SectionHolder/>} );
const ClientVerificationStep  = dynamic(() => import("@components/complete-payment/steps/ClientVerificationStep"), {loading: () => <SectionHolder/>} );
const DisplayQuoteStep = dynamic(() => import("@components/complete-payment/steps/DisplayQuoteStep"), {loading: () => <SectionHolder/>} );
const PrePaymentStep = dynamic(() => import("@steps/PrePaymentStep"), {loading: () => <SectionHolder/>} );


class Application extends Component {

    constructor(props) {
        super(props);

        this.appObj = (props.appObj)?props.appObj:new AVB_IOA_app();

        const {
            visualQa = false,
            testData: {
                step = 0,
                data = {},
                loading = false
            } = {},
        } = props;

        this.state = {
            currentStep: step,
            visualQa: visualQa,
            showingRetrieval:false,
            showingInterstitial:loading,
            showingWarmTransfer: false,
            warmTransferType:warmTransferTypes.OTHER,
            showingAdditional:false,
            showingError:false,
            showingRealTimeQuotes: false,
            enableEditAddress: false,
            showRetry: false,
            showQuoteFail: false,
            retryAttempts: 0,
            APIData: data,
            telematics:[],
            fieldVariations:{},
            affiliateCode: '',
            retrievalCode: '',
            retrievalState: '',
            retrievalCarrierId: '',
            retrievalCallbackUrl: '',
            retrievalSource: '',
            errorMessage: '',
            InterstitialMessage: '',
            dob: {},
            formLoading: false,
            garagedLocations:[],
            enableLiveChat: false
        };

        this.appObj.setApplicationObj(this);
        this.scrollTopRef = createRef();
        this.scrollErrorRef = createRef();
        this.AdditionalRef = createRef();
        this.additionalPaymentData = {};

        this.handlePrePayment = this.handlePrePayment.bind(this);
        this.showWarmTransfer = this.showWarmTransfer.bind(this);
        this.saveGaragedLocations = this.saveGaragedLocations.bind(this);
        this.resetVehicleMessages = this.resetVehicleMessages.bind(this);
    }

    async componentDidMount() {
        const {currentStep} = this.state;

        let cleanURL = window.location.href.replace('&#x3D;', "=");
        let url = new URL(cleanURL);

        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30);

        const code = checkAndSetCookie("code", COOKIE_AFFILIATE_CODE);
        if (code !== undefined) {
            this.setState({
                affiliateCode: code
            });
        }

        const app = checkAndSetCookie("app", COOKIE_AFFILIATE_APPLICATION_ID);
        if (app !== undefined) {
            this.setState({
                affiliateApplicationCode: app
            });
        }

        const gclid = checkAndSetCookie("gclid", GOOGLE_CLICK_ID);
        if (gclid !== undefined) {
            this.setState({
                gclid: gclid
            });
        }

        if (url.searchParams.get("utm_source")) {
            let utm_source = url.searchParams.get("utm_source");
            this.setState({
                affiliateCode: utm_source
            });
        }

        if (currentStep === 0) {
            let consumerRef = url.searchParams.get("consumerRef");

            const applicationIdRef = url.searchParams.get("ref") ? url.searchParams.get("ref") : null;

            if (consumerRef) {
                this.setState({
                    formLoading: true
                });

                this.increaseStep(1, false);

                this.setState({
                    enableEditAddress: true
                });
            }

            const siteCodes = {
                code,
                app,
                consumerRef,
                applicationIdRef
            };

            const dimensions = {
                width: window.innerWidth,
                height: window.innerHeight,
            }

            this.appObj.startApplication(siteCodes, dimensions).then((res) => {
                if (!res.outcome) {
                    this.increaseStep(0, false);
                    this.setState({
                        formLoading: false,
                        enableEditAddress: false
                    });
                }

                if (consumerRef) {
                    if (!res.consumerAddressPresent || !res.googleMapsFound) {
                        this.increaseStep(0, false);
                        this.setState({
                            formLoading: false,
                            enableEditAddress: false
                        });

                    } else {
                        this.increaseStep(1);
                        this.setState({
                            formLoading: false,
                            enableEditAddress: true
                        });
                    }
                }

            });
        }

        if (url.searchParams.get("token")) {
            this.appObj.carrierToken = url.searchParams.get("token");
        }

        let validatedCallbackUrl = '';
        if (currentStep === 0 && this.props.completePayment) {
            const applicationId = url.searchParams.get("ref") ? url.searchParams.get("ref") : '';
            const state = url.searchParams.get("state") ? url.searchParams.get("state") : '';
            const carrierId = url.searchParams.get("carrierId") ? url.searchParams.get("carrierId") : '';
            const callbackUrl = url.searchParams.get("callbackurl") ? url.searchParams.get("callbackurl") : '';
            this.appObj.StateType = state;

            validatedCallbackUrl = this.validateCallbackUrl(callbackUrl)

            const campaignDetails = await getFetchAPI(`api/marketing/campaigns/campaign-details?applicationId=${applicationId}&state=${state}`);
            this.appObj.saveCampaignDetails(campaignDetails, false);

            this.setState({
                retrievalCode: applicationId,
                retrievalState: state,
                retrievalCarrierId: carrierId,
                retrievalCallbackUrl: validatedCallbackUrl
            });

            this.appObj.ApplicationId = applicationId;
        }

        const source = this.getPaymentSource(validatedCallbackUrl);
        this.setState({
            retrievalSource: source
        });

        if (currentStep === 0 && url.searchParams.get("ref") && !this.props.completePayment) {
            let applicationId = url.searchParams.get("ref") ? url.searchParams.get("ref") : '';
            let state = url.searchParams.get("state") ? url.searchParams.get("state") : '';

            this.setState({
                retrievalCode: applicationId,
                retrievalState: state
            });

            this.appObj.ApplicationId = applicationId;

            this.showRetrieval();
        }

        if (LIVE_CHAT_CARRIERS.includes(code)) {
            this.setState({
                enableLiveChat: true
            });
        }

        Utils.updateAnalytics('your-details');

        this.createFormStep();
    }

    validateCallbackUrl(url) {
        if (url && url.startsWith("https://")) {
            const link = document.createElement("a");
            link.href = url;
            const domain = link.hostname.split(".").slice(-2).join('.');
            return domain === "simplyioa.com" ? url : "";
        }
        return "";
    }

    getPaymentSource(url) {
        if (url === "") {
            return COMPLETE_PAYMENT_CONSUMER_SOURCE;
        } else {
            return COMPLETE_PAYMENT_EXTERNAL_SOURCE;
        }
    }

    processStep(data, formName) {
        this.appObj.storeUserData(data, formName);
        const nextStepName = Utils.getStepName(this.state.currentStep + 1);
        Utils.updateAnalytics('loading-'+nextStepName);
    }

    handleSelectedDrivers(data) {
        if (!data.Drivers[0].hasOwnProperty("EligibleDiscounts")) {

            this.showInterstitial('Updating Driver Details');

            let dateOfBirth = data.Drivers[0].DateOfBirth;
            let MaritalStatus = data.Drivers[0].MaritalStatus;
            let PrimaryDriver = true;
            let relationship = data.Drivers[0].RelationshipToPrimaryNamedInsured;

            Utils.postToAPI({
                dateOfBirth: dateOfBirth,
                maritalStatus: MaritalStatus,
                isApplicant: PrimaryDriver,
                relationshipToPrimaryInsured: relationship
            }, (result) => {
                data.Drivers[0].EligibleDiscounts = result.EligibleDiscounts;
                this.setAPIData(data);
                this.cancelInterstitial();
            }, `/api/${this.appObj.StateType}/${this.appObj.ApplicationId}/eligibleDiscounts`)

        } else {
            this.setAPIData(data);
        }
    }

    handlePrePayment(data) {
        this.showInterstitial();

        let connection = new signalR.HubConnectionBuilder()
            .withUrl(process.env.NEXT_STATIC_QUOTE_CALLBACK_URL,
            { 
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }  
            })
            .build();

        let _this = this;

        let request = {
            ApplicationId: data.ApplicationId,
            ConstituentState: data.ConstituentState,
            CarrierId: data.CarrierId.toString(),
            Lienholders: data.Lienholders,
            TelematicsInformation: data.TelematicsInformation,
            UninsuredMotoristOptionsAddedOn: data.UninsuredMotoristOptionsAddedOn,
            DeliveryInformation: data.DeliveryInformation ? data.DeliveryInformation : null,
            Drivers: this.appObj.appTransformedData.Drivers
        };
	
        connection.start().then(function () {
            connection.invoke("CacheConnection", data.ApplicationId).catch(function () {
                _this.showWarmTransfer()
            });

            Utils.postToAPI(request, (response) => {
                if (response.ShouldWarmTransfer === true) {
                    _this.showWarmTransfer();
                }
                else if (response.WaitForRequote === false) {
                    _this.increaseStep();
                    _this.cancelInterstitial();
                } 
            }, '/api/submit-additional-details');

        }).catch(function () {
            _this.showWarmTransfer()
        });

        connection.on("ReceiveDetailsUpdateResult", function (response) {
            connection.stop();
            if (response.ShouldUseWarmTransfer === true) {
                _this.showWarmTransfer()
            } else {
                _this.increaseStep();
                _this.cancelInterstitial();
            }
        });
    }

    retrieveQuote(formFields) {
        this.setState({
            showingInterstitial: true,
            InterstitialMessage: "Retrieving your quotes"
        });

        let request = {
            ApplicationId: formFields.QuoteId,
            State: formFields.State,
            PostalCode: formFields.PostalCode,
            DateOfBirth: formFields.DateOfBirth
        };

        getPostAPI("api/quotes/resume", request).then(
            async (response) => {
                this.retrieveQuoteSuccess(response);
            });
    }

    updateCampaignDetails(details) {
        const {PhoneNumber, ImageName, PartnerName} = details || {};

        this.props.handleCampaignDetails({
            phoneNumber: PhoneNumber,
            image: ImageName,
            alt: PartnerName,
            partner: true
        });

    }

    retrieveCompletePayment(formFields) {
        this.setState({
            showingInterstitial: true,
            showRetry: false,
            showQuoteFail: false,
            InterstitialMessage: "Loading quote, please wait"
        });

        const retries = this.state.retryAttempts + 1;
        formFields.VerificationAttempt = retries;

        let _this = this;
        let connection = new signalR.HubConnectionBuilder().withUrl(process.env.NEXT_STATIC_QUOTE_CALLBACK_URL,
            { 
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"        
                }
            }).build();
        connection.on("ReceivePrepareForPaymentResult", function (jsonResponse) {
            connection.stop();
            if (jsonResponse.IsSuccessful) {
                _this.setAPIData(jsonResponse);
                _this.increaseStep(6);
                _this.cancelInterstitial();
                _this.appObj.ApplicationId = _this.state.retrievalCode
                _this.appObj.appTransformedData.Drivers = jsonResponse.Drivers;
                _this.appObj.appTransformedData.Vehicles = jsonResponse.Quote.Vehicles;
                _this.appObj.appTransformedData.AllowTelematics = jsonResponse.AllowTelematics;
            } else {
                _this.appObj.WarmTransferMessage = jsonResponse?.FailureReasons[0];
                _this.setState({
                    showRetry: true,
                    showQuoteFail: true,
                    retryAttempts: retries,
                    showingInterstitial: false
                });
            }
        });
        connection.start().then(function () {
            connection.invoke("CacheConnection", formFields.ApplicationId).catch(function (err) {
                _this.appObj.showWarmTransferScreen(
                    'Unhandled exception encountered connection.invoke("CacheConnection") : ' + JSON.stringify(err),
                    warmTransferTypes.GET_QUOTES.ERROR,
                    '',
                    connection);
            });
        }).catch(function (err) {
            _this.appObj.showWarmTransferScreen(
                'Unhandled exception encountered connection.start() : ' + JSON.stringify(err),
                warmTransferTypes.GET_QUOTES.ERROR,
                '',
                connection);
        });

        setTimeout(() => {

            let request = {
                ApplicationId: formFields.ApplicationId,
                State: formFields.State,
                PostalCode: formFields.PostalCode,
                DateOfBirth: formFields.DateOfBirth,
                CarrierId: formFields.CarrierId,
                Source: formFields.Source,
                VerificationAttempt: formFields.VerificationAttempt
            };

            getPostAPI("api/quotes/prepare-for-payment", request).then(
                async (response) => {
                    this.retrieveCompletePaymentSuccess(response)
                });
        }, 2000);
    }

    retrieveCompletePaymentSuccess(result) {
        if (result.IsSuccessful) {
            if (!result.IsVerified) {
                this.appObj.ApplicationId = result.ApplicationId;
                this.appObj.WarmTransferMessage = result.WarmTransferMessage;
                this.setState(state => ({
                    showingInterstitial: false,
                    showRetry: true,
                    retryAttempts: state.retryAttempts + 1,
                }));
            }
        }
        else {
            this.appObj.WarmTransferMessage = result.WarmTransferMessage;
            this.setState(state => ({
                showingInterstitial: false,
                showRetry: true,
                retryAttempts: state.retryAttempts + 1,
            }));
        }
    }

    retrieveQuoteSuccess(result) {
        if (result.IsSuccessful) {
            if (result.ShouldWarmTransfer) {
                this.appObj.ApplicationId = result.ApplicationId;
                this.appObj.WarmTransferMessage = result.WarmTransferMessage;
                this.setState({
                    showingWarmTransfer: true,
                    showingInterstitial: false,
                    InterstitialMessage: ''
                });
            } else {
                this.setState({
                    InterstitialMessage: "Updating your Quotes"
                });

                if (result.WaitForRequote) {
                    this.appObj.restartCallBack(result);
                } else {
                    this.appObj.jumpToQuotes(result).then();
                }
            }
        }
        else {
            this.appObj.ApplicationId = result.ApplicationId;
            this.appObj.WarmTransferMessage = result.WarmTransferMessage;
            this.setState({
                showingWarmTransfer: true,
                InterstitialMessage: ''
            });
        }
    }

    increaseStep(step, callTrace = true) {
        const currentStep = !isNaN(step) ? step : this.state.currentStep + 1;
        this.setState({
            currentStep: currentStep
        });
        const stepName = Utils.getStepName(currentStep);
        Utils.updateAnalytics(stepName);
        if (currentStep === 1) {
            const cookies = new Cookies();
            const gtp = cookies.get('__utma');
            if (gtp) {
                Utils.updateAnalytics('Cookies-Accepted');
            }
        }
        if (callTrace) {
            this.appObj.traceUser(currentStep);
        }
    }

    showAdditional(data) {
        this.setState(({APIData}) => ({
            showingAdditional: true,
            showVehicleDeletionMessage: false,
            APIData: {
                ...APIData,
                ...APIData.Vehicles.map(vehicle => {
                    const newData = data ? data.find(x => x.ReferenceId = vehicle.ReferenceId) : [];
                    return Object.assign({}, vehicle, newData, {"IsNew": false})
                })
            }
        }));
    }

    handleVehicleDeletion(data, pos) {
        this.setState(({APIData}) => ({
            showingAdditional: false,
            showVehicleDeletionMessage: true,
            previousPosition: pos,
            APIData: {
                ...APIData,
                Vehicles: data
            }
        }));
    }

    cancelAdditional() {
        this.setState({
            showingAdditional: false
        }, () => {
            document.body.style.overflow = null;
        });
    }

    showRetrieval() {
        this.setState({
            showingRetrieval: true
        });
    }

    cancelRetrieval() {
        this.setState({
            showingRetrieval: false
        },  () => {
            document.body.style.overflow = null;
        });
    }

    showInterstitial(message = false) {
        this.setState({
            showingInterstitial: true,
            InterstitialMessage: message ?? ""
        });
    }

    setShowRealTimeQuotes(boolean) {
        this.setState({
            showingRealTimeQuotes: boolean
        }, () => {
            this.props.handleAction(boolean, this.appObj.StateType)
        })
    }

    cancelInterstitial() {
        this.setState({
            showingInterstitial: false
        }, () => {
            document.body.style.overflow = null;
        });
    }

    showWarmTransfer(warmTransferType) {
        this.setState({
            showingInterstitial: false,
            showingWarmTransfer: true,
            warmTransferType: warmTransferType
        });
    }

    showUserError(message) {
        this.setState({
            errorMessage: message
        });
    }

    setAPIData(data) {
        this.setState({
            APIData: data
        });
    }

    resetVehicleMessages() {
        this.setState({
            previousPosition: null,
            showVehicleDeletionMessage: false
        });
    }

    saveGaragedLocations(garagedLocations, data, vehicleUsageTypes) {
        this.setState(({APIData}) => ({
            garagedLocations: garagedLocations,
            vehicleUsageTypes: vehicleUsageTypes,
            APIData: {
                ...APIData,
                Vehicles: data
            }
        }));
    }

    setAdditionalPaymentData() {
        this.setState(Object.assign({}, this.state, this.additionalPaymentData));
    }

    setFieldVariations(data) {
        this.setState({
            fieldVariations: data
        });
    }

    setNationwideDisclaimerData(data) {
        this.additionalPaymentData.nationwide = data
    }

    setTelematicsData(data) {
        this.additionalPaymentData.telematics = data
    }

    setElectronicDocumentsOptions(data) {
        this.additionalPaymentData.electronicDocuments = data
    }

    setUmOptions(data) {
        this.additionalPaymentData.umOptions = data
    }

    createFormStep(){
        const {APIData,currentStep,showingRetrieval,showingInterstitial,showingAdditional,showingWarmTransfer,showingError, showingRealTimeQuotes} = this.state;

        if (this.state.visualQa) {
            return <VisualQA/>
        }

        let step = [];
        if (showingWarmTransfer === false && showingError === false && currentStep > 0 && !this.props.completePayment) {
            step.push(<ProgressBanner showingInterstitial={showingInterstitial} step={currentStep} key={Utils.getIndex()} appObj={this} />);
        }

        if (!showingInterstitial && !showingWarmTransfer && !showingError && !showingRealTimeQuotes) {

            switch (currentStep) {
                case 0:
                    if (this.props.completePayment) {
                        step.push(<ClientVerificationStep
                            handleAction={this.retrieveCompletePayment.bind(this)}
                            handleRetry={() => this.setState({
                                showRetry: false
                            })}
                            showQuoteFail={this.state.showQuoteFail}
                            retryAttempts={this.state.retryAttempts}
                            appProcessorObj={this.appObj}
                            state={this.state.retrievalState}
                            retry={this.state.showRetry}
                            applicationId={this.state.retrievalCode}
                            carrierId={this.state.retrievalCarrierId}
                            source={this.state.retrievalSource}
                            key={Utils.getIndex()}
                        />)
                    } else {
                        step.push(<AddressStep
                            handleAction={this.processStep.bind(this)}
                            initialAddress={this.appObj.InsuredAddress}
                            data={APIData}
                            appObj={this.appObj}
                            key={Utils.getIndex()}
                            enableLiveChat={this.state.enableLiveChat}
                        />);
                    }

                    break;

                case 1:
                    step.push(<DetailsStep
                        handleAction={this.processStep.bind(this)}
                        data={APIData}
                        appProcessorObj={this.appObj}
                        appObj={this}
                        key={Utils.getIndex()}
                        fieldVariations={this.state.fieldVariations}
                        enableLiveChat={this.state.enableLiveChat}
                    />);
                    break;

                 case 2:
                    step.push(<DriversForm
                        handleAction={this.processStep.bind(this)}
                        handleSelectedDrivers={this.handleSelectedDrivers.bind(this)}
                        data={APIData}
                        appProcessorObj={this.appObj}
                        appObj={this}
                        key={'DriverForm'}
                        fieldVariations={this.state.fieldVariations}
                        enableLiveChat={this.state.enableLiveChat}
                    />);
                    break;

                case 3:
                    step.push(<VehiclesForm
                        handleAction={this.processStep.bind(this)}
                        handleDelete={this.handleVehicleDeletion.bind(this)}
                        data={APIData}
                        showVehicleDeletionMessage={this.state.showVehicleDeletionMessage}
                        previousPosition={this.state.previousPosition}
                        appProcessorObj={this.appObj}
                        saveGaragedLocations={this.saveGaragedLocations}
                        vehicleUsageTypes={this.state.vehicleUsageTypes}
                        currentGaragedLocations={this.state.garagedLocations}
                        showAdditional={this.showAdditional.bind(this)}
                        appObj={this}
                        key={Utils.getIndex()}
                        fieldVariations={this.state.fieldVariations}
                        enableLiveChat={this.state.enableLiveChat}
                    />);
                    break;

                case 4:
                    step.push(<PreviousCarrier
                        data={APIData}
                        handleAction={this.processStep.bind(this)}
                        appProcessorObj={this.appObj}
                        key={Utils.getIndex()}
                        appObj={this}
                        fieldVariations={this.state.fieldVariations}
                        enableLiveChat={this.state.enableLiveChat}
                    />);
                    break;

                case 5:
                    step.push(<ContactDetails
                        affiliate={APIData}
                        data={this.appObj.appTransformedData}
                        handleAction={this.processStep.bind(this)}
                        appProcessorObj={this.appObj}
                        key={Utils.getIndex()}
                        appObj={this}
                        fieldVariations={this.state.fieldVariations}
                        enableLiveChat={this.state.enableLiveChat}
                    />);
                    break;

                case 6:
                    if (this.props.completePayment) {
                        step.push(<DisplayQuoteStep
                            data={APIData}
                            key={Utils.getIndex()}
                            handleAction={this.processStep.bind(this)}
                            enableLiveChat={this.state.enableLiveChat}
                        />)
                    } else {
                        step.push(<Quotes
                            data={APIData}
                            handleAction={this.processStep.bind(this)}
                            appProcessorObj={this.appObj}
                            key={Utils.getIndex()}
                            appObj={this}
                            fieldVariations={this.state.fieldVariations}
                            enableLiveChat={this.state.enableLiveChat}
                        />);
                    }
                    break;

                case 7:
                    step.push(
                        <PrePaymentStep
                            data={APIData}
                            handleAction={this.handlePrePayment}
                            fail={this.showWarmTransfer}
                            key={Utils.getIndex()}
                            appProcessorObj={this.appObj}
                            appObj={this}
                            telematics={this.state.telematics}
                            fieldVariations={this.state.fieldVariations}
                            suppress={this.props.completePayment}
                            enableLiveChat={this.state.enableLiveChat}
                        />
                    )
                    break;

                case 8:
                    const NextStep = this.appObj.paymentProviderType === CARRIER_NATIONWIDE_NO_PAYMENT_PROVIDER_ID ? NationwidePayment : Payment;

                    step.push(<NextStep
                        data={APIData}
                        handleAction={this.processStep.bind(this)}
                        fail={this.showWarmTransfer}
                        key={Utils.getIndex()}
                        appProcessorObj={this.appObj}
                        appObj={this}
                        telematics={this.state.telematics}
                        fieldVariations={this.state.fieldVariations}
                        paymentSource={this.state.retrievalSource}
                        suppress={this.props.completePayment}
                        enableLiveChat={this.state.enableLiveChat}
                    />);
                    break;

                case 9:
                    step.push(<Success
                        data={APIData}
                        handleAction={this.processStep.bind(this)}
                        key={Utils.getIndex()}
                        appProcessorObj={this.appObj}
                        appObj={this}
                        fieldVariations={this.state.fieldVariations}
                        enableLiveChat={this.state.enableLiveChat}
                    />);
                    break;
            }


            if(showingRetrieval) {
                step.push(this.createRetrieval());
            }
            if(showingAdditional) {
                step.push(this.createAdditional(currentStep));
            }
        } else {
            if (showingError) {
                step.push(this.createError());
            }
            else if (showingWarmTransfer) {
                step.push(this.createWarmTransfer());
            }
            else if (showingInterstitial) {
                step.push(this.createInterstitial());
            }
            else if (showingRealTimeQuotes) {
                step.push(this.createRealTimeQuotes());
            }
        }
        return step;
    }

    createInterstitial(){
        return <Interstitial
            step={this.state.currentStep}
            message={this.state.InterstitialMessage}
            appObj={this.appObj}
            key={Utils.getIndex()}
            enableLiveChat={this.state.enableLiveChat}
        />;
    }

    createRealTimeQuotes() {
        return <RealTimeQuotes
            appObj={this}
        />
    }

    createWarmTransfer(){

        if (propExists(this, "appObj.applicationObj.state.retrievalCallbackUrl")) {
            return (
                <BackToAgent
                    url={this.appObj.applicationObj.state.retrievalCallbackUrl}
                    message={<span>Please review all quotes for this application</span>}
                />
            )
        }

        let message = "";
        if (propExists(this, "appObj.appTransformedData.Applicant.FirstName")) {
            message = "Hi " + Utils.capitalize(this.appObj.appTransformedData.Applicant.FirstName) + ". ";
        }

        message = this.appObj.WarmTransferMessage ? message + this.appObj.WarmTransferMessage : message + DEFAULT_WARM_TRANSFER_MESSAGE;

        return (
            <WarmTransfer
                key={Utils.getIndex()}
                quoteID={this.appObj.ApplicationId}
                state={this.appObj.StateType}
                message={message}
                appProcessorObj={this.appObj}
                warmTransferType={this.state.warmTransferType}
                enableLiveChat={this.state.enableLiveChat}
            />
        );
    }

    createError(){
        return <Error key={Utils.getIndex()} appProcessorObj={this.appObj} />;
    }

    createAdditional(){
        return <AddVehicleModal
            handleAction={this.processStep.bind(this)}
            cancelAction={this.cancelAdditional.bind(this)}
            key={Utils.getIndex()}
            appProcessorObj={this.appObj}
            appObj={this}
        />;
    }

    createRetrieval() {
        return <RetrievalFormModal
            handleAction={this.retrieveQuote.bind(this)}
            cancelAction={this.cancelRetrieval.bind(this)}
            step={0}
            key={Utils.getIndex()}
            appProcessorObj={this.appObj}
            appObj={this}
        />;
    }

    currentStep() {
        let {currentStep} = this.state;
        return currentStep;
    }

    render() {

        return (
            <div key={Utils.getIndex()}>
                {this.createFormStep()}
                {this.state.enableLiveChat && (
                    <LiveChatIcon/>
                )}
            </div>
        )
    }
}

export default Application;