import {useEffect, useState} from "react";

const MOBILE_DEVICE_REGEX = /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i;
const PLATFORM_REGEX = /MacIntel/;


const useDetectDevice = () => {

    const [isMobile, setMobile] = useState(false);

    useEffect(() => {
        const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
        const platform = typeof window.navigator === "undefined" ? false : navigator.platform;
        const maxTouchPoints = typeof window.navigator === "undefined" ? false : navigator.maxTouchPoints;

        let mobile = MOBILE_DEVICE_REGEX.test(userAgent);

        if (maxTouchPoints && maxTouchPoints > 2){
            mobile = PLATFORM_REGEX.test(platform);
        }

        setMobile(mobile);

    }, []);

    return isMobile;

}


export default useDetectDevice;