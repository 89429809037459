import SVG from "@components/general/svg";
import {SHARED_ASSETS_PATH} from "@constants";


const Toast = ({type = "correct", children}) => {

    const icon = <SVG img={SHARED_ASSETS_PATH + "svg/success-circle.svg"} className={'icon'}/>

    return (
        <div className={`toasty`}>
            <div className={`toasty-wrapper ${type}`}>
                {icon} <span>{ children }</span>
            </div>
        </div>
    );

}


export default Toast;
